$grid-breakpoints: (
  xs: 0,
  sm: 568px,
  md: 768px,
  md-lg: 876px,
  lg: 1024px,
  xl: 1280px,
  extra-xl: 1600px
);

$container-max-widths: (
  sm: 568px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5
);

$utilities: (
  'width': (
    property: width,
    responsive: true,
    class: w,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
  'height': (
    property: height,
    responsive: true,
    class: h,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
  'cursor': (
    property: cursor,
    values: auto default pointer wait text move help not-allowed
  )
);
