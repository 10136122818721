@import '../../../mixins';

.popover {
  @include dark-popover;
}

.dashLine {
  position: relative;
  display: inline-block; // Make sure this applies only to the text itself
  padding-bottom: 0.25rem;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-image: repeating-linear-gradient(
      to right,
      rgba(var(--neutralColorRGB-6), 0.5) 0,
      rgba(var(--neutralColorRGB-6), 0.5) 3px,
      transparent 3px,
      transparent 10px
    );
  }
}
