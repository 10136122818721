.wrapper {
  :global {
    .ant-select.ant-select {
      &:not(.ant-select-disabled) {
        .ant-select-selector {
          .ant-select-selection-placeholder {
            color: var(--neutralColor-6);
            font-weight: 600;
          }
        }
      }
    }
  }
}
