.editor {
  &:global {
    & > div {
      padding: 0 !important;
      width: 100%;
    }
  }

  :global {
    input {
      background: transparent;
      border: none;
      font-weight: 500;
      font-size: 14px;
      color: var(--neutralColor-4);
      padding: 0 0 1px;
      border-radius: 8px;
    }
  }
}
