.button {
  height: 40px;
  min-width: 40px;

  &:global {
    &:hover,
    &.visible {
      border-color: var(--primaryColor-6);
      color: var(--primaryColor-6);
    }
  }
}

.popover {
  :global {
    .ant-popover-inner {
      border-radius: var(--borderRadius-l);
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

.icon {
  color: var(--neutralColor-6);
  stroke: var(--grayscaleColor-1);
  stroke-width: 1;
}
