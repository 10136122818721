.search {
  :global {
    .wrapper {
      &.collapsed {
        .search-input {
          border: none;
          padding: 0;
          width: 0;
          height: 0;
          overflow: hidden;
        }

        .search-icon {
          position: relative;
          padding-top: 1.25rem;
          padding-bottom: 0.25rem;
          top: 0;
          left: 0;
          display: block;
          cursor: pointer;

          svg {
            display: block;
            margin: 0 auto;
            font-size: 1.5rem;
          }
        }

        .search-clear {
          display: none;
        }
      }
    }

    .search-input {
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--borderRadius-lg);
      width: 100%;
      display: block;
      height: 40px;

      padding: 0 3rem;
      font-family: var(--fontFamily);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      background-color: transparent;
      color: var(--neutralColor-6);

      &::placeholder {
        color: var(--neutralColor-6);
      }
      &:hover {
        border: 1px solid var(--primaryColor-4);
      }
      &:focus-visible {
        border: 1px solid var(--primaryColor-6);
        outline: none;
      }
    }

    .search-icon {
      color: var(--neutralColor-4);
      position: absolute;
      top: 0.5rem;
      left: 1rem;
      font-size: 1.5rem;
      pointer-events: none;
    }

    .search-clear {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 1.25rem;
      font-size: 1.25rem;
      color: var(--neutralColor-4);
    }
  }
}
