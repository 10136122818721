@import '../../../mixins.scss';

.wrapper {
  :global {
    .ant-pagination {
      .ant-pagination-total-text {
        font-weight: 500;
        color: var(--neutralColor-4);
      }
      .ant-pagination-disabled {
        color: var(--grayscaleColor-6);
        cursor: default;
      }
    }

    .ant-select.ant-select-single {
      &.ant-select-open {
        background: var(--primaryColor-3);
        border-radius: var(--borderRadius-lg);
      }
      &:not(.ant-select-open):hover {
        background: var(--primaryColor-2);
        border-radius: var(--borderRadius-lg);
      }
      .ant-select-selector {
        background: transparent;
        border: none;
        color: var(--neutralColor-6);
        min-height: auto;
        padding: 0.25rem 0.75rem;

        .ant-select-selection-item {
          padding-right: 1.25rem;
        }
      }
      .ant-select-arrow {
        font-size: 1rem;
        color: var(--neutralColor-6);
      }
    }

    .ant-pagination-item,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      display: none;
    }

    .ant-pagination-next,
    .ant-pagination-prev {
      color: var(--neutralColor-6);
      line-height: normal;
      align-content: center;
      transition: none;

      &:not(.ant-pagination-disabled):hover {
        background: var(--primaryColor-2);
        border-radius: var(--borderRadius-circle);
      }
    }
  }
}

.selector {
  @include glass-background-effect;
  z-index: 1000 !important;
}
