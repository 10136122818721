.wrapper {
  :global {
    .FieldWrapperOuter {
      .quill {
        .ql-tooltip {
          left: 0 !important;
        }
      }
    }
  }
}
