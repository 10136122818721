@import '../../../../../variables.scss';

.wrapper {
  :global {
    .form-buttons-container {
      width: 50%;

      gap: 1rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem 0 0;
      flex-wrap: wrap;
      justify-content: flex-end;

      .DropdownButton {
        button.menu,
        button.main {
          min-height: 33px;
        }
      }

      > button,
      a {
        height: 33px;
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        width: 100% !important;
      }
    }
  }
}
