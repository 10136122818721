.imagesForm {
  padding-bottom: 0.7rem;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  :global {
    .overlayImageSelector {
      height: fit-content;
    }
  }
}
