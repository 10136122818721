.wrapper {
  &:global {
    & > .tabs-list.dragging-over .ant-tabs-ink-bar {
      display: none;
    }

    & > .tabs-list > .ant-tabs > .ant-tabs-nav {
      margin-bottom: 0;

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-weight: 600;
        }
        .tab-item + .tab-item {
          margin: 0 0 0 32px;
        }
      }
    }
  }

  :global {
    .invalid {
      color: var(--warningColor-6);
    }

    .drag-item {
      .drag-handler {
        &.disabled {
          opacity: 0.3;
        }
      }
    }
  }
}
