.wrapper {
  :global {
    .sortable-list {
      border-radius: 20px;
      border: 1px solid var(--grayscaleColor-5);
      overflow: hidden;
    }

    .edit-btn {
      color: var(--neutralColor-6);
      transition: color 0.3s;
      opacity: 1;
      cursor: pointer;

      &:hover {
        color: var(--primaryColor-6);
      }
    }

    .edit-btn.disabled {
      opacity: 0.3;
      cursor: default;

      &:hover {
        color: inherit;
      }
    }

    .add-metric-btn {
      height: 40px;
      width: 250px;
      border-color: var(--grayscaleColor-5);
      color: var(--neutralColor-6);

      &:hover {
        color: var(--primaryColor-6) !important;
        border-color: var(--primaryColor-6) !important;
      }

      &:active,
      &:focus {
        color: var(--primaryColor-7) !important;
        border-color: var(--primaryColor-7) !important;
      }
    }
  }
}
