.activitySelector {
  min-width: 130px;
  :global {
    .ActivityIcon {
      margin: 0 !important;
    }
  }
}

.followUpField {
  width: 65%;
  flex-shrink: 0;
}
