.outer {
  padding-right: 64px !important;
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &:global {
    &.simple {
      padding-right: 0 !important;
    }

    &.kanbanField {
      &.kanbanFieldTextType {
        align-items: center;
      }

      &.kanbanFieldMultiTextType {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  :global {
    .list-item {
      &.multiline {
        padding-bottom: 0.3rem;
        width: 100%;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .text-row {
      display: block;
      width: 100%;
      line-height: normal;
    }

    .list-item-bullet {
      font-weight: 600;
      margin-right: 0.2rem;
    }

    .list-item-text {
      max-width: calc(100% - 5px);
    }
  }
}
