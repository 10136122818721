.container {
  position: relative;
  padding: 0 0.5rem;
}

.settingsContainer {
  padding-bottom: 0;

  @media screen and (min-width: 80em) {
    padding-bottom: 1rem;
  }
}
