.outer {
  &:hover {
    :global {
      .code-outer:after {
        background: linear-gradient(
            to top,
            var(--grayscaleColor-1) 5%,
            rgba(var(--grayscaleColorRGB-13), 0) 80%
          )
          100px;
      }
    }
  }

  &:global {
    &.kanbanField {
      &.multiline {
        flex-direction: column;
        gap: 2px;
      }

      .open-item-info-drawer-link {
        line-height: inherit;
      }
    }
  }

  :global {
    .styled-link {
      font-weight: inherit !important;
      border: 0 !important;
      transition: color 200ms ease;
      word-break: break-word;

      &:hover {
        color: var(--primaryColor-5);
      }
    }

    .id-outer {
      font-family: Monaco, Consolas, 'Courier New', monospace;
      font-size: 0.8rem;
      line-height: 0.9rem;
      font-weight: 500;
      color: var(--grayscaleColor-7);
    }

    .open-item-info-drawer-link {
      line-height: 21px;
    }

    .list-bullet {
      background-color: var(--primaryColor-4);
      font-weight: 600;
      margin-right: 0.5rem;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      flex: none;
    }
  }
}

.openDrawerLink {
  cursor: pointer;
  color: var(--primaryColor-6);
  transition: color 200ms ease;

  &:global {
    &:hover {
      color: var(--primaryColor-5) !important;
    }
  }
}

.multipleValues {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  gap: 4px;
  background: var(--grayscaleColor-3);
  border: 1px solid var(--neutralColor-4);
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;

  :global {
    .icon {
      font-size: 16px;
      margin-right: 4px;
      flex-shrink: 0;
    }

    .text {
      font-size: 14px;
      font-weight: 500;
      margin-right: 8px;
    }
  }

  &:global {
    &:hover {
      border-color: var(--primaryColor-5);
      color: var(--primaryColor-5);
    }
  }
}
