@import '../../../variables';

.ssoTitle {
  color: var(--neutralColor-4);
  line-height: 0.9rem;
  font-size: 0.9rem;
}

.ssoButton {
  height: 40px;

  :global {
    svg {
      margin-top: -0.15rem !important;
    }
  }
}

.loadingWrapper {
  height: 200px;
}
