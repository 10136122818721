.container {
  position: relative;
  padding-right: 1.5rem;
}

.styledWarning {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--errorColor-5);
  font-size: 1.2rem;
}

.pastDeadlineText {
  color: var(--errorColor-5);
  font-weight: 600;
}
