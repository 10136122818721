.container {
  display: flex;
  height: 172px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :global {
    .ConversationIcon {
      color: var(--grayscaleColor-5);
      margin-bottom: 26px;

      &__dots {
        fill: var(--grayscaleColor-3);
      }
    }

    .message-body {
      font-size: 18px;
      color: var(--neutralColor-4);
    }
  }
}
