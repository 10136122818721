.wrapper {
  :global {
    svg > g > g > path {
      // pie
      cursor: pointer;
    }
    svg > g > g > rect {
      // bar
      cursor: pointer;
    }
    svg > g > path {
      // funnel
      cursor: pointer;
    }

    .metric-item > .value {
      // metric
      cursor: pointer;
    }
  }
}
