.card {
  background-color: var(--grayscaleColor-1);
  box-shadow: 0 1px 4px 0 rgba(var(--neutralColorRGB-7), 0.1);
  border-radius: var(--borderRadius-m);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:not(&_disabledDrag):active {
    cursor: grabbing !important;
  }

  &_disabledDrag:active {
    cursor: no-drop !important;
  }

  &_header {
    font-size: 14px;
    line-height: 17px;
    color: var(--neutralColor-8);
  }

  &_fieldWrapper {
    margin-bottom: 14px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_fieldLabelIcon {
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    margin-right: 2px;
  }

  &_fieldLabelText {
    font-size: 14px;
    line-height: 16px;
    color: var(--neutralColor-7);
  }

  &_fieldGroup {
    min-height: 16px;

    :global {
      .FileFieldOuter {
        max-width: 100%;
      }

      .AvatarPreviewOuter {
        .ant-popover {
          max-width: 150px;
        }
      }
    }
  }

  &_fieldValue {
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: calc(100% - 40px);
    font-size: 14px;
    line-height: 16px;
  }
}

.cardHeader {
  &__bottom {
    margin-top: 4px;
  }

  &__processTitle {
    font-size: 12px;
    color: var(--neutralColor-6);
  }

  &__createDate {
    font-size: 12px;
    line-height: 12px;
    color: var(--neutralColor-5);
  }

  &__processId {
    font-size: 14px;
    line-height: 1;
    color: var(--primaryColor-6);

    &:global {
      &.process-entity {
        max-width: 225px;
      }

      &.task-entity {
        max-width: 250px;
      }
    }
  }

  &__processStatus {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 50%;

    &:global {
      &.SUCCEEDED {
        background-color: var(--successColor-6);
      }

      &.RUNNING {
        background-color: var(--primaryColor-6);
      }

      &.FAILED {
        background-color: var(--errorColor-6);
      }

      &.CANCELLED {
        background-color: var(--warningColor-6);
      }
    }
  }

  &__taskPriority {
    &:global {
      &.class-2-LOW {
        color: var(--successColor-6);
      }

      &.class-4-NORMAL {
        color: var(--successColor-6);
      }

      &.class-6-HIGH {
        color: var(--warningColor-6);
      }

      &.class-8-URGENT {
        color: var(--errorColor-5);
      }
    }
  }
}

.userAvatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--infoColor-4);
  margin-left: 8px;
  color: var(--grayscaleColor-1);
  outline: none;

  &__systemUser {
    background-color: var(--neutralColor-4);
  }
}

.deadline {
  font-size: 14px;
}

.missedDeadline {
  color: var(--errorColor-5);
}
