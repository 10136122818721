:global {
  .btn-dropdown-arrow.btn-dropdown-arrow:hover,
  .ant-dropdown-open .btn-dropdown-arrow.btn-dropdown-arrow,
  .ant-menu-submenu-open > .ant-menu-submenu-title > .btn-dropdown-arrow.btn-dropdown-arrow {
    color: var(--primaryColor-6);
    border-color: var(--primaryColor-6);
    background-color: var(--grayscaleColor-1);

    svg {
      transform: rotate(180deg);
    }
  }
}
