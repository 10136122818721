.menu {
  color: var(--HEADER_MENU__color);
  font-size: 1.6rem;
  cursor: pointer;
}

.close.close {
  position: fixed !important;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  height: 2.5rem;
  border-radius: var(--borderRadius-l);
  color: var(--HEADER_MOBILE__backgroundColor) !important;
  background-color: var(--HEADER_USER_AVATAR_MOBILE_backgroundColor);
  border-color: var(--HEADER_USER_AVATAR_MOBILE_backgroundColor) !important;

  &:hover {
    color: var(--HEADER_USER_AVATAR_MOBILE_backgroundColor) !important;
    background-color: var(--HEADER_MOBILE__backgroundColor) !important;
    border-color: var(--HEADER_USER_AVATAR_MOBILE_backgroundColor) !important;
  }
}
