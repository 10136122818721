.removeButton {
  font-size: 1.5rem;
  color: var(--grayscaleColor-6);
  cursor: pointer;
  transition: color 0.3s ease;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    color: var(--errorColor-5);
  }
}
