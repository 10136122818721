.fileField {
  :global {
    .with-error {
      border-color: var(--warningColor-6);

      &.error-prepend {
        padding-left: 1rem !important;

        &:before {
          content: none;
        }
      }
    }
  }
}

.collapse {
  &:global {
    &.ant-collapse {
      border: 0 !important;
      padding: 0 !important;
    }
  }

  :global {
    .ant-collapse-content {
      padding: 1rem;
      border: 1px solid var(--grayscaleColor-5);
      border-bottom: 0;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
}
