.outer {
  display: flex;
  align-items: center;
  white-space: nowrap;

  :global {
    .label {
      margin-right: 5px;
    }
  }
}
