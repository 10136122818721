.wrapper {
  :global {
    .ant-select.ant-select {
      .ant-select-selector {
        border-radius: var(--borderRadius-l) !important;

        &:hover:enabled {
          border-color: var(--primaryColor-5);
        }
      }

      &.ant-select-multiple {
        .ant-select-selector {
          padding-right: 0;

          .ant-select-selection-overflow {
            max-width: 100%;
            padding-right: 2rem;
          }
        }

        .ant-select-clear,
        .ant-select-arrow {
          right: 12px;
        }
      }
    }

    .ant-input.ant-input,
    .ant-picker.ant-picker {
      border-radius: var(--borderRadius-l);
      font-weight: 500 !important;
      color: var(--neutralColor-6) !important;
      box-shadow: none;

      input {
        color: inherit;
      }
      &.ant-input-disabled {
        background-color: var(--grayscaleColor-4);
      }
    }

    .ComputedInputOuter {
      .computed-expression,
      .computed-component-outer {
        &:hover {
          border-color: var(--primaryColor-5) !important;
        }
      }
    }

    .react-tag-input {
      padding-right: 1rem;
    }
  }
}
