.antAvatarGroupWrapper {
  :global {
    .avatar-popover {
      max-width: 500px;
    }
  }
  &:global {
    & > .avatar-count {
      margin-left: -3%;
    }
    & > div {
      &:not(:first-child) {
        margin-left: -3%;
      }
      & > div > .ant-avatar {
        border: 2px solid #fff;
        box-shadow: 0 0 0 2px #fff;
        filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.16));
      }
    }
  }
}
