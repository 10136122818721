.container {
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;

  @media screen and (min-width: 48em) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
}

.contentRow {
  &:global {
    &:not(.is-mobile).collapsed {
      .sidebar {
        width: var(--leftDrawerWidth) !important;
        flex: 1 1 var(--leftDrawerWidth) !important;
        max-width: var(--leftDrawerWidth) !important;
      }

      .main-content {
        flex: 1 1 calc(100% - var(--leftDrawerWidth)) !important;
        max-width: calc(100% - var(--leftDrawerWidth)) !important;
      }
    }
  }

  :global {
    .sidebar {
      flex: 1 1 min(25%, 400px) !important;
      max-width: 400px !important;
      transition: all 0.2s;
    }

    .main-content {
      flex: 1 1 max(75%, calc(100% - 400px)) !important;
      max-width: max(75%, calc(100% - 400px)) !important;
      transition: all 0.2s;

      &.is-mobile {
        padding-top: 0.5rem;
        max-width: 100% !important;
      }

      .RoutesContainer {
        padding-top: 0;
      }
    }
  }
}
