@import '../../../../../variables';

.tasksContainer {
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-bottom: 16px;
  background: var(--primaryColor-6);
  border-radius: var(--SECTION__borderRadius);

  &:global {
    &.warning-tasks {
      background: var(--warningColor-6) !important;
    }
  }
}

.tasksContainerTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--grayscaleColor-1);
  padding: 8px 16px 16px;
}

.taskContainer {
  border-radius: 8px;
  min-height: 74px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
}

.isNewTask {
  animation-duration: 2000ms;
  animation-name: highlightNewTaskAnimation;
}

.noTasks,
.fetchingTasks {
  font-weight: 600;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: row;
  background: var(--grayscaleColor-1);
  border: 1px solid var(--grayscaleColor-5);
  padding: 16px;
}

@keyframes highlightNewTaskAnimation {
  from {
    background-color: var(--primaryColor-5);
  }

  to {
    background-color: var(--grayscaleColor-1);
  }
}
