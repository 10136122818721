.document {
  :global {
    .react-pdf__Page {
      max-width: 100%;

      .react-pdf__Page__canvas {
        max-width: 100%;
      }
    }

    .react-pdf__message {
      height: 100%;
    }
  }
}
