.container {
  &:global {
    &.collapsed {
      .SectionCardHeadingText {
        display: none;
      }
      .SectionCardHeadingIcon {
        margin-right: 0;
      }
      .CollapseExpandButton {
        display: none;
      }
      .CollasedIcon {
        transform: rotate(180deg);
      }
      .SectionCardHeading {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 0;
        padding-top: 0.25rem;
        row-gap: 1rem;
      }

      .SearchInput {
        display: none;
      }

      .itemTitle__title {
        display: none;
      }

      .ActionsButtonOuter {
        display: none;
      }

      .ContentOuter {
        height: calc(100vh - 325px) !important;
      }

      .ant-tree-treenode {
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        cursor: pointer;
        position: relative;

        &.ant-tree-treenode-selected:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          border-right: 1px solid var(--primaryColor-6);
        }

        .ant-tree-indent {
          display: none;
        }

        .ant-tree-node-content-wrapper {
          width: auto !important;
        }

        .ant-tree-switcher-noop + .ant-tree-node-content-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ant-tree-switcher {
          margin-right: 0.25rem;
          padding: 0;
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
        }
      }

      .ItemTitle {
        padding-right: 0;

        .icon svg {
          margin: 0;
        }
      }
    }
  }

  :global {
    .ant-tree.ant-tree {
      background-color: transparent;

      .ant-tree-treenode {
        width: 100%;
        border-top-right-radius: var(--borderRadius-lg);
        border-bottom-right-radius: var(--borderRadius-lg);
        padding: 2px 0 2px 1rem;

        &:hover {
          background-color: var(--primaryColor-2);
          .itemTitle__title {
            color: var(--SIDE_MENU_ITEM_ACTIVE__color);
          }
        }

        &.ant-tree-treenode-selected {
          background-color: var(--primaryColor-3);

          .itemTitle__title {
            color: var(--SIDE_MENU_ITEM_ACTIVE__color);
          }
        }

        .ant-tree-indent-unit {
          width: 12px;
        }

        .ant-tree-node-content-wrapper {
          width: 100%;
          line-height: inherit;
          height: inherit;
          padding: 0;
          background-color: transparent !important;

          &:hover {
            background-color: var(--primaryColor-1);
          }
        }
      }
    }
    .results {
      padding: 1rem 1rem 1rem 2rem;
    }
  }

  &__add {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid var(--primaryColor-6);
    border-radius: var(--borderRadius-lg);
    padding: 0.5rem 1rem;
    gap: 0.25rem;
    color: var(--primaryColor-6) !important;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1.25rem;

    &:hover {
      border-color: var(--primaryColor-5) !important;
      color: var(--primaryColor-5) !important;
    }
  }
  &__addCollapsed {
    padding: 0.25rem;
    font-size: 1rem;
    height: auto;
    border-radius: var(--borderRadius-circle);
  }

  &__itemTitle {
    position: relative;
    padding-right: 2rem;
    font-weight: 500;
    font-size: 0.85rem;
    gap: 0.75rem;
    color: var(--neutralColor-6);
    display: flex;
    align-items: center;
    cursor: pointer;

    &:global {
      &.selected {
        cursor: default;
      }
    }
  }

  &__groupActionsButton {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);

    :global {
      button.menu {
        min-height: 32px;
        min-width: 32px;
        border-width: 0;
        border-radius: 50%;
        background: transparent;
        &:hover {
          transition: none;
          border-radius: var(--borderRadius-lg);
          background: rgba(var(--grayscaleColorRGB-1), 0.2);
          outline: 1px solid rgba(var(--grayscaleColorRGB-1), 0.8);
          box-shadow: 0px 2px 10px 0px rgba(17, 29, 73, 0.15);
          backdrop-filter: blur(16px);
        }
      }
    }
  }

  &__sectionCardHeading {
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }

  &__sectionCardHeadingText {
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1px;
    color: var(--neutralColor-6);
  }

  &__sectionCardHeadingIcon {
    display: flex;
    align-items: center;
    height: auto;
    background: transparent;
    border: 1.5px solid var(--neutralColor-4);
    border-radius: var(--borderRadius-circle);
    padding: 0.25rem;
    margin-right: 1rem;
    color: var(--neutralColor-4);
    transition: none;
    &:hover,
    &:focus {
      background: var(--primaryColor-2);
      transition: none;
      outline: 4px solid var(--primaryColor-2) !important;
      color: var(--neutralColor-4) !important;
      border-color: var(--neutralColor-4) !important;
    }
    &:active {
      color: var(--neutralColor-4) !important;
      border-color: var(--neutralColor-4) !important;
    }
  }
}

.tree {
  :global {
    .ActionsButtonOuter {
      opacity: 0;
      transition: opacity 0.3s;

      .menu svg {
        font-size: 0.75rem;
        color: var(--neutralColor-6);
      }
    }

    .ant-tree-treenode {
      align-items: center;

      .ant-tree-indent {
        order: 0;
      }

      .ant-tree-node-content-wrapper {
        min-height: auto;
        height: auto !important;
        order: 1;
      }

      &:hover > .ant-tree-node-content-wrapper > .ant-tree-title .ActionsButtonOuter {
        opacity: 1;
      }
    }

    .ant-tree-switcher {
      line-height: normal;
      margin-right: 0.75rem;
      padding: 8px;
      width: 32px;
      height: 32px;

      .ant-tree-switcher-icon {
        transform: rotate(0deg);
        transition: all 0.3s;
        font-size: 1rem;
        color: var(--neutralColor-4);
      }

      &.ant-tree-switcher_close .ant-tree-switcher-icon {
        transform: rotate(-90deg);
      }
    }
  }
}

.collapseExpandButton {
  cursor: pointer;
  margin-left: auto;
  margin-right: 1rem;
  font-size: 1.5rem;
  line-height: 1;
  transition: all 0.3s;
  color: var(--neutralColor-6);

  &:global {
    &.disabled {
      cursor: inherit;
      opacity: 0.3;
    }
  }

  :global {
    svg {
      vertical-align: middle;
    }
  }
}
