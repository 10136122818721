.wrapper {
  :global {
    .ant-drawer-wrapper-body {
      position: relative;
    }

    .ant-drawer-body {
      padding: 0;
      flex: initial;
    }
  }

  &:global {
    &.ant-drawer-closable {
      .ant-drawer-title {
        margin-right: 2rem;
        overflow: hidden;
      }
    }
  }
}
