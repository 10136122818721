:root {
  --fontFamily: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, 'Helvetica Neue', sans-serif;
  --leftDrawerWidth: 88px;

  // BRAND COLORs
  --primaryColor-1: #f0f8ff; // Selected background color
  --primaryColorRGB-1: 240, 248, 255; // Selected background color
  --primaryColor-2: #cfe8ff;
  --primaryColorRGB-2: 207, 232, 255;
  --primaryColor-3: #a6d1ff;
  --primaryColorRGB-3: 166, 209, 255;
  --primaryColor-4: #7db7ff;
  --primaryColorRGB-4: 125, 183, 255;
  --primaryColor-5: #549bff; // Hover color
  --primaryColorRGB-5: 84, 155, 255; // Hover color
  --primaryColor-6: #2976f6; // Normal color
  --primaryColorRGB-6: 41, 118, 246; // Normal color
  --primaryColor-7: #1958cf; // Click color
  --primaryColorRGB-7: 25, 88, 207; // Click color
  --primaryColor-8: #0c3da8;
  --primaryColorRGB-8: 12, 61, 168;
  --primaryColor-9: #032782;
  --primaryColorRGB-9: 3, 39, 130;

  // MAIN LAYOUT COLORs
  --neutralColor-1: #afadb8; // Table header color
  --neutralColorRGB-1: 175, 173, 184; // Table header color
  --neutralColor-2: #a2a1ab; // Background color
  --neutralColorRGB-2: 162, 161, 171; // Background color
  --neutralColor-3: #96959e; // Border and Divider color
  --neutralColorRGB-3: 150, 149, 158; // Border and Divider color
  --neutralColor-4: #767391; // Disabled color
  --neutralColorRGB-4: 118, 115, 145; // Disabled color
  --neutralColor-5: #575485; // Secondary color
  --neutralColorRGB-5: 87, 84, 133; // Secondary color
  --neutralColor-6: #3a3878; // Normal text color
  --neutralColorRGB-6: 58, 56, 120; // Normal text color
  --neutralColor-7: #222252; // h1 and h2 headers color
  --neutralColorRGB-7: 34, 34, 82; // h1 and h2 headers color
  --neutralColor-8: #10112b;
  --neutralColorRGB-8: 16, 17, 43;
  --neutralColor-9: #020205;
  --neutralColorRGB-9: 2, 2, 5;

  // INFO COLORs
  --infoColor-1: #f0f9ff; // Selected background color
  --infoColorRGB-1: 240, 249, 255; // Selected background color
  --infoColor-2: #c7e7ff;
  --infoColorRGB-2: 199, 231, 255;
  --infoColor-3: #9ed2ff;
  --infoColorRGB-3: 158, 210, 255;
  --infoColor-4: #75baff; // Hover color
  --infoColorRGB-4: 117, 186, 255; // Hover color
  --infoColor-5: #4da0ff; // Normal color
  --infoColorRGB-5: 77, 160, 255; // Normal color
  --infoColor-6: #367dd9; // Click color
  --infoColorRGB-6: 54, 125, 217; // Click color
  --infoColor-7: #245db3;
  --infoColorRGB-7: 36, 93, 179;
  --infoColor-8: #15418c;
  --infoColorRGB-8: 21, 65, 140;
  --infoColor-9: #0e2c66;
  --infoColorRGB-9: 14, 44, 102;

  // SUCCESS COLORs
  --successColor-1: #f0fff3; // Selected background color
  --successColorRGB-1: 240, 255, 243; // Selected background color
  --successColor-2: #f0fff4;
  --successColorRGB-2: 240, 255, 244;
  --successColor-3: #ebfaef;
  --successColorRGB-3: 235, 250, 239;
  --successColor-4: #c0edce;
  --successColorRGB-4: 192, 237, 206;
  --successColor-5: #92e0ad; // Hover color
  --successColorRGB-5: 146, 224, 173; // Hover color
  --successColor-6: #68d391; // Normal color
  --successColorRGB-6: 104, 211, 145; // Normal color
  --successColor-7: #4cad75; // Click color
  --successColorRGB-7: 76, 173, 117; // Click color
  --successColor-8: #35875a;
  --successColorRGB-8: 53, 135, 90;
  --successColor-9: #216140;
  --successColorRGB-9: 33, 97, 64;

  // WARNING COLORs
  --warningColor-1: #fffae6; // Selected background color
  --warningColorRGB-1: 255, 250, 230; // Selected background color
  --warningColor-2: #ffeba3;
  --warningColorRGB-2: 255, 235, 163;
  --warningColor-3: #ffde7a;
  --warningColorRGB-3: 255, 222, 122;
  --warningColor-4: #ffce52;
  --warningColorRGB-4: 255, 206, 82;
  --warningColor-5: #ffbb29; // Hover color
  --warningColorRGB-5: 255, 187, 41; // Hover color
  --warningColor-6: #ffa500; // Normal color
  --warningColorRGB-6: 255, 165, 0; // Normal color
  --warningColor-7: #d98600; // Click color
  --warningColorRGB-7: 217, 134, 0; // Click color
  --warningColor-8: #b36800;
  --warningColorRGB-8: 179, 104, 0;
  --warningColor-9: #8c4d00;
  --warningColorRGB-9: 140, 77, 0;

  // ERROR COLORs
  --errorColor-1: #fff2f0; // Selected background color
  --errorColorRGB-1: 255, 242, 240; // Selected background color
  --errorColor-2: #ffcdc7;
  --errorColorRGB-2: 255, 205, 199;
  --errorColor-3: #ffa59e;
  --errorColorRGB-3: 255, 165, 158;
  --errorColor-4: #ff7a75; // Hover color
  --errorColorRGB-4: 255, 122, 117; // Hover color
  --errorColor-5: #f24848; // Normal color
  --errorColorRGB-5: 242, 72, 72; // Normal color
  --errorColor-6: #cc3338; // Click color
  --errorColorRGB-6: 204, 51, 56; // Click color
  --errorColor-7: #a6212a;
  --errorColorRGB-7: 166, 33, 42;
  --errorColor-8: #80131e;
  --errorColorRGB-8: 128, 19, 30;
  --errorColor-9: #590c17;
  --errorColorRGB-9: 89, 12, 23;

  // GRAYSCALE COLORS
  --grayscaleColor-1: #fff;
  --grayscaleColorRGB-1: 255, 255, 255;
  --grayscaleColor-2: #fafafa;
  --grayscaleColorRGB-2: 250, 250, 250;
  --grayscaleColor-3: #f5f5f5;
  --grayscaleColorRGB-3: 245, 245, 245;
  --grayscaleColor-4: #f0f0f0;
  --grayscaleColorRGB-4: 240, 240, 240;
  --grayscaleColor-5: #d9d9d9;
  --grayscaleColorRGB-5: 217, 217, 217;
  --grayscaleColor-6: #bfbfbf;
  --grayscaleColorRGB-6: 191, 191, 191;
  --grayscaleColor-7: #8c8c8c;
  --grayscaleColorRGB-7: 140, 140, 140;
  --grayscaleColor-8: #595959;
  --grayscaleColorRGB-8: 89, 89, 89;
  --grayscaleColor-9: #434343;
  --grayscaleColorRGB-9: 67, 67, 67;
  --grayscaleColor-10: #262626;
  --grayscaleColorRGB-10: 38, 38, 38;
  --grayscaleColor-11: #1f1f1f;
  --grayscaleColorRGB-11: 31, 31, 31;
  --grayscaleColor-12: #141414;
  --grayscaleColorRGB-12: 20, 20, 20;
  --grayscaleColor-13: #000;
  --grayscaleColorRGB-13: 0, 0, 0;

  // Border radius variables
  --borderRadius-s: 4px;
  --borderRadius-m: 8px;
  --borderRadius-l: 20px;
  --borderRadius-lg: 30px;
  --borderRadius-circle: 50%;

  --HEADER_MENUS_BOTTLE_EFFECT_BACKGROUND: rgba(var(--grayscaleColorRGB-1), 0.5);

  // custom style for setup from whitelabel
  --SECTION__borderRadius: var(--borderRadius-l);
  --AUTH_LOGO__backgroundColor: var(--grayscaleColor-1);

  --SIDE_MENU_ITEM_SUBTITLE__color: var(--grayscaleColor-8);
  --SIDE_MENU_ITEM_ACTIVE__color: var(--neutralColor-6);

  --SIDE_MENU_ITEM_MOBILE__color: var(--neutralColor-6);
  --SIDE_MENU_ITEM_ACTIVE_MOBILE__backgroundColor: var(--grayscaleColor-3);
  --SIDE_MENU_ITEM_HOVER_MOBILE__backgroundColor: var(--grayscaleColor-3);

  --INFO_PANEL_COMMENTS__backgroundColor: var(--grayscaleColor-1);

  --BODY__backgroundColor: linear-gradient(
      331deg,
      rgba(88, 153, 226, 0.2) 8.26%,
      rgba(239, 242, 255, 0.2) 102.34%
    ),
    #fff;

  --BREADCRUMBS__backgroundColor: transparent;

  --GROUPED_METRIC__labelColor: var(--neutralColor-6);

  --HEADER__backgroundColor: transparent;

  --HEADER_MOBILE__backgroundColor: var(--grayscaleColor-1);
  --HEADER_USER_AVATAR_MOBILE_backgroundColor: var(--primaryColor-6);
  --HEADER_MENU_MOBILE__color: var(--neutralColor-6);
  --HEADER_MOBILE__borderColor: var(--grayscaleColor-4);

  --HEADER_USER_AVATAR_color: var(--grayscaleColor-1);
  --HEADER_USER_AVATAR_backgroundColor: var(--primaryColor-6);
  --HEADER_USER_FIRSTNAME_color: var(--neutralColor-6);
  --HEADER_USER_LASTNAME_color: var(--primaryColor-5);

  --HEADER_MENU__color: var(--neutralColor-6);
  --HEADER_MENU_HOVER__color: var(--primaryColor-6);
  --HEADER_MENU_HOVER__borderColor: var(--primaryColor-6);
  --HEADER_MENU_ACTIVE__color: var(--primaryColor-6);
  --HEADER_MENU_ACTIVE__borderColor: var(--primaryColor-6);

  --HEADER_SUBMENU__borderColor: var(--grayscaleColor-1);
  --HEADER_SUBMENU__backgroundColor: var(--HEADER_MENUS_BOTTLE_EFFECT_BACKGROUND);
  --HEADER_SUBMENU_ITEM__color: var(--neutralColor-6);
  --HEADER_SUBMENU_ITEM_HOVER__backgroundColor: var(--primaryColor-2);
  --HEADER_SUBMENU_ITEM_HOVER__color: var(--neutralColor-6);

  --FEATURES_ENTITIES__backgroundColor: transparent;
  --FEATURES_ENTITIES__dividerColor: var(--grayscaleColor-3);
  --FEATURES_ENTITIES__titleColor: var(--neutralColor-6);
  --FEATURES_ENTITIES__linkColor: var(--neutralColor-6);
  --FEATURES_ENTITIES_HOVER__linkColor: var(--primaryColor-5);
  --FEATURES_ENTITIES_ACTIVE__linkColor: var(--primaryColor-5);

  --FEATURES_ENTITIES_REST_ITEMS_BUTTON__color: var(--neutralColor-4);
  --FEATURES_ENTITIES_REST_ITEMS_BUTTON__backgroundColor: var(--grayscaleColor-3);
  --FEATURES_ENTITIES_REST_ITEMS_BUTTON__borderColor: var(--grayscaleColor-5);
  --FEATURES_ENTITIES_REST_ITEMS_BUTTON_HOVER__color: var(--primaryColor-5);
  --FEATURES_ENTITIES_REST_ITEMS_BUTTON_HOVER__backgroundColor: var(--grayscaleColor-3);
  --FEATURES_ENTITIES_REST_ITEMS_BUTTON_HOVER__borderColor: var(--primaryColor-5);
  --FEATURES_ENTITIES_REST_ITEMS_MENU_ITEM__color: var(--neutralColor-4);
  --FEATURES_ENTITIES_REST_ITEMS_MENU_ITEM__backgroundColor: var(--grayscaleColor-1);
  --FEATURES_ENTITIES_REST_ITEMS_MENU_ITEM_HOVER__color: var(--neutralColor-4);
  --FEATURES_ENTITIES_REST_ITEMS_MENU_ITEM_HOVER__backgroundColor: var(--grayscaleColor-3);
}
