.wrapper {
  &:global {
    &.ant-tabs-top > .ant-tabs-nav::before {
      content: none;
    }

    &.ant-tabs > .ant-tabs-nav {
      margin: 0;
      z-index: 1;

      .ant-tabs-extra-content {
        -webkit-text-stroke-width: thin;
      }

      .ant-tabs-nav-operations {
        padding: 0 0.5rem 0.5rem;
        z-index: 1;
      }
      .ant-tabs-nav-wrap {
        .ant-tabs-tab {
          transition: none;
          z-index: 1;
          margin-left: 0;
          padding: 0;
          margin: 0 0.1rem 0.5rem;
          border-radius: var(--borderRadius-l) var(--borderRadius-l) 0 0;
          position: relative;
          border-color: transparent;
          background-color: transparent;

          .ant-tabs-tab-btn {
            transition: none;
            padding: 0.5rem;
            border-radius: var(--borderRadius-l);

            .ant-badge .ant-badge-count {
              top: 2px;
            }
          }

          &:nth-last-child(2) {
            margin-right: 12px;
          }

          &:first-of-type {
            margin-left: 0;
          }

          &:not(.ant-tabs-tab-active) {
            &:hover,
            &:has(.ant-tabs-tab-btn:focus),
            &:has(.ant-tabs-tab-btn:focus-visible) {
              .ant-tabs-tab-btn {
                background-color: var(--primaryColor-2);
              }
            }
          }

          .tabLabel {
            -webkit-text-stroke-width: thin;
            max-width: 200px;
            line-height: 1.25rem;
            color: var(--neutralColor-6);

            .layout-field {
              font-size: 16px;
              height: 100%;

              &::before {
                z-index: 1000;
                position: absolute;
                content: '';
                height: 0;
                bottom: -18px;
                background-color: var(--grayscaleColor-1);
                left: -1px;
                right: 0;
              }
            }
          }

          &.ant-tabs-tab-active {
            z-index: 0;
            transition: none;

            border-color: var(--grayscaleColor-5) var(--grayscaleColor-5) transparent
              var(--grayscaleColor-5);
            background-color: var(--grayscaleColor-1);

            .tabLabel {
              .layout-field {
                &::before {
                  height: 20px;
                }
              }

              &:after,
              &:before {
                content: '';
                width: 34px;
                height: 34px;
                position: absolute;
                bottom: -9px;
                border-radius: 100%;
                border-width: 1px;
                border-style: solid;
              }

              &:after {
                right: -34px;
                transform: rotate(-41deg);
                border-color: transparent;
                border-left-color: var(--grayscaleColor-5);
              }

              &:before {
                left: -34px;
                transform: rotate(41deg);
                border-color: transparent;
                border-right-color: var(--grayscaleColor-5);
              }
            }

            &:after,
            &:before {
              content: '';
              width: 47px;
              height: 47px;
              position: absolute;
              bottom: -16px;
              border-radius: 100%;
              border-width: 7px;
              border-style: solid;
            }

            &:after {
              right: -40px;
              transform: rotate(-47deg);
              border-color: transparent;
              border-left-color: var(--grayscaleColor-1);
            }

            &:before {
              left: -40px;
              transform: rotate(47deg);
              border-color: transparent;
              border-right-color: var(--grayscaleColor-1);
            }
          }
        }
      }
    }

    &.hide-tab-panel {
      border-top-width: 0;

      .ant-tabs-nav {
        display: none;
      }
    }
  }
}
