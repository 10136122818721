.wrapper {
  position: relative;
  border-top: 1px solid var(--grayscaleColor-5);

  :global {
    #stripoSettingsContainer {
      width: 400px;
      float: left;

      .nav {
        display: block;
      }
    }

    #stripoPreviewContainer {
      width: calc(100% - 400px);
      float: left;
      border-left: 1px solid var(--grayscaleColor-5);
    }
  }
}
