@import '../../../../../variables.scss';

.container {
  height: 100%;
  overflow: auto;

  :global {
    .form-buttons-container {
      position: absolute;
      top: 0;
      right: 0;
      justify-content: flex-end;
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
      padding: 0.5rem 1rem 0 0;

      .DropdownButton {
        button.menu,
        button.main {
          min-height: 33px;
        }
      }

      > button,
      a {
        height: 33px;
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        width: 100%;
      }
    }

    .available-select {
      &.ant-select-multiple {
        cursor: pointer;
      }

      &.ant-select-focused .ant-select-selection-placeholder {
        display: none !important;
      }
    }
  }
}
