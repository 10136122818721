.dropdown {
  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:global {
      &.ant-dropdown-open {
        :local(.dropdown__icon) {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__icon {
    font-size: 1.5rem;
    margin-left: 0.5rem;
    position: relative;
    top: -4px;
    color: var(--HEADER_MENU__color);
    transition: transform 0.3s ease;
  }
}

.avatar {
  margin-right: 0.5rem;
  font-size: 1rem;
  background-color: var(--HEADER_USER_AVATAR_backgroundColor);
  border-radius: 50%;
  width: 42px;
  height: 42px;
  font-weight: 600;
  color: var(--HEADER_USER_AVATAR_color);
  border: 3px solid var(--HEADER_USER_AVATAR_backgroundColor);
  padding: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid var(--HEADER_USER_AVATAR_color);
    border-radius: 50%;
  }
}

.names {
  text-transform: uppercase;
  max-width: 15rem;

  &__first {
    font-weight: 600;
    color: var(--HEADER_USER_FIRSTNAME_color);
    font-size: 12px;
    line-height: 15px;
  }

  &__last {
    color: var(--HEADER_USER_LASTNAME_color);
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    max-width: 15rem;
  }
}
