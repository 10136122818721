.metricsWrp {
  color: inherit;
  border-radius: var(--borderRadius-l);

  :global {
    .ant-select .ant-select-selector {
      padding-right: 0.7rem !important;
      padding-left: 0.2rem !important;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      background-color: transparent;

      line-height: 14px;
      min-height: unset;

      &:after {
        line-height: 14px;
        margin: 0;
      }

      .ant-select-selection-item {
        margin: 0;
        padding: 0;
        line-height: 14px;
        height: 14px;
        font-size: 12px;
        background-color: transparent;

        .ant-tag-close-icon {
          display: none;
        }
      }

      .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
        display: none;
      }
    }
    .label {
      max-width: 70px;
      margin-right: 2px;
    }
    .count {
      display: none;
    }

    .ant-select-arrow {
      right: 0 !important;
      color: var(--neutralColor-6);
    }

    .ant-select-selection-overflow {
      flex-wrap: nowrap;
      justify-content: end;
      max-width: 100% !important;
      padding: 0 !important;

      .ant-tag {
        border: none;
        font-size: 14px;
      }
    }
  }
}

.metricsDropdownPopup {
  box-shadow: 4px 0 20px rgba(var(--neutralColorRGB-8), 0.12);
  width: 400px;
  max-height: 23rem !important;

  :global {
    .ant-select-item-option-content {
      display: flex;

      > span {
        width: 100%;
      }
    }

    .count > span {
      font-family: var(--fontFamily);
      background-color: var(--primaryColor-2);
      font-weight: 600;
      padding: 3px 10px;
      border-radius: 16px;
      font-size: 10px;
      line-height: 2;
    }
    .label {
      max-width: 300px;
    }
    .toggle-selectAll {
      border-top: 1px solid var(--grayscaleColor-5);
      button {
        color: var(--primaryColor-6);
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:hover {
          background: var(--primaryColor-2);
        }
      }
    }
    .no-results {
      background: var(--primaryColor-2);
      color: var(--grayscaleColor-9);
      font-weight: 500;
    }
  }
}
.allowTopResult {
  max-height: 26rem !important;
}
