.outer {
  position: relative;

  &:global {
    &.collapsed {
      background: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--SECTION__borderRadius);

      .arrow-icon-outer {
        transform: rotate(0deg);
      }

      .top-section {
        border: none;
      }
    }
  }

  :global {
    .top-section {
      min-height: 52px;
      position: relative;

      .ant-tabs-content-holder {
        overflow: hidden;
        border-radius: var(--SECTION__borderRadius);
        background: var(--grayscaleColor-1);
        border: 1px solid var(--grayscaleColor-5);
        margin-top: -1px;
      }

      > .ant-tabs {
        > .ant-tabs-nav {
          margin-bottom: 0;

          .ant-tabs-ink-bar {
            bottom: -4px;
          }
          .ant-tabs-tab {
            &:nth-last-child(2) {
              margin-right: 23px;
            }
          }

          .ant-tabs-nav-wrap {
            padding-left: 10px;
          }

          .ant-tabs-tab-btn {
            display: flex;
            align-items: center;
            color: var(--neutralColor-6);
            font-size: 14px;
            padding-right: 8px;
            padding-left: 8px;
            transition: color 0.3s ease;
            padding: 0.4rem 1rem;
            border-radius: var(--borderRadius-l);

            &:hover {
              color: var(--primaryColor-6);
            }

            svg {
              color: var(--neutralColor-6);
              transition: color 0.3s ease;
            }
          }
        }
      }

      .ExistingMessagesOuter {
        padding-left: 2rem;
        max-height: 300px;
        overflow: auto;
        padding-top: 1rem;
      }

      .MessageContainerOuter {
        margin-left: 0;
        padding-top: 1px;

        .MessageContainerOuter {
          margin-left: 1rem;
        }
      }

      .ReplyingMessageOuter {
        .MessageFooterOuter {
          padding-right: 24px;
        }
      }

      .MainMessageOuter {
        background: var(--grayscaleColor-1);
        box-shadow: 0 -4px 8px rgba(var(--neutralColorRGB-4), 0.1);
        border-radius: 0 0 8px 8px;
        padding: 16px 24px 8px;
        margin-top: 0;

        .MessageContentOuter {
          padding-right: 0;
          padding-left: 0;
        }

        .editor__input {
          min-height: 48px;
          padding-top: 11px;

          &::placeholder {
            font-size: 14px;
            color: var(--neutralColor-1);
          }
        }

        .editor__highlighter {
          min-height: 48px;
        }
      }

      .MessageFooterOuter {
        padding-right: 0;

        .SaveButton {
          margin-right: 0;
          margin-left: 1rem;
          width: 82px;
          height: 40px;
        }

        .CancelButton {
          width: 82px;
          height: 40px;
          background: var(--grayscaleColor-3);
          border: 1px solid var(--grayscaleColor-5);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--neutralColor-4) !important;
          font-weight: 600;
          font-size: 14px;
          transition: color 0.3s, border-color 0.3s;

          &:hover {
            text-decoration: none;
            color: var(--primaryColor-6) !important;
            border-color: var(--primaryColor-6);
          }
        }
      }
    }

    .arrow-icon-outer {
      cursor: pointer;
      transform: rotate(180deg);
      padding: 10px;
      display: flex;
      justify-content: center;
      z-index: 9;

      &:hover {
        svg {
          color: var(--primaryColor-5);
        }
      }

      svg {
        color: var(--neutralColor-4);
      }
    }

    .referrals-icons-col {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__common {
        &-icons {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 58px;
          width: 100%;
        }

        &-icon {
          &:hover {
            svg {
              color: var(--primaryColor-6);
            }
          }

          svg {
            color: var(--neutralColor-6);
            transition: color 0.3s ease;
          }
        }
      }

      .referrals-icon {
        margin-bottom: 24px;
        cursor: pointer;

        .SideMenuIconOuter {
          width: 32px;
          height: 32px;
          font-size: 19px;
        }
      }
    }

    .referrals-tables-outer {
      padding-top: 8px;

      .collapse-header-title {
        display: flex;
        align-items: center;

        .SideMenuIconOuter {
          margin-right: 8px;
        }
      }

      .ant-collapse-content-box {
        padding: 0 !important;
      }

      .ReferredItemsTableContainerOuter {
        padding: 0;
      }

      .ant-pagination {
        margin-right: 8px;
      }
    }

    .MetricsDisplayOuter {
      display: none;
    }
  }
}

.historyContainer {
  padding: 24px 16px;
  overflow: auto;
  max-height: calc(100vh - 250px);
  min-height: 600px;
}
