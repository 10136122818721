.loader {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -999;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--grayscaleColorRGB-1), 0.86);
  transition: opacity 0.3s, z-index 0s 0.3s;

  :global {
    .LoadingScreenBlocker {
      &__message {
        font-style: italic;
        font-weight: 600;
        font-size: 40px;
        line-height: 49px;
        color: var(--neutralColor-4);
        position: absolute;
        bottom: 10vh;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &:global {
    &.visible {
      opacity: 1;
      pointer-events: all;
      z-index: 99999999;
      transition: opacity 0.3s;
    }
  }
}
