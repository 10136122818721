.lightbox {
  :global {
    .pdf-viewer {
      margin-top: 5rem;
    }
    .wrp-img-slide img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

:global {
  .hide-some-toolbar-buttons {
    .yarl__toolbar {
      button[aria-label='Zoom in'].yarl__button,
      button[aria-label='Zoom out'].yarl__button,
      button[aria-label='Download'].yarl__button {
        display: none;
      }
    }
  }
}

.counter {
  color: white;
  position: absolute;
  top: 18px;
  right: 65px;
}
