.container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  &:global {
    &:hover {
      .DesktopSideMenuItem__settings-link,
      .FeaturedButton {
        opacity: 1;
        border-radius: var(--borderRadius-lg);
        background: rgba(var(--grayscaleColorRGB-1), 0.1) !important;
        outline: 1px solid rgba(var(--grayscaleColorRGB-1), 0.8);
        box-shadow: 0px 2px 10px 0px rgba(17, 29, 73, 0.15);
        backdrop-filter: blur(16px);
        border: none;

        &:hover {
          color: var(--primaryColor-6) !important;
          outline: 1px solid var(--primaryColor-6);
        }
      }

      .DesktopSideMenuItem__nav-link {
        background-color: var(--primaryColor-2);
      }
      .DesktopSideMenuItem__nav-link .DesktopSideMenuItem__text {
        color: var(--SIDE_MENU_ITEM_ACTIVE__color);
      }

      .DesktopSideMenuItem__nav-link .DesktopSideMenuItem__subtitle-text {
        color: var(--SIDE_MENU_ITEM_SUBTITLE__color);
      }
    }
  }

  :global {
    .FeaturedButton {
      opacity: 0;
      font-size: 1rem;
      padding: 0.5rem;
      color: var(--neutralColor-6) !important;
      transition: none;
      cursor: pointer;

      svg {
        display: block;
      }
    }

    .DesktopSideMenuItem {
      &__actions-block {
        position: absolute;
        display: flex;
        top: calc(50% - 18px);
        right: 0.25rem;
        gap: 0.25rem;
        padding: 2px 0;
      }

      &__settings-link {
        opacity: 0;
        font-size: 1rem;
        padding: 0.5rem;
        color: var(--neutralColor-6) !important;
        transition: none;
        cursor: pointer;

        svg {
          display: block;
        }
      }

      &__subtitle-text {
        color: var(--grayscaleColor-8);
        font-size: 10px;
        display: block;
        line-height: 12px;
        font-weight: 600 !important;
        margin-bottom: 0.25rem;
      }

      &__text {
        word-break: break-word;
        font-family: var(--fontFamily);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        color: var(--neutralColor-6);
      }

      &__nav-link {
        display: flex;
        align-items: center;
        border: none;
        position: relative;
        padding-left: 2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 1rem;
        border-top-right-radius: var(--borderRadius-lg);
        border-bottom-right-radius: var(--borderRadius-lg);

        .item-icon {
          overflow: inherit !important;
        }

        &.active {
          border: none;
          background-color: var(--primaryColor-3);
        }
      }
    }

    .DesktopSideMenuItem__nav-link.active .DesktopSideMenuItem__text {
      color: var(--SIDE_MENU_ITEM_ACTIVE__color);
    }

    .DesktopSideMenuItem__nav-link.active .DesktopSideMenuItem__subtitle-text {
      color: var(--SIDE_MENU_ITEM_SUBTITLE__color);
    }

    .SideMenuIconOuter {
      min-width: 20px;
      width: 20px;
      height: 20px;
      color: var(--neutralColor-6);
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
