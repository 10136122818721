.wrapper {
  :global {
    .react-phone-number-input__error {
      position: initial;
      padding: 0;
    }
  }
}

.error {
  :global {
    .label {
      color: var(--errorColor-5);
    }
  }
}
