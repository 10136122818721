.columnsSelect {
  max-width: 250px;

  :global {
    .ant-select.ant-select {
      .ant-select-selection-placeholder {
        color: var(----neutralColor-6) !important;
        font-weight: 600 !important;
      }
    }
  }

  &__placeholderIcon {
    font-size: 16px;
    margin-right: 14px;
  }
}
