.container {
  :global {
    .react-phone-number-input__error {
      position: initial;
      padding: 0;
    }
  }
}

.collapse {
  &:global {
    &.ant-collapse.ant-collapse {
      padding-right: 0;
      border-bottom: 0;

      .ant-collapse-item {
        padding-top: 2rem;
      }

      .ant-collapse-header {
        padding-left: 0;
        align-items: center;

        i {
          left: 0;
          line-height: 0;
        }
      }

      .ant-collapse-content {
        overflow: visible;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }

  :global {
    .panel-header-title {
      font-size: 1.1rem;
    }
  }
}

.propertyContainer {
  position: relative;
  margin-top: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:global {
    & > div {
      width: 65%;
      flex-shrink: 0;
    }
  }

  :global {
    .FieldLabelOuter {
      width: 35%;
      flex-shrink: 0;
    }

    .FileFieldOuter {
      width: 100%;
    }

    .FieldErrorOuter {
      padding-left: 0;
      margin-left: 35%;
    }
  }
}
