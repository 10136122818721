.fullWidthBtn {
  width: 100%;
  text-align: left;
  padding: 0.5rem 0.7rem;
  line-height: 1rem;

  :global {
    * {
      pointer-events: none;
    }

    svg {
      font-size: 1.1rem;
    }

    .text {
      padding-left: 0.4rem;
    }
  }
}

.toggleShowHiddenFieldsLabel {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}
