@import '../../../variables';

.outer {
  @media (max-width: map-get($grid-breakpoints, 'md')) {
    display: block;
  }

  :global {
    .ViewTitleContainer {
      font-size: 21px;
      line-height: 1.5rem;
      margin-bottom: 1rem;
      overflow: visible;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      display: flex;
      align-items: center;

      @media screen and (min-width: 48em) {
        margin-bottom: 0;
      }

      .ViewTitleContainer__count {
        margin-right: 12px;
        font-weight: 600;
      }

      .ViewTitleContainer__title {
        overflow: hidden;

        .title-inline-editor span {
          font-size: 21px;
        }

        .EditableItemTitleOuter {
          max-width: 100%;
        }

        .editor-row {
          .FieldWrapperOuter {
            min-height: auto;

            input {
              height: auto;
            }
          }
        }

        .title-inline-editor {
          &.editing {
            input {
              font-size: 0.9rem;
            }
          }
        }
      }

      .ViewTitleContainer__countWarning {
        width: 100%;
        color: var(--warningColor-6);
        font-size: 16px;
        position: relative;
        font-weight: 600;
        height: 33px;
        display: flex;
        align-items: center;

        @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
          width: auto;
          padding-left: 40px;

          &:before {
            content: '';
            height: 24px;
            width: 1px;
            background-color: var(--grayscaleColor-5);
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .editor-row .InlineEditorOuter.inline-editor {
      max-width: 100%;

      .edit-actions {
        background: transparent;
      }
    }

    .SelectionRowContainer {
      @media (max-width: 767px) {
        margin-top: -20px;
        margin-bottom: 28px;
        padding-left: 0;
      }

      .ant-divider:first-child {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
