.wrapper {
  :global {
    .logs-header {
      background: rgba(var(--grayscaleColorRGB-1), 0.5);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--borderRadius-lg);
    }

    .logs-content {
      background: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--bs-border-radius-xl);
    }

    .logs-table {
      font-size: 0.8rem;
    }
  }
}
