@import '../../../../../variables';

.container {
  position: relative;

  :global {
    .editable-item-header {
      .ItemActionsButtonOuter {
        margin-left: auto;

        > span {
          position: static;
        }
      }
    }

    .collapse-header-title {
      font-size: 1.1rem;
    }

    .conversation-container {
      white-space: pre-wrap;
    }

    .scrollable-area {
      position: relative;
      overflow-y: auto;
      height: 100%;

      &.loading {
        visibility: hidden;
      }
    }

    .data-list-outer {
      &:not(.info-panel-collapsed) {
        @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
          flex-direction: column;
        }

        .data-list-container {
          @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
            width: 100%;
            order: 2;
          }
        }
      }

      :global {
        .ResizableOuter {
          height: fit-content;
        }
      }

      .InfoPanelOuter {
        flex-shrink: 0;
        margin-left: auto;

        &:not(.collapsed) {
          @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
            width: 100%;
            order: 1;
          }
        }
      }

      &.info-panel-collapsed {
        .InfoPanelOuter {
          width: 56px;
        }
      }
    }

    .GenericEntitySelectorOuter {
      border-radius: 0 var(--borderRadius-l) var(--borderRadius-l) 0;
    }
  }
}
