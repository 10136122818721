.numberOfPage {
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--neutralColor-4);
}
.buttonBack,
.buttonForward {
  background: transparent !important;
  border: none !important;
  border-radius: var(--borderRadius-circle) !important;
  aspect-ratio: 1;
  &:has(svg):hover {
    background: var(--primaryColor-2) !important;
    color: inherit !important;
  }
}
