@import '../../../../variables';

.container {
  backdrop-filter: blur(16px);

  :global {
    .form-action-button {
      height: 40px;
      border-radius: 20px;
      max-width: 50%;
      border: none;

      &.ant-btn-primary:disabled {
        color: var(--grayscaleColor-1);
        background-color: var(--grayscaleColor-5);
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        flex: 1 1 auto;
      }

      &.cancel {
        color: var(--primaryColor-6) !important;
      }
    }
  }
}
