.wrapper {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: var(--SECTION__borderRadius);

  &:global {
    &.hide-tab-panel {
      border-radius: var(--SECTION__borderRadius);
    }
  }

  &:global {
    &.process-container {
      .FieldWrapperOuter,
      .ant-select-selector {
        border-radius: var(--borderRadius-l) !important;
      }
    }
  }
  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
      &:first-of-type {
        .tabLabel {
          .layout-field:before {
            border-left: 1px solid var(--grayscaleColor-5);
          }
        }
        &:before,
        .tabLabel:before {
          content: none !important;
        }
      }
    }
    .section {
      background: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--borderRadius-l);

      &.first-section {
        border-top-left-radius: 0;
      }
      .ant-collapse > .ant-collapse-item.ant-collapse-item.ant-collapse-item {
        background-color: transparent;
        border-radius: 0;
        border: none;
        margin: 10px;

        .ant-collapse-content {
          border: none;
        }
      }
    }

    .ant-tabs-tabpane {
      margin-top: -1px;
    }

    .ReferredItemsTableContainerOuter,
    .ReferredItemsTableContainerLoading {
      margin-top: -1px;
      padding: 0.5rem;
      background-color: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--borderRadius-l) 0 var(--borderRadius-l) var(--borderRadius-l);
    }
  }

  &:global {
    &.hide-tab-panel {
      border-top-width: 0;

      .ant-tabs-content-holder .ant-tabs-tabpane {
        margin-top: 0;

        .section {
          border-top-left-radius: var(--borderRadius-l);
        }
      }

      .ant-tabs-nav {
        display: none;
      }
    }
  }
}

.referralsTab {
  color: var(--neutralColor-6);
  max-height: 42px;
  margin-bottom: 8px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: var(--borderRadius-l) var(--borderRadius-l) 0 0;

  :global {
    .referralsLabel {
      line-height: 1.25rem;
      max-width: 170px;
    }
  }

  &:global {
    &.active {
      color: var(--neutralColor-6);
      background-color: var(--grayscaleColor-1);
      border-color: var(--grayscaleColor-5) var(--grayscaleColor-5) transparent
        var(--grayscaleColor-5);

      &:before {
        content: '';
        width: 34px;
        height: 34px;
        position: absolute;
        bottom: -9px;
        border-radius: 100%;
        border-width: 1px;
        border-style: solid;
        left: -34px;
        transform: rotate(41deg);
        border-color: transparent;
        border-right-color: var(--grayscaleColor-5);
      }
      &:after {
        content: '';
        width: 47px;
        height: 47px;
        position: absolute;
        bottom: -16px;
        border-radius: 100%;
        border-width: 7px;
        border-style: solid;
        left: -40px;
        transform: rotate(47deg);
        border-color: transparent;
        border-right-color: var(--grayscaleColor-1);
      }

      .layout-referrals {
        &:after {
          position: absolute;
          content: '';
          height: 16px;
          bottom: -15px;
          border-right: 1px solid var(--grayscaleColor-5);
          background-color: var(--grayscaleColor-1);
          left: 0;
          right: -1px;
        }
      }

      .referralsLabel {
        color: var(--neutralColor-6);
      }
    }
  }
}

.referralsTabIcon {
  margin-right: 0.4375rem;
  font-size: 1.1875rem;
  cursor: pointer;
}

:global {
  .ant-tabs-dropdown-menu-item[id$='-more-popup-__referrals'] {
    display: none;
  }
}
.container {
  margin-right: -1rem;
}

.label {
  font-weight: 400;
  padding-left: 1rem;
  font-size: 0.9rem;
  opacity: 1;

  &:global {
    &.disabled {
      opacity: 0.5;
    }
  }
}
.arrowButton {
  color: var(--neutralColor-6);
  border-color: transparent;
  background-color: transparent;

  &:hover {
    border-color: transparent !important;
    background-color: var(--primaryColor-1) !important;
    color: var(--neutralColor-6) !important;
  }
}
