.container {
  position: relative;
  margin-bottom: 1rem;
  @media screen and (min-width: 80em) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
}

.wrapper {
  & > div {
    border-radius: var(--SECTION__borderRadius);
    box-shadow: none;
  }

  :global {
    .BasicInputOuter input,
    .BasicTextAreaOuter textarea,
    .DefaultInputOuter,
    .integrationsSearch {
      border-radius: var(--borderRadius-l);
    }
  }
}
