.item {
  border-radius: var(--borderRadius-l) !important;
  transition: 0.3s;
  cursor: pointer;
  padding: 12px;

  &:hover {
    background-color: var(--primaryColor-2);
  }

  &:global {
    &.active {
      background-color: var(--primaryColor-3);
    }
  }
}

.tag {
  color: var(--grayscaleColor-1);
  background-color: var(--grayscaleColor-3);
  border-color: var(--grayscaleColor-3);
  height: 25px;
  font-size: 14px;
  margin-inline-end: 0;
  min-width: 68px;
}

.noResults {
  color: var(--neutralColor-6);
}

.input {
  :global {
    input.ant-input {
      color: var(--neutralColor-6);

      &::placeholder {
        color: var(--neutralColor-6);
        font-weight: 500;
      }
    }
  }
}
