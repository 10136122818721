@import '../../../../../variables';

.editor {
  :global {
    .label-text {
      color: var(--neutralColor-6);
      font-weight: 600;
      font-style: italic;

      @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        font-weight: 400;
      }
    }

    .edit-actions {
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }

    .InlineEditorOuter {
      width: 100%;
      height: 25px;

      .display-priority-wrapper {
        height: 100%;
      }
    }
    .FieldWrapperOuterSimple {
      padding: 0;
    }
  }
}

.priorityField {
  width: 75%;
  height: 40px;

  :global {
    .display-priority-wrapper {
      .open-item-info-drawer-link {
        font-weight: 500;
      }

      &.class-2-LOW {
        .open-item-info-drawer-link {
          color: var(--successColor-6);
        }
      }

      &.class-4-NORMAL {
        .open-item-info-drawer-link {
          color: var(--neutralColor-6);
        }
      }

      &.class-6-HIGH {
        .open-item-info-drawer-link {
          color: var(--warningColor-6);
        }
      }

      &.class-8-URGENT {
        .open-item-info-drawer-link {
          color: var(--errorColor-5);
        }
      }
    }

    .display-priority-icon-wrapper {
      margin-right: 8px;

      &.class-2-LOW {
        svg {
          color: var(--successColor-6) !important;
        }
      }

      &.class-4-NORMAL {
        svg {
          color: var(--neutralColor-6) !important;
        }
      }

      &.class-6-HIGH {
        color: var(--warningColor-6) !important;

        svg {
          color: var(--warningColor-6) !important;
        }
      }

      &.class-8-URGENT {
        svg {
          color: var(--errorColor-5) !important;
        }
      }
    }
  }
}

.deadlineField {
  :global {
    .NoDeadlineOuter,
    .DeadlineDisplayTextOuter {
      border: none;
    }
    .DeadlineDisplayOuter {
      border: none;
      padding-left: 0 !important;
      color: var(--neutralColor-6);
    }
    .DeadlineIconOuter {
      color: var(--errorColor-5);
      background: none;
      width: auto;
      height: auto;
      margin-left: 0;
    }
  }
  width: 75%;
  height: 42px;
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--neutralColor-6);
}
