.wrapper {
  :global {
    .widget-body {
      .control-panel {
        margin: 0 !important;
        margin-bottom: 1rem !important;
      }

      .activity-list {
        padding: 0 !important;
        height: calc(100% - 56px);
        overflow: auto;
      }

      .no-activity-img {
        height: calc(100% - 90px);
        min-height: 100px;
        max-height: 400px;
      }
    }
  }
}
