.drawer {
  :global {
    .details-row {
      margin-bottom: 8px;
    }

    .sending-success {
      color: var(--successColor-7);
    }

    .sending-process {
      color: var(--primaryColor-5);
    }

    .sending-warn {
      color: var(--warningColor-6);
    }

    .sending-fail {
      color: var(--errorColor-5);
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .ant-modal {
      width: 1190px !important;

      .ant-modal-body {
        padding-bottom: 0;
      }
    }

    .ReactTable {
      border: 1px solid transparent;
    }

    .rt-tbody {
      .rt-td {
        overflow: hidden;

        .recipients-list {
          display: block;

          div {
            line-height: 1.5rem;
          }
        }

        .status-cell {
          display: block;
          line-height: 1.5rem;
        }

        .timestamp-cell,
        .sending-success,
        .sending-process,
        .sending-warn,
        .sending-fail {
          display: block;
          line-height: 1.5rem;
        }

        .timestamp-cell {
          font-size: 0.8rem;
        }

        .mail-preview {
          .icon-btn {
            border: none;
            margin: 0 5px;
          }
        }

        .sending-success {
          color: var(--successColor-7);
        }

        .sending-process {
          color: var(--primaryColor-5);
        }

        .sending-warn {
          color: var(--warningColor-6);
        }

        .sending-fail {
          color: var(--errorColor-5);
        }

        .expanded-details {
          display: block;

          &__row {
            display: flex;
            line-height: 1.5rem;
          }

          &__label {
            margin-right: 5px;
          }

          &__value {
            font-size: 0.8rem;
            color: rgba(var(--grayscaleColorRGB-13), 0.45);
            white-space: initial;
            word-break: initial;
          }
        }
      }
    }
  }
}
