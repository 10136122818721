.wrapper {
  :global {
    .alertIcon {
      margin-left: 0.3rem;
      font-size: 1.1rem;
    }

    .ant-input.ant-input,
    .ant-input-number.ant-input-number {
      border-radius: 20px;
    }

    .ant-tabs-tab-btn-disabled svg {
      opacity: 0.3;
    }
    .ant-tabs-tab-prev-icon-target,
    .ant-tabs-tab-next-icon-target {
      fill: var(--grayscaleColor-13);
      transform: none;
    }
    .ant-tabs-nav {
      .ant-tabs-tab-active {
        font-weight: normal;
      }
      .ant-tabs-tab {
        margin-right: 0;
      }
      .invalid {
        color: var(--warningColor-6);
        font-weight: 600;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--primaryColor-6);
    }

    .ant-tabs-ink-bar {
      background: var(--primaryColor-6);
    }

    .ant-tabs-tab:hover {
      color: var(--primaryColor-5);
    }

    .ant-tabs .ant-tabs-tab-btn:focus-visible {
      outline-width: 1px;
      outline-offset: -1px;
    }
  }
}
