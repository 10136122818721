.wrapper {
  :global {
    .thumbnail {
      width: 70px;
      min-height: 53px;
      outline: 1px solid var(--infoColor-1);
      background: var(--grayscaleColor-1);
    }

    .subject {
      color: var(--grayscaleColor-7);
    }

    .actions {
      width: 11rem !important;
    }

    .edit-btn,
    .copy-btn {
      svg {
        fill: var(--neutralColor-6);
        transition: all 0.3s;
      }

      &:hover {
        svg {
          color: var(--neutralColor-6);
          fill: var(--primaryColor-5);
        }
      }
    }

    .delete-btn {
      &.disabled {
        min-width: 40px;
        opacity: 0.3;
      }
    }
  }
}
