.wrapper {
  :global {
    .widget-wrapper {
      height: 400px;
      position: sticky;
      top: 0;
    }

    .ant-collapse.ant-collapse {
      padding-right: 0;
    }

    .ant-collapse.ant-collapse .ant-collapse-item {
      border-bottom: 1px solid var(--grayscaleColor-5);
    }

    .ant-collapse.ant-collapse.ant-collapse-ghost {
      border-bottom: 0;

      .ant-collapse-item {
        border-bottom: 0;
        padding-top: 10px;

        &:first-child {
          padding-top: 0;
        }
      }

      .ant-collapse-header {
        background: none;
        padding: 0 0 0 20px;
      }

      .ant-collapse-arrow {
        line-height: 24px;
        border-right: none;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
      }
    }

    .ant-collapse.ant-collapse .ant-collapse-item:last-child {
      border-bottom: 0;
    }

    .ant-collapse.ant-collapse .ant-collapse-header {
      padding: 8px 8px 8px 0;
      background: var(--grayscaleColor-4);
    }

    .ant-collapse.ant-collapse .ant-collapse-arrow {
      line-height: 38px;
      border-right: 1px solid var(--grayscaleColor-5);
      padding-right: 8px;
      padding-left: 8px;
    }

    .filter-query {
      .antd-tree-select-btn {
        width: 100% !important;
        margin-bottom: 0.5rem;
      }
      .ConditionQueryOuter {
        flex-direction: column;
        align-items: flex-start !important;
        > label {
          width: 100%;
          padding-bottom: 0.5rem !important;
        }
        > div {
          width: 100%;
        }
        .PropertyFilterOuter {
          flex-direction: column !important;
          gap: 0.5rem !important;

          & > div {
            width: 100%;
            margin: 0 !important;
          }
        }
      }
    }
    .BasicSelectOuter,
    .BasicInputOuter input {
      border-radius: var(--borderRadius-l);
    }
  }
}
