.groups {
  &__title {
    font-weight: 600;
    font-size: 1.1rem;
    padding: 1rem 0;
  }

  &__content {
    margin: 4rem 0.5rem 0.5rem;
    position: relative;
    overflow-y: auto;
  }

  &__groupsIcon {
    font-size: 14rem;
    max-width: 100%;
  }

  &__container {
    padding-top: 0;

    @media screen and (min-width: 80em) {
      width: 850px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
