@import '../../../../variables.scss';

.wrapper {
  max-width: none !important;

  &:global {
    & > .content > .nav-bar {
      width: 50%;

      .ant-tabs-nav {
        height: 50px;
        margin: 0;

        @media screen and (max-width: map-get($grid-breakpoints,'md')) {
          height: auto !important;
        }

        &::before {
          border-bottom: 1px solid var(--grayscaleColor-5);
          width: 200% !important;
        }
      }

      :global {
        .ant-tabs-nav-list {
          .link {
            font-size: 1.1rem;
            font-weight: 400;
            color: var(--neutralColor-6);
            padding: 10px;
            display: flex;
            align-items: center;
            height: 100%;
            text-decoration: none;
            padding: 0.5rem !important;
          }

          .ant-tabs-ink-bar {
            display: none;
          }
        }

        .ant-tabs-tab-active {
          :global {
            .link {
              color: var(--primaryColor-6) !important;
            }
          }
        }

        .ant-tabs-tab {
          margin: 0 !important;

          &:after {
            bottom: 0;
            margin-left: 0.5rem;
            background: var(--primaryColor-6);
            content: '';
            display: block;
            height: 2px;
            border-radius: 1px;
            width: 0;
            position: absolute;
            transition: width 200ms ease-in-out;
          }

          &:hover:after,
          &.ant-tabs-tab-active:after {
            width: calc(100% - 1rem);
          }
        }
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        width: 100% !important;

        .ant-tabs-nav-list {
          flex-wrap: wrap !important;
        }
      }
    }
  }
  :global {
    .ProcessesButton {
      max-width: 150px;
    }
  }
}
