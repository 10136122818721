.actions {
  position: absolute;
  right: 0;
  z-index: 100;
  display: flex;
  background-color: var(--grayscaleColor-1);
  border: 0;
  border-radius: 4px;

  &:global {
    &.pos-top {
      top: -33px;
    }

    &.pos-bottom {
      bottom: -33px;
    }
  }

  :global {
    .action-button {
      width: 28px;
      height: 28px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--grayscaleColor-1);
      cursor: pointer;
      padding: 0;
      border: 1px solid var(--grayscaleColor-5);
      color: var(--grayscaleColor-1);
      font-size: 16px;
      transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;

      &.ok {
        border-radius: 4px 0 0 4px;
        background-color: var(--successColor-6);

        &:hover {
          color: var(--successColor-6);
          border-color: var(--successColor-6);
          background-color: var(--grayscaleColor-1);
        }
      }

      &.cancel {
        border-radius: 0 4px 4px 0;
        background-color: var(--errorColor-5);

        &:hover {
          color: var(--errorColor-5);
          border-color: var(--errorColor-5);
          background-color: var(--grayscaleColor-1);
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.45;
      }
    }
  }
}
