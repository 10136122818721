.wrapper {
  background: rgba(var(--grayscaleColorRGB-1), 0.5);
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  padding: 0.5em;
  padding-left: 1em;
  margin-bottom: 1rem;
  border: 1px solid var(--grayscaleColor-5);
  border-radius: var(--borderRadius-lg);

  :global {
    .item-title {
      font-size: 20px;
      min-width: 0;
      flex: 1;

      .edit-actions {
        .safe-space {
          width: 36px;
          height: 36px;
        }
      }
    }
    .ItemActionsButtonOuter {
      .action-button--copy {
        border-radius: var(--borderRadius-lg);
      }
    }
  }
}
