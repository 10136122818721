.wrapper {
  :global {
    & {
      line-height: 1.5rem;
    }
    .clickable-link {
      white-space: nowrap;
    }
  }
}

.divider {
  height: 1.3rem;
}
