.wrapper {
  border-radius: var(--borderRadius-l);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--neutralColor-6);
  min-height: 40px;
  cursor: text;
  transition: border-color 0.3s ease;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid transparent;

  &:global {
    &:not(.disable-wrapper-hover) {
      border-color: var(--grayscaleColor-5);

      &:not(.disabled) {
        background-color: var(--grayscaleColor-1);

        &.with-color,
        &.with-background-color {
          &:hover {
            background-color: inherit !important;
          }
        }

        &:hover,
        &:has(*:focus) {
          border-color: var(--primaryColor-6);
        }
      }

      input,
      .ant-picker input {
        color: var(--neutralColor-6);
        font-weight: 500;

        &::placeholder {
          color: var(--grayscaleColor-7) !important;
          font-weight: 400;
        }
      }

      &.disabled {
        background: var(--grayscaleColor-3);
        color: var(--grayscaleColor-6);

        &.with-color,
        &.with-background-color {
          &:hover {
            background-color: var(--grayscaleColor-3) !important;
          }
        }
      }
    }

    &.with-error {
      border-color: var(--errorColor-5);

      &:not(.error-prepend) {
        .ant-select-selector {
          border-color: transparent !important;
        }

        &:hover {
          border-color: var(--primaryColor-6);

          .ant-select-selector {
            border-color: transparent !important;
          }
        }
      }
    }

    &.disabled {
      input,
      .ant-picker,
      .ant-select-selector {
        overflow: hidden;
        background-color: var(--grayscaleColor-3) !important;
      }

      .ant-select {
        &:hover {
          .ant-select-selector {
            &:hover {
              border-color: var(--grayscaleColor-5) !important;
            }
          }
        }
      }
    }

    &.error-prepend.error-prepend {
      position: relative;
      padding-left: 46px !important;

      &:before {
        content: '';
        position: absolute;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20Z' fill='%23F24848'/%3E%3Cpath d='M13 15H11V17H13V15Z' fill='%23F24848'/%3E%3Cpath d='M13 7H11V13H13V7Z' fill='%23F24848'/%3E%3C/svg%3E%0A")
          0 0 no-repeat;
        background-size: 100% 100%;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        z-index: 9;
      }
    }
  }

  :global {
    .ant-picker {
      border: none !important;
    }
  }
}

.wrapperSimple {
  position: relative;
  padding-top: 7px;
  padding-bottom: 6px;
}
