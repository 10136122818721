@import '../../../../variables.scss';

.wrapper {
  :global {
    .actions-container {
      border: none;
      flex-wrap: nowrap !important;
      padding: 0;

      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        display: grid !important;
      }

      .open-table-btn {
        padding: 0.75rem;
      }
    }

    .custom-view-title {
      overflow: hidden;
      flex-shrink: 1;
    }

    .ReactTable .rt-tbody .rt-td:first-child {
      text-overflow: initial;
    }
  }
}
