@import '../../../mixins.scss';

.stickyMenuLabel {
  color: var(--grayscaleColor-1);
  width: fit-content;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  background: var(--primaryColor-6);
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  cursor: pointer;
  height: auto;
  border-radius: 0 3rem 3rem 0;

  :global {
    .MobileSideMenu__sticky-menu-label-text {
      font-weight: 600;
      padding-left: 0.3rem;
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
}

.drawer {
  :global {
    .ant-drawer-wrapper-body,
    .ant-drawer-header {
      background-color: transparent;
    }

    .ant-drawer-body {
      @include custom-scroll-styling;
    }

    .ant-drawer-header {
      border: none;
    }
  }

  &__title {
    color: var(--neutralColor-6);
  }

  &__createIcon {
    color: var(--neutralColor-6) !important;
  }
}

.collapse {
  &:global {
    &.ant-collapse.ant-collapse {
      padding-right: 0.25rem;

      .ant-collapse-content-box {
        padding: 0 !important;
      }

      .ant-collapse-header {
        font-family: var(--fontFamily);
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--neutralColor-4);
        padding: 11px 0 11px 32px;
        background-color: transparent;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 16px;
          left: 16px;
          border-top: 1px solid var(--grayscaleColor-3);
          bottom: 0;
          display: block;
          opacity: 1;
          transition: opacity 0.3s;
        }

        .ant-collapse-arrow {
          position: absolute;
          margin: 0;
          color: var(--neutralColor-4);
          left: 20px;
          transition: all 0.3s;
        }
      }

      .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
        .ant-collapse-arrow {
          transform: rotate(90deg);
        }

        &:after {
          opacity: 0;
        }
      }
    }
  }
}

.mobileMenuItem {
  color: var(--neutralColor-6) !important;

  &:hover {
    border-radius: 40px !important;
    background-color: var(--primaryColor-2);
  }
}
