.wrapper {
  margin: 0.5rem;
  width: calc(100% - 1rem);

  :global {
    .error-message {
      margin-top: 0.5rem;
      text-align: center;
    }

    .loader-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .loader-inner {
      font-size: 10px;
      text-indent: -9999em;
      border: 4px solid rgba(var(--grayscaleColorRGB-1), 0.2);
      border-left: 4px solid var(--neutralColor-6);
      animation: file-field-loader-animation 1.1s infinite linear;

      &,
      &:after {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
      }
    }

    .drop-text {
      border: 2px dashed var(--grayscaleColor-6);
      font-size: 0.9rem;
      border-radius: 10px;
      text-align: center;
      width: 100%;
      position: relative;
      padding: 1rem 1.5rem;
    }

    .file-upload-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      cursor: pointer;
      z-index: 1;

      &.disabled {
        pointer-events: none;
      }
    }

    .drop-area {
      position: relative;
    }

    .drop-area.drag-over .drop-text {
      border-color: var(--primaryColor-6);
    }

    .drop-area.validation-error .drop-text {
      border-color: var(--warningColor-6);
    }

    .drop-area.disabled .drop-text {
      border-color: var(--grayscaleColor-5);

      span {
        color: var(--grayscaleColor-5);
      }
    }
  }
}
