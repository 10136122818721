@import '../../mixins.scss';

@mixin links($linkColor, $linkHoverColor) {
  .ant-menu-title-content {
    a,
    a:link,
    a:visited,
    &:not(:disabled) {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 0.85rem;
      line-height: 17px;
      color: $linkColor !important;
    }

    .outline-animation {
      @include outline-animation();
    }

    a > div {
      width: 20px;
      height: 20px;

      svg {
        max-width: 12px;
        max-height: 12px;
        flex-shrink: 0;
        margin-right: 0;
      }
    }
  }
}

.header {
  background-color: var(--FEATURES_ENTITIES__backgroundColor);
  padding-top: 2px;
  padding-bottom: 2px;
}

.menu {
  border-bottom: none;
  align-items: center;
  width: 100%;
  line-height: normal;
  background-color: var(--FEATURES_ENTITIES__backgroundColor);
  gap: 1px;
  height: 2rem;

  li {
    height: 2rem !important;
  }

  &:global {
    &.ant-menu.ant-menu .ant-menu-submenu {
      min-height: 0;
    }
  }

  :global {
    @include links(var(--FEATURES_ENTITIES__linkColor), var(--FEATURES_ENTITIES_HOVER__linkColor));
    .SideMenuIconOuter--item-icon {
      height: 24px !important;
    }
    .ant-menu-overflow-item.ant-menu-item,
    .ant-menu-overflow-item.ant-menu-submenu {
      min-height: 0 !important;

      &:hover {
        background-color: var(--primaryColor-2);
        border-radius: var(--borderRadius-lg) !important;
        color: inherit;
      }
    }

    .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal {
      padding: 0 !important;
      .ant-menu-submenu-title {
        .ant-menu-title-content {
          padding: 4px 12px 4px 6px;
        }
      }
    }
    .ant-menu-submenu.ant-menu-submenu-open:has(.ant-menu-title-content) {
      background-color: var(--primaryColor-3);
      border-radius: var(--borderRadius-lg) !important;
      color: inherit;
    }

    .ant-menu-overflow-item.ant-menu-overflow-item-rest {
      height: 32px !important;
      padding: 0 !important;
      margin-left: auto;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .ant-menu-item:after,
    .ant-menu-submenu:after {
      display: none;
    }

    .ant-menu-item.ant-menu-item.menu-label {
      font-weight: 600;
      font-size: 14px !important;
      line-height: 17px;
      color: var(--FEATURES_ENTITIES__titleColor);
      margin-right: 16px;
      white-space: nowrap;
      padding: 0 !important;
      cursor: default;
      border-right: none !important;

      &:hover {
        span {
          color: var(--FEATURES_ENTITIES__titleColor) !important;
        }
      }
    }

    .btn-dropdown-arrow {
      border-color: var(--FEATURES_ENTITIES_REST_ITEMS_BUTTON__borderColor);
      background-color: var(--FEATURES_ENTITIES_REST_ITEMS_BUTTON__backgroundColor);
      color: var(--FEATURES_ENTITIES_REST_ITEMS_BUTTON__color);

      &:hover {
        border-color: var(--FEATURES_ENTITIES_REST_ITEMS_BUTTON_HOVER__borderColor) !important;
        background-color: var(--FEATURES_ENTITIES_REST_ITEMS_BUTTON_HOVER__backgroundColor);
        color: var(--FEATURES_ENTITIES_REST_ITEMS_BUTTON_HOVER__color) !important;
      }
    }
  }
}
