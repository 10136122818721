.list {
  &_header {
    min-height: 40px;

    &__hidden {
      padding: 0.5rem 0.25rem;

      :local {
        .list_toggleButton {
          writing-mode: vertical-rl;
          text-orientation: mixed;
          height: auto;
        }

        .list_icon {
          transform: rotate(-90deg);
        }

        .list_title {
          max-width: 230px;
          min-height: 0;
        }
      }
    }
  }

  &_toggleButton {
    min-width: 32px;
    transition: transform 0s;
    color: var(--neutralColor-6);
  }

  &_icon {
    transition: color 0.3s ease;
  }

  &_title {
    font-weight: 600;
  }

  &_counter {
    --hoverBackgroundColor: var(--primaryColor-8);

    border-radius: var(--borderRadius-lg);
    min-width: 28px;
    min-height: 28px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    outline: none;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:global {
      &:not(.withTheme) {
        color: var(--primaryColor-8) !important;
        border: 1px solid var(--primaryColor-8);

        &:hover {
          color: var(--grayscaleColor-1) !important;
        }
      }
    }

    &:hover {
      background-color: var(--hoverBackgroundColor) !important;
    }
  }
}

.countLoader {
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
}
