.errorLabel {
  color: var(--warningColor-6);
  font-size: 0.9rem;

  &:global {
    &.relative-error {
      position: relative;
    }

    &.absolute-error {
      position: absolute;
      bottom: -23px;
    }
  }
}
