.wrapper {
  :global {
    .ant-btn-icon-only {
      vertical-align: middle;
    }
  }
}

.editRow {
  display: flex;
  align-items: center;
  background: var(--grayscaleColor-1);
  border: 1px solid var(--grayscaleColor-5);
  border-radius: var(--SECTION__borderRadius);
  height: 56px;
  padding: 0 12px;
  margin-bottom: 6px;

  @media (max-width: 1024px) {
    background: transparent;
    border: none;
    flex-wrap: wrap;
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }

  :global {
    .dashboard-selector {
      height: 40px;
      .selector {
        min-width: 200px;
        max-width: 500px;
        .ant-select-selector {
          font-weight: 600;
        }
      }

      @media (max-width: 1024px) {
        background: var(--grayscaleColor-1);
        border: 1px solid var(--grayscaleColor-5);
        border-radius: 8px;
        height: auto;
        padding: 0.75rem;
        margin-bottom: 1rem;
        margin-right: 0 !important;
      }
    }

    .DateRangeSelectorWrapper {
      @media (max-width: 686px) {
        width: 100%;
        margin-bottom: 1rem;
      }

      .custom-btn {
        svg {
          @media (max-width: 1024px) {
            margin-right: 0 !important;
          }
        }

        span {
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }

      .DateRangeSelectorPicker {
        @media (max-width: 755px) {
          width: 300px;
          margin-right: 0.5rem !important;
        }
        @media (max-width: 400px) {
          width: calc(100vw - 97px);
        }

        .ant-picker.ant-picker .ant-picker-input input {
          @media (max-width: 755px) {
            font-size: 12px;
          }
        }
      }
    }

    .InlineEditorOuter.inline-editor.title-inline-editor {
      margin-left: 0;
    }

    .ActionsButtonOuter {
      button.main,
      button.menu {
        height: 40px;
      }
    }

    .date-range .custom-btn,
    .refresh-btn,
    .save-btn,
    .delete-btn,
    .add-widget-btn {
      height: 40px;
      margin-right: 12px;

      @media (max-width: 755px) {
        margin-right: 0.5rem;
      }
      @media (max-width: 686px) {
        margin-right: 1rem;
      }
    }

    .add-widget-btn {
      margin-right: 0;
    }

    .refresh-btn {
      @media (max-width: 1024px) {
        margin-left: auto;
      }
      @media (max-width: 686px) {
        margin-left: 0;
      }
    }
  }
}
