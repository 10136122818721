.signOut {
  color: var(--HEADER_MENU_MOBILE__color);
  border: 1px solid var(--errorColor-5);
  height: 35px;
  width: 35px;
  svg {
    color: var(--errorColor-5);
  }
}

.profileContainer {
  padding-bottom: 1.5rem;
  :global {
    .email-work {
      font-size: 0.75rem;
      color: var(--neutralColor-4);
    }
  }
}

.profile {
  height: 2.7rem;
  width: 2.7rem;
  background-color: var(--HEADER_USER_AVATAR_MOBILE_backgroundColor);
  line-height: 3rem;
  font-size: 1.4rem;
  color: var(--HEADER_MOBILE__backgroundColor);

  &::after {
    content: '';
    position: absolute;
    top: -7px;
    right: -7px;
    bottom: -7px;
    left: -7px;
    border-radius: 50%;
    border: 3px solid var(--HEADER_USER_AVATAR_MOBILE_backgroundColor);
  }
}
