@import '../../../variables';
@import '../../../mixins.scss';

.actionsContainer {
  background: rgba(var(--grayscaleColorRGB-1), 0.5);
  border: 1px solid var(--grayscaleColor-5);
  border-radius: var(--borderRadius-lg);
  padding: 0.5rem 0.75rem;

  :global {
    .viewSelectorContainer {
      border-right: 1px solid var(--grayscaleColor-5);
      padding-right: 1rem;
      max-width: min(300px, 100%);

      .ant-select {
        outline: 0px solid var(--primaryColor-2);
      }

      .ant-select-open {
        transition: outline 0.5s;
        outline: 8px solid rgba(var(--primaryColorRGB-2), 0);
        border-radius: var(--borderRadius-l);
      }

      &:empty {
        display: none;
      }
      .ant-select .ant-select-selector .ant-select-selection-item {
        display: block;
        font-weight: 600;
        > svg {
          vertical-align: middle;
        }
      }
    }

    .ant-select-selection-search {
      .outline-animation {
        @include outline-animation();
      }
    }

    .editViewContainer.ActionsButtonOuter {
      border-right: 1px solid var(--grayscaleColor-5);
      padding-right: 0.5rem;
    }

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      .ant-select-show-search {
        padding-left: 0 !important;
      }
    }

    .settingsActionButtons {
      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        margin-left: 0 !important;
      }

      .DropdownButton button {
        background: transparent;
        border: none;
        border-radius: var(--borderRadius-circle);
        justify-content: center;

        &:hover,
        &:active {
          background-color: var(--primaryColor-2);
          color: inherit !important;
        }
      }
      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.75rem;
      }

      .SettingsButton {
        background: transparent;
        border: none;
        svg {
          width: 22px;
          height: 22px;
        }
        &:hover {
          background-color: var(--primaryColor-2);
          color: inherit !important;
        }
      }
    }

    .actionButton {
      height: 40px;
      border-radius: var(--borderRadius-lg) !important;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      svg {
        vertical-align: middle;
      }
    }

    .actionButton.actionButton.QuickAddItemButton {
      color: var(--grayscaleColor-1) !important;
    }

    .ActionDeleteButton {
      height: 40px;
      background: var(--errorColor-5);
      border-color: var(--errorColor-5);

      @media (max-width: 767px) {
        margin-bottom: 0 !important;
      }

      &:hover {
        background: var(--errorColor-4);
        border-color: var(--errorColor-4);
      }
    }

    .SettingsButton {
      width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--grayscaleColor-2);
      border: 1px solid var(--grayscaleColor-5);

      &:hover {
        background: var(--grayscaleColor-2);
        border-color: var(--primaryColor-6);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .FilterItemButton {
      aspect-ratio: 1;
      padding: 0.5rem;
      border: none;
      border-radius: var(--borderRadius-circle) !important;
      font-size: 1rem;
      color: var(--neutralColor-6) !important;

      @media (max-width: map-get($grid-breakpoints, 'md')) {
        margin-bottom: 0;
      }

      svg {
        fill: var(--neutralColor-6) !important;
      }

      &:hover {
        background: var(--primaryColor-2);
      }
    }

    .QuickAddItemButton {
      background: var(--primaryColor-6);

      &:hover {
        background: var(--primaryColor-5);
      }
    }

    .QuickAddItemButton {
      svg {
        width: 22px;
        height: 22px;
        margin-right: 3px;
        margin-left: -0.25rem;
      }
    }

    .ActionsButtonOuter {
      height: 40px;

      @media (max-width: 767px) {
        margin-bottom: 0;
      }

      &.groupingButton.hasValue {
        .menu {
          border-radius: 50px;
          flex-direction: row-reverse;
        }
      }

      .ViewActionsButtonEditViewButtonTextOuter {
        position: relative;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      & > span {
        display: block;
        height: 100%;

        &.withMenu {
          .main {
            border-radius: 20px 4px 4px 20px;
          }

          .menu {
            padding-left: 0.25rem;
            padding-right: 0.75rem;
            border-radius: 4px 20px 20px 4px;
          }
        }
        & > button {
          height: 100%;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: var(--neutralColor-6) !important;
          background: transparent;
          border: none;
          padding-right: 0.5rem;
          box-shadow: none;

          svg {
            fill: var(--neutralColor-6) !important;
            transition: all 0.3s;
          }

          &:hover {
            background: var(--primaryColor-2);
          }
        }
      }
    }
  }
}
