@import '../../../mixins';

.outer {
  :global {
    button.main:focus + .menu.menu,
    button.main:active + .menu.menu,
    button.main:hover + .menu.menu {
      border-left-color: var(--primaryColor-4);
    }

    .menu {
      min-height: 0;
      min-width: 0;
    }
  }

  &:global {
    &.saveButton {
      button.main {
        &:not(:disabled) {
          color: var(--grayscaleColor-1) !important;
          background: var(--primaryColor-6) !important;

          &:hover {
            background: var(--primaryColor-5) !important;
          }
        }
      }
    }
  }
}

.dropdownOverlay {
  min-width: 170px !important;
  @include glass-background-effect;

  :global {
    .ant-dropdown-menu {
      padding-top: 10px;
      padding-bottom: 10px;
      box-shadow: none;
      background: transparent;
    }

    .info-icon {
      margin-left: 0.3rem;
      color: var(--grayscaleColor-7);
    }

    .dropdown-item {
      .ant-dropdown-menu-title-content {
        font-weight: 500;
        font-size: 0.85rem;
      }
      &:hover {
        background-color: var(--primaryColor-2) !important;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: all;
        cursor: not-allowed;
      }

      &.danger {
        color: var(--errorColor-5) !important;

        &:hover {
          background-color: var(--errorColor-5) !important;
          color: var(--grayscaleColor-1) !important;

          .info-icon {
            color: var(--grayscaleColor-1) !important;
          }
        }
      }
    }
  }
}
