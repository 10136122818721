.editor {
  &:global {
    & > div {
      padding-bottom: 0 !important;
      width: 100%;
    }
  }

  :global {
    textarea {
      overflow: auto;
      border: none;
      background: transparent;
      border-radius: var(--borderRadius-l);
      font-weight: 500;
      font-size: 12px;
      padding: 9px 0 8px;
      color: var(--neutralColor-6);
      resize: none;
    }
  }
}
