.wrapper {
  :global {
    .right-buttons button,
    .right-buttons a {
      padding: 0.75rem;
    }

    .widget-body {
      display: flex;
      flex-direction: column;

      .TaskDashboardPageFilters {
        padding-bottom: 0.5rem;

        &.row {
          --bs-gutter-x: 4px;
        }
      }
    }

    .widget-tasks {
      overflow: auto;
    }
  }
}
