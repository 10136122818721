.preview {
  padding: 5rem 0;

  :global {
    .desktop-preview {
      background: var(--grayscaleColor-1);
      border: 2px solid var(--grayscaleColor-4);
      font-size: 0;
      position: relative;
      border-radius: 10px 10px 3px 3px;
      height: 100%;

      &__header {
        padding: 15px 15px 12px;
        border-bottom: 1px solid var(--grayscaleColor-4);
        font-size: 14px;
      }
    }

    .user-icon {
      color: var(--grayscaleColor-4);
      font-size: 40px;
    }

    .placeholders {
      display: inline-flex;
    }

    .placeholder {
      height: 7px;
      background: var(--grayscaleColor-4);
      margin-bottom: 6px;
      margin-right: 6px;
      margin-top: 4px;

      &__first {
        width: 40px;
      }

      &__second {
        width: 60px;
      }
    }

    .preview-frame {
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
      border-left-width: 0;
      width: 100%;

      &__desktop {
        height: calc(100% - 68px);
      }

      &__mobile {
        height: 459px;
      }
    }

    .mobile-preview {
      position: relative;
      height: 698px;
      width: 344px;
      border-radius: 40px;
      padding: 50px 10px 60px;
      background: var(--grayscaleColor-1);
      border: 2px solid var(--grayscaleColor-4);

      &:before {
        position: absolute;
        top: 22px;
        left: 50%;
        margin-left: -30px;
        width: 60px;
        background: var(--grayscaleColor-4);
        height: 8px;
        border-radius: 20px;
        content: ' ';
        display: block;
      }

      &:after {
        position: absolute;
        bottom: 13px;
        left: 50%;
        margin-left: -24px;
        width: 50px;
        height: 50px;
        border-radius: 30px;
        border: 2px solid var(--grayscaleColor-4);
        content: ' ';
        display: block;
      }

      &__footer-img {
        margin-top: -7px;
      }

      &__screen {
        box-shadow: 0 0 0 1px var(--grayscaleColor-4);
        border-radius: 2px;
        overflow: hidden;
        height: 568px;
      }
    }
  }
}
