.wrapper {
  border: 1px solid var(--grayscaleColor-5);

  &:global {
    & > .react-resizable-handle {
      cursor: row-resize;
      user-select: none;
    }
  }
}
