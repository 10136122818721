.globeIcon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.selectContainer {
  :global {
    .select-icon {
      right: 0;
      z-index: -1;
    }
  }
}

.select {
  width: 80px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: none;
  font-size: 0.875rem;
  color: var(--HEADER_MENU_MOBILE__color);

  &:focus {
    outline: none;
  }
}
