.wrapper {
  width: 32px;
  height: 32px;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  border-radius: var(--borderRadius-circle);
  color: var(--FEATURES_ENTITIES__linkColor);
  &:hover {
    background-color: var(--primaryColor-2);
    color: var(--FEATURES_ENTITIES__linkColor) !important;
  }
}

:global {
  .ant-menu-overflow-item-rest.ant-menu-submenu-open:has(.btn-dropdown-double-arrow)
    .btn-dropdown-double-arrow {
    background-color: var(--primaryColor-3);
  }
}
