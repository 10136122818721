.editor {
  &:global {
    & {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &.disabled {
      .ql-editor {
        color: var(--grayscaleColor-7);
      }

      .ql-toolbar,
      .ql-container {
        background: var(--grayscaleColor-3);
      }

      .ql-toolbar {
        button,
        button.ql-active,
        span,
        span.ql-active {
          cursor: default;
          pointer-events: none;

          .ql-fill {
            fill: var(--grayscaleColor-6);
          }

          .ql-stroke {
            stroke: var(--grayscaleColor-6);
          }
        }
      }
    }
  }

  :global {
    .quill {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .ql-toolbar,
    .ql-container {
      border: 0 !important;
    }

    .ql-container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .ql-snow {
      & .ql-editor {
        & pre.ql-syntax {
          background-color: var(--grayscaleColor-4);
          color: var(--neutralColor-4);
        }
      }
    }

    .ql-editor {
      min-height: 5rem;
      flex: 1;

      &.ql-blank:before {
        font-family: var(--fontFamily);
        color: var(--grayscaleColor-7);
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
      }
    }

    .ql-tooltip {
      max-width: 100%;

      &.ql-editing {
        input[type='text'] {
          width: auto;
        }
      }
    }

    .image-uploading:before {
      border-top-color: var(--primaryColor-4);
    }
  }
}
