.wrapper {
  :global {
    svg.warning {
      display: none !important;
    }
    path {
      stroke: var(--grayscaleColor-6) !important;
    }
    .node {
      rect,
      circle,
      ellipse,
      polygon {
        stroke: var(--grayscaleColor-6) !important;
      }

      tspan {
        fill: var(--grayscaleColor-8);
      }

      &.selected {
        rect,
        circle,
        ellipse,
        polygon {
          fill: var(--successColor-2) !important;
          stroke: var(--successColor-7) !important;
          stroke-width: 1.5px !important  ;
        }
        g div {
          color: var(--successColor-7) !important;
        }
        g tspan {
          fill: var(--successColor-7) !important;
        }
      }
      &.available {
        rect,
        circle,
        ellipse {
          stroke: var(--infoColor-5) !important;
        }
        g div {
          color: var(--infoColor-5) !important;
        }
        g tspan {
          fill: var(--infoColor-5) !important;
        }
      }
      &.invalid {
        rect,
        circle,
        ellipse,
        polygon {
          fill: var(--errorColor-1) !important;
          stroke: var(--errorColor-5) !important;
        }
        g div {
          color: var(--errorColor-5) !important;
        }
        g tspan {
          fill: var(--errorColor-5) !important;
        }
      }
    }
  }
}
