.addButtonContent {
  color: var(--neutralColor-6);
}

.addButtonContentOverlay {
  :global {
    .ant-select-dropdown {
      position: relative;
      top: 0 !important;
      bottom: 0 !important;
      border: none !important;
      border-radius: 0 !important;
      margin-top: 8px;
    }
  }
}
