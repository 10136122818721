.wrapper {
  :global {
    .edit-link {
      transition: color 200ms ease;

      &:hover {
        color: var(--primaryColor-5);
      }
    }

    .send-new-code {
      color: var(--infoColor-5);
      cursor: pointer;

      &.disabled {
        color: var(--grayscaleColor-7);
        cursor: default;
      }
    }
  }
}
