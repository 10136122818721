.container {
  padding: 0 0.5rem;
  width: 100%;

  :global {
    .section-card {
      margin-top: 1rem;
    }
    .action-button.danger {
      border-radius: var(--borderRadius-lg);
    }
  }
}

.buttonText {
  max-width: 100px !important;
}
