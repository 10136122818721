.wrapper {
  :global {
    .delete-btn {
      background: var(--errorColor-5);
      border-color: var(--errorColor-5);
    }

    .webhooks-table {
      th {
        white-space: nowrap;
      }
      td > button {
        margin-right: 0.3rem;
        margin-left: 0.3rem;
      }
      td > div {
        padding-bottom: 0 !important;
      }
    }
  }
}
