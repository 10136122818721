.cronField {
  display: block !important;

  :global {
    .react-js-cron-field {
      display: block;
    }

    div.react-js-cron-select {
      margin-left: 0;
      display: block !important;
    }
    .react-js-cron-clear-button {
      margin-left: 0;
    }
  }
}
