@import '../../../../mixins.scss';
.wrapper {
  :global {
    .titleWrapper {
      cursor: initial;
      @include flex-truncate;
      .ant-dropdown-trigger {
        cursor: pointer;
        &:hover {
          color: var(--primaryColor-6);
        }
      }

      .title {
        word-break: break-word;
        min-width: 0;
      }
    }
    .shortDescription,
    .related-to {
      font-size: 12px;
      max-height: 8.3rem;
      min-width: 100px;
    }

    .dueDate,
    .completedAt {
      font-size: 12px;
      max-width: 100px;
      min-width: 88px;
      text-align: right;

      &.error {
        color: var(--errorColor-5);
        font-weight: 700;
      }
    }

    .btn-menu {
      color: var(--neutralColor-6);
    }

    .files-icon {
      color: var(--primaryColor-6);
    }

    .priority-4-NORMAL {
      color: var(--successColor-5);
    }
    .priority-6-HIGH {
      color: var(--warningColor-5);
    }
    .priority-8-URGENT {
      color: var(--errorColor-5);
    }
  }
}

.iconEditPencil {
  height: 20px !important;
  width: 20px !important;
  margin-right: 12px !important;
}
