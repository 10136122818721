@import '../variables';

.ErrorMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  font-weight: 600;
  color: var(--grayscaleColor-4);

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 6rem;
  }
}

.LoadingIndicatorWrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.LoadingIndicator {
  margin-top: 14rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
