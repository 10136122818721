@import '../../../../variables';
@import '../../../../mixins';

.listItem {
  border-left: 1px solid rgba(var(--grayscaleColorRGB-13), 0.15);
  border-right: 1px solid rgba(var(--grayscaleColorRGB-13), 0.15);
  background: var(--grayscaleColor-1);

  &:global {
    &.is-section-item,
    &.is-tab-item {
      border-top: 1px solid rgba(var(--grayscaleColorRGB-13), 0.15);
      margin-top: 1rem;

      .ant-collapse.ant-collapse {
        .ant-collapse-header {
          background-color: var(--grayscaleColor-4);
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  :global {
    .ant-collapse.ant-collapse {
      padding-right: 0 !important;
      position: relative;

      .ant-collapse-arrow.ant-collapse-arrow {
        line-height: 41px !important;
        border-right: 1px solid var(--grayscaleColor-5);
        padding-right: 1rem;
        display: block;
        left: 15px;

        @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
          display: none;
        }
      }

      .ant-collapse-item {
        &.ant-collapse-item-active {
          .ant-collapse-content {
            border-top: 1px solid var(--grayscaleColor-5);
          }
        }

        .ant-collapse-header {
          overflow: hidden;

          .ant-collapse-header-text {
            flex: 1;
            min-width: 0;
          }
        }
      }

      .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }
    }

    .BasicInputOuter input {
      border-radius: var(--borderRadius-l);
    }
  }
}

.headerTitle {
  min-height: 1.5rem;

  &:global {
    &.is-section,
    &.is-tab {
      font-weight: 500;
    }

    & > .alertIcon {
      fill: var(--warningColor-6);
    }
  }

  :global {
    .item-type {
      color: var(--grayscaleColor-7);
    }
    .title-outer {
      overflow: hidden;

      .required-info {
        font-size: 0.8rem;
        font-style: italic;
        font-weight: 300;
        color: var(--neutralColor-4);
      }
    }

    .title-container {
      &.has-icon {
        margin-left: 1rem;
      }

      &.is-hidden-property {
        opacity: 0.5;
      }
    }
    .subtitle {
      font-size: 0.8rem;
      font-style: italic;
      font-weight: 300;
      color: var(--neutralColor-4);
    }

    .buttons-outer {
      color: var(--neutralColor-6);
      > .disabled {
        opacity: 0.3;
        cursor: default;
      }

      > svg {
        fill: var(--neutralColor-6);
      }
    }
  }
}

.popover {
  @include dark-popover;
}
