.wrapper {
  :global {
    .group {
      border-color: var(--neutralColor-4) !important;
    }

    .delete-btn {
      min-height: 40px;
      min-width: 40px;
      padding: 0;
      &:disabled {
        svg {
          color: var(--grayscaleColor-4) !important;
        }
      }
      &:hover {
        color: red !important;
      }
    }
    .add-condition-group-btn {
      height: 40px;
      border-color: var(--grayscaleColor-5);
      color: var(--neutralColor-6);

      &:hover:not([disabled]) {
        color: var(--primaryColor-6) !important;
        border-color: var(--primaryColor-6) !important;
      }

      &:active,
      &:focus {
        color: var(--primaryColor-7) !important;
        border-color: var(--primaryColor-7) !important;
      }
      &:disabled {
        color: var(--neutralColor-4);
      }
    }

    .entity-list-notify {
      color: var(--warningColor-6);
    }
  }
}

.errorLabel {
  color: var(--warningColor-6);
  white-space: pre;
}
