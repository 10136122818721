.featured {
  :global {
    .selected {
      color: var(--warningColor-6);
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      opacity: 0;
    }
  }
}
