.container {
  font-size: 0.9rem;

  :global {
    .existing-messages {
      padding: 20px 20px 0;
      background-color: var(--INFO_PANEL_COMMENTS__backgroundColor);
    }

    .reply-icon {
      height: 48px;
      margin: 0.5rem 0 0;
      color: var(--grayscaleColor-7);
    }

    .action-link {
      font-size: 0.8rem;
      font-weight: 500;
      margin-right: 0.5rem;
      padding: 0;
      border: 0;
      outline: none;
      background-color: transparent;
      color: var(--infoColor-5) !important;

      &:hover,
      &:focus {
        color: var(--infoColor-4) !important;
        background-color: transparent;
      }

      &:active {
        color: var(--infoColor-6) !important;
        background-color: transparent;
      }

      &.delete-button {
        color: var(--errorColor-5) !important;

        &:hover,
        &:focus {
          color: var(--errorColor-4) !important;
        }

        &:active {
          color: var(--errorColor-6) !important;
        }
      }
    }

    .actions-outer {
      margin: 0 0 1rem calc(24px + 0.5rem);
    }

    .conversation-loader {
      border-color: var(--neutralColor-6);
      border-left-color: transparent;
      display: inline-block;
      margin-top: 3px;
      position: absolute;

      &,
      &:after {
        width: 15px;
        height: 15px;
      }
    }

    .message-container {
      margin: 0 0 1rem 1rem;

      &.replying {
        margin-left: 0;
      }
    }
  }
}
