.formikField {
  width: 100%;
}

.numberFormatRadio {
  display: block;
  height: 2rem;
  line-height: 2rem;
}

.labelText {
  margin-top: 1rem;
}
