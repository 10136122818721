.collapse {
  &:global {
    &.ant-collapse.ant-collapse {
      padding-right: 0;
      border-bottom: 0;

      .ant-collapse-item {
        padding-top: 2rem;
      }

      .ant-collapse-header {
        padding-left: 0;
        align-items: center;

        i {
          left: 0;
          line-height: 0;
        }
      }

      .ant-collapse-content {
        overflow: visible;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }
}

.tabWrapper {
  box-sizing: border-box;
  border-radius: var(--SECTION__borderRadius);
  overflow: hidden;

  :global {
    .section {
      border: 1px solid var(--grayscaleColor-5);
      border-top: none;
      background: var(--grayscaleColor-1);
      border-radius: 0 0 8px 8px;

      .ant-collapse > .ant-collapse-item.ant-collapse-item.ant-collapse-item {
        background-color: transparent;
        border-radius: 0;
        border: none;
        margin: 0;

        .ant-collapse-content {
          border: none;
        }
      }
    }

    .ant-tabs-tabpane {
      border-top: 1px solid var(--grayscaleColor-5);
      margin-top: -1px;
    }
  }
}
