.wrapper {
  :global {
    .toggle-btn {
      margin-bottom: 0.3rem;
      display: block;
      padding: 0;
      text-decoration: none;
      font-size: 0.9rem;
      border: 0;
      outline: none;
      color: var(--primaryColor-6);
      transition: color 0.3s ease;

      &:hover {
        color: var(--primaryColor-5);
      }
    }

    .conditional-templates,
    .form-settings {
      background: var(--grayscaleColor-4);
      padding: 0.5rem;
    }

    .infoIcon {
      margin-left: 0.3rem;
      color: var(--grayscaleColor-7);
    }
  }
}
