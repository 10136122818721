.wrapper {
  height: 300px;
  position: relative;

  :global {
    .rectangle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
