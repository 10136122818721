@import '../../../../../variables.scss';

.wrapper {
  border: 1px solid var(--grayscaleColor-5);
  background-color: var(--grayscaleColor-2);
  border-radius: 0 var(--borderRadius-l) var(--borderRadius-l) var(--borderRadius-l);

  &:global {
    @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
      border-radius: var(--borderRadius-l);
    }
  }

  :global {
    .TriangleOuter {
      @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        display: none;
      }
    }
    .triangle {
      left: 30px;
    }
  }
}
