.wrapper {
  :global {
    .actions {
      width: 12rem !important;
    }

    .quick-add-item-link {
      height: 40px;
      background-color: var(--primaryColor-6);
      color: var(--grayscaleColor-1) !important;
      border-radius: 50px;

      &:hover {
        background-color: var(--primaryColor-5);
      }

      svg {
        width: 30px;
        height: 25px;
        margin-right: 3px;
        margin-left: -0.25rem;
      }
    }
  }
}
