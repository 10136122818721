.noDeadline {
  color: var(--neutralColor-5);
  font-size: 0.9rem;
  border: 1px solid var(--grayscaleColor-3);
  transition: border-color 0.3s ease;
  border-radius: var(--borderRadius-l);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    border-color: var(--primaryColor-6);
  }
}

.deadlineText {
  color: var(--errorColor-5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--grayscaleColor-3);

  transition: border-color 0.3s ease;
  border-radius: var(--borderRadius-l);

  &:hover {
    border-color: var(--primaryColor-5);
  }
}

.deadlineIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 8px;
  background-color: var(--errorColor-5);
  border-radius: var(--borderRadius-l) 0 0 var(--borderRadius-l);
  color: var(--grayscaleColor-1);
  font-size: 16px;
  margin-left: -1px;
}
