.title {
  font-size: 1.5rem;
}

.subtitle {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.submitButton {
  height: 40px;
}
