.notificationsDrawer {
  .ant-drawer-wrapper-body {
    overflow: initial !important;
  }

  :global {
    .title {
      font-weight: 700 !important;
    }

    .unreadCount {
      font-size: 0.8rem;
      color: var(--grayscaleColor-7);
      font-weight: 500 !important;
    }
  }
}

.iconContainer {
  cursor: pointer;
  display: flex;

  :global {
    .headerNotificationIcon {
      color: var(--HEADER_MENU__color);
      transition: color 0.3s ease;
    }
  }
}

.drawerContent {
  :global {
    .noNotificationsText {
      padding: 1.5rem;
    }

    .notificationItem {
      position: relative;
      border-bottom-width: 1px !important;
      border-bottom-style: solid;
      border-bottom-color: var(--grayscaleColor-4);

      :global {
        svg,
        .iconPlaceholder {
          transition: color 200ms ease;
          width: 20px;
          height: 20px;
        }

        .link {
          padding: 0.75rem 0;
          font-size: 0.9rem;
          transition: none !important;
          color: var(--neutralColor-6) !important;
          word-break: break-word;

          .author {
            font-size: 12px;
            line-height: 1.4;
            font-weight: 600;
            margin-right: 0.5rem;
          }
          .dateTime,
          .type {
            font-size: 12px;
            color: var(--grayscaleColor-6);
            white-space: nowrap;
          }

          .type {
            margin-left: auto;
          }

          .message {
            font-style: italic;
            width: 100%;
          }
        }

        .actionContainer {
          .pe-auto {
            cursor: pointer;
          }
          .width20px {
            width: 20px;
          }

          .delete-button {
            :global {
              svg {
                fill: var(--grayscaleColor-7);
                &:hover {
                  fill: var(--errorColor-6);
                  cursor: pointer;
                }
              }
              div {
                border-color: var(--grayscaleColor-7);
                border-left-color: transparent;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
            &.disabled {
              pointer-events: none;
              :global {
                svg {
                  fill: var(--grayscaleColor-4);
                  &:hover {
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }

      .desktop {
        &:hover {
          :global {
            svg {
              color: var(--primaryColor-5);
            }
          }
        }

        .actionContainer {
          padding-right: 1rem;
        }
      }

      .mobile {
        :global {
          .titleContainer {
            margin-right: 3rem;
          }

          .actionContainer {
            position: absolute;
            right: 0;
            top: 0.5rem;
            padding: 1rem;
          }

          div.message {
            padding: 0 1rem 1rem;
          }
        }
      }
    }
  }
}
