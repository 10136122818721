.outer {
  :global {
    .quill {
      width: 100%;
    }

    .ql-container.ql-container {
      border: 0;

      .ql-editor.ql-editor {
        padding: 6px 6px 6px 0;

        pre {
          color: var(--neutralColor-6);
        }
      }
    }
  }

  &:global {
    &.kanbanField {
      pointer-events: none;
    }
  }
}
