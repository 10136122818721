.inputContainer {
  position: relative;

  &:hover {
    :local(.clearSelectorButton) {
      opacity: 1;
      visibility: visible;
    }
  }

  :global {
    .ant-select-selection-placeholder {
      display: block !important;
    }
    .ant-select {
      &:not(.ant-select-multiple) .ant-select-selection-item {
        display: block !important;
      }
    }
  }
  &:global {
    &.disabledTextOverflow {
      .ant-select-selection-item {
        text-overflow: unset;
      }
    }

    &:not(.withMaxTagCount) {
      .ant-select-selection-overflow {
        max-height: 300px;
        overflow: auto;
      }
    }
  }
}

.dropdownHeader {
  color: var(--grayscaleColor-6);
  font-size: 0.7rem;
  padding: 0.3rem 12px;
  border-bottom: 1px solid var(--grayscaleColor-4);
  user-select: none;
}

.withShowFilterButton {
  :global {
    .ant-select-selector {
      padding-right: 70px !important;
    }

    .ant-select-clear {
      right: 45px !important;
    }
  }
}

.showFilterButton {
  padding: 0;
  outline: none;
  border: 0;
  border-radius: 0 var(--borderRadius-l) var(--borderRadius-l) 0;
  background-color: var(--primaryColor-6);
  transition: background-color 0.3s ease;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 40px;
  height: 40px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: var(--primaryColor-5);
  }

  &.inverseBorder {
    border-radius: 0 8px 0 8px;
  }

  :global {
    .ant-badge {
      font-size: 16px;
      transition: color 0.3s ease;
      color: var(--grayscaleColor-1);
    }
  }
}

.clearSelectorButton {
  opacity: 0;
  visibility: hidden;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grayscaleColor-1);
  border: 1px solid var(--grayscaleColor-5);
  border-radius: 4px;
  color: var(--errorColor-5);
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease,
    visibility 0.3s ease;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  left: 0;

  &:hover {
    border-color: var(--errorColor-5);
    background-color: var(--errorColor-5);
    color: var(--grayscaleColor-1);
  }

  &__active,
  &:focus {
    opacity: 1;
    visibility: visible;
  }

  &__topButton {
    bottom: calc(100% + 5px);
  }

  &__bottomButton {
    top: calc(100% + 5px);
    z-index: 3;
  }
}
