@import '../../../../../../../variables';

.stepsOuter {
  :global {
    .ant-steps-item-tail {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      &:after {
        background-color: var(--infoColor-4) !important;
      }
    }

    .ant-steps-item:last-child .ant-steps-item-description {
      padding-bottom: 0;
    }

    .ant-steps-item-title {
      line-height: 1 !important;
      padding-top: 8px;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .ant-steps-item-description {
      line-height: 1.6 !important;
      font-size: 14px;
      color: var(--neutralColor-6) !important;
      padding-bottom: 0 !important;
    }

    .info-icon {
      margin-left: 0.3rem;
      color: var(--infoColor-6);
      font-size: 1.1rem;
      opacity: 0.6;
    }

    .icon-btn {
      height: 33px;
      padding: 0 0 0 5px;
      font-size: 24px;
      border: none;
    }

    .completed-block {
      color: var(--successColor-5);
    }

    .cancelled-tasks {
      color: rgba(var(--grayscaleColorRGB-13), 0.45);
    }

    .CustomCollapsePanel {
      .ant-collapse-header {
        font-size: 13px;
        padding: 0;
        align-items: center;

        .ant-collapse-header-text {
          display: contents;
          .ant-collapse-expand-icon {
            margin: 0;
          }
        }
      }
      .ant-collapse-content-box {
        padding-top: 0 !important;
        padding-bottom: 24px;
      }
      &:last-child {
        .ant-collapse-content-box {
          padding-bottom: 0;
        }
      }
    }
    .ant-steps-item-content {
      padding-bottom: 16px;
    }
    .SmallText {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
    }

    .task-item {
      display: flex;
      align-items: center;
      max-width: 100%;

      &::before {
        content: '';
        margin-right: 0.3rem;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--grayscaleColor-5);
      }

      svg.info-icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
      }
    }

    .task-nums-success {
      font-weight: 600;
      color: var(--successColor-5);
      margin: 0 4px 0 9px;
    }

    .task-nums-warning {
      font-weight: 600;
      color: var(--warningColor-5);
      margin-right: 4px;
    }

    .task-nums-fail {
      font-weight: 600;
      color: var(--errorColor-4);
    }

    .task-expand-mail-button {
      outline: none !important;
      width: 12px;
      height: 12px;
    }

    .task-expand-mail.task-expand-mail {
      color: var(--infoColor-4);
    }

    .inline-loading-bar {
      display: inline-block;
      vertical-align: sub;
    }

    .step-icon {
      font-size: 20px;
      color: var(--grayscaleColor-1);
    }

    .deadline-description {
      min-height: 36px;
      display: flex;
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }
}

.stepIconWrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primaryColor-6);
  display: flex;
  justify-content: center;
  align-items: center;

  &__completed {
    background-color: var(--grayscaleColor-1);
  }
}

.stepIcon {
  font-size: 20px !important;
  color: var(--grayscaleColor-1) !important;

  &__completed {
    font-size: 24px !important;
    color: var(--successColor-5) !important;
  }
}

.syncIcon {
  stroke: var(--grayscaleColor-1);
  transform: rotate(90deg);
}
