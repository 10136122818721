.ReactTableWrapper {
  //border: 1px solid var(--grayscaleColor-5);
  //border-radius: 1rem;
}

.ReactTable {
  position: relative;
  min-width: 100%;
  width: max-content;
  box-sizing: border-box;
}

.ReactTable .rt-tbody-loading {
  display: inline-block;
  min-width: min(100%, 1200px);
  position: relative;
}

.ReactTable .rt-thead.-header {
  z-index: 3;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-th:focus {
  outline-width: 0;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-tr {
  display: flex;
  min-height: calc(2rem + 1em);

  .ant-select.ant-select {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 30px;
    }
  }

  .rt-td,
  .rt-th {
    border-bottom: 1px solid var(--grayscaleColor-5);

    &:has(> .group) {
      border-bottom: 0;
    }
  }

  &:last-child {
    .rt-td {
      border-bottom: 0;
    }
  }
}

.ReactTable .rt-td,
.ReactTable .rt-th {
  flex: 100 0 auto;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(var(--grayscaleColorRGB-13), 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  padding: 7px 5px;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-tfoot {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(var(--grayscaleColorRGB-13), 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(var(--grayscaleColorRGB-13), 0.03);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(var(--grayscaleColorRGB-13), 0.05);
}

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(var(--grayscaleColorRGB-13), 0.1);
  border-top: 2px solid rgba(var(--grayscaleColorRGB-13), 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(var(--grayscaleColorRGB-13), 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}

.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(var(--grayscaleColorRGB-13), 0.6);
  background: rgba(var(--grayscaleColorRGB-13), 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(var(--grayscaleColorRGB-13), 0.3);
  color: #fff;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(var(--grayscaleColorRGB-1), 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(var(--grayscaleColorRGB-13), 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(var(--grayscaleColorRGB-1), 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(var(--grayscaleColorRGB-13), 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}

/**
  * initReactTableStyles
  */
.ReactTable {
  .resizer {
    height: 100%;
    min-width: 7px;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--grayscaleColor-5);
      opacity: 0;
    }
  }

  .resizer.isResizing {
    &:after {
      opacity: 1;
    }
  }

  @media (hover: hover) {
    .rt-tr:hover .resizer {
      &:after {
        opacity: 1;
      }
    }
  }

  .rt-tbody .rt-tr:hover,
  .rt-tbody .rt-tr:hover > td {
    background-color: var(--primaryColor-1) !important;
  }

  .rt-th,
  .rt-td {
    flex-grow: 1;
  }

  .rt-th:focus {
    outline: none;
  }

  .rt-table {
    box-shadow: 0 1px 3px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
    border-radius: 0.25rem;
    overflow: visible;
  }

  .rt-thead {
    &.-header {
      background-color: var(--grayscaleColor-1);
    }

    & th:has(.isResizing) {
      .sort-button,
      .ActionsButton {
        position: static !important;
        opacity: 1 !important;
        right: 0;
      }
    }

    .rt-th {
      padding: 0;

      & .sort-button,
      & .ActionsButton {
        opacity: 0;
        position: absolute;
        transition: background-color 0.3s, opacity 0s;
      }

      & .ActionsButton {
        right: 7px; // need for fix jump quick filter, need have hidden element in the same place when visible
        width: 0;
      }

      &:hover,
      &:has(.-sort-asc),
      &:has(.-sort-desc) {
        & .sort-button {
          position: relative;
          opacity: 1;
        }
      }

      &:has(.ActionsButton .ant-dropdown-open),
      &:has(.quick-filter.ant-popover-open),
      &:hover {
        & .ActionsButton {
          position: relative;
          opacity: 1;
          right: 0;
          width: auto;
        }
      }

      .wrapper {
        font-size: 0.85rem;
        color: var(--neutralColor-6);
        padding: 0.8rem 0 0.8rem 0.5rem;
        vertical-align: middle;
        font-weight: 600;
        text-align: left;
        height: 48px;

        &.select {
          padding: 0;
        }

        .-sort-asc {
          transform: rotate(0);
        }

        .-sort-desc {
          transform: rotate(90deg);
        }
      }
    }
  }

  &.selectable .rt-thead .rt-th.rt-th:first-child {
    padding: 0.7rem;
    text-align: center;
  }

  .rt-tbody {
    background-color: var(--grayscaleColor-1);

    .rt-tr-group {
      min-height: calc(2rem + 1em);
    }

    .rt-td {
      border-right: 0 !important;
      padding: 0 !important;
      vertical-align: middle !important;
      word-break: break-all !important;
      line-height: 1;

      & > div.inline-display-mode {
        padding: 0.5rem !important;
      }

      & > a {
        padding: 0;
      }

      & > div.inline-display-mode {
        transition: background-color 0.3s, color 0.3s;

        &.with-background-color,
        &.with-color {
          .open-item-info-drawer-link {
            color: inherit;
            line-height: 1;
          }

          &:hover {
            background-color: inherit !important;
            color: var(--neutralColor-4) !important;

            .open-item-info-drawer-link {
              color: var(--infoColor-5);
            }
          }
        }

        .edit-actions.isTouchDevice {
          display: none;
        }
      }

      & > div.inline-editor-mode {
        position: relative;
        max-width: 330px;
        margin: 8px 0;
        padding: 0 !important;
        background-color: var(--grayscaleColor-1) !important;
        z-index: 3;
        height: fit-content !important;
        border-radius: var(--borderRadius-l);

        .FieldWrapperOuter,
        .ant-select .ant-select-selector.ant-select-selector {
          min-height: 32px;
          padding-top: 0;
          padding-bottom: 0;
        }

        .editor-actions-block {
          bottom: 3px;
        }

        .SelectWrapper {
          & > button {
            width: 33px;
            height: 33px;

            .ant-badge {
              font-size: 14px;
            }

            .ant-badge-count {
              min-width: 16px;
              height: 16px;
            }
          }
        }
      }

      position: relative;

      &:has(.inline-editor-mode) {
        overflow: visible;
      }

      &:has(.inline-editor-mode) {
        overflow: visible;
      }
    }
  }

  .rt-noData {
    background-color: transparent !important;
  }
}

.data-table thead {
  th {
    border-right: 1px solid rgba(var(--grayscaleColorRGB-13), 0.05);

    &:last-child {
      border-right: 0;
    }
  }
}
