.container {
  padding-top: 0.25rem;
  user-select: none;
  background: transparent;
  border-radius: var(--SECTION__borderRadius);
  overflow: hidden;
  width: 340px;

  :global {
    .DesktopSideMenu {
      &__title-container {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        padding-left: 1rem;
        color: var(--neutralColor-6);
      }

      &__collapse {
        &.ant-collapse.ant-collapse {
          padding-right: 2px;
          border: 0;

          .ant-collapse-content-box {
            padding: 0 !important;
          }

          .ant-collapse-header {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 18px;
            padding: 0.5rem 0 0.5rem 1rem;
            color: var(--grayscaleColor-8);
            background: transparent;
            position: relative;
            align-items: center;
            border-top-right-radius: var(--borderRadius-lg);
            border-bottom-right-radius: var(--borderRadius-lg);
            transition: none;

            .ant-collapse-arrow {
              color: var(--grayscaleColor-8);
              font-size: 1rem;
              margin: 0;
            }
            &:hover {
              background-color: var(--primaryColor-2);
              color: var(--SIDE_MENU_ITEM_SUBTITLE__color);
              .ant-collapse-arrow {
                color: var(--SIDE_MENU_ITEM_SUBTITLE__color);
              }
            }
          }

          .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
            .ant-collapse-arrow {
              transform: rotate(90deg);
            }
          }
        }
      }

      &__title {
        font-weight: 700;
        margin-top: 1px;
      }

      &__collapsable-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        aspect-ratio: 1;
        background: transparent;
        border: 1.5px solid var(--neutralColor-4);
        border-radius: var(--borderRadius-circle);
        padding: 0.25rem;
        margin-right: 1rem;
        color: var(--neutralColor-4);
        transition: none;
        &:hover {
          background: var(--primaryColor-2);
          transition: none;
          color: var(--neutralColor-4) !important;
          border-color: var(--neutralColor-4) !important;
        }
        &:active {
          color: var(--neutralColor-4) !important;
          border-color: var(--neutralColor-4) !important;
        }
      }

      &__add {
        display: flex;
        align-items: center;
        padding-left: 14px;
        background: transparent;
        border: 1px solid var(--primaryColor-6);
        color: var(--primaryColor-6) !important;
        border-radius: var(--borderRadius-lg);
        font-size: 0.85rem;
        font-weight: 600;
        gap: 0.25rem;

        &:hover {
          border-color: var(--primaryColor-5) !important;
          color: var(--primaryColor-5) !important;
        }
      }
      &__addCollapsed {
        padding: 5px;
        font-size: 1rem;
        height: auto;
        border-radius: var(--borderRadius-circle);
      }
    }
    .results {
      padding: 1rem 1rem 1rem 2rem;
    }
  }

  &:global {
    &.DesktopSideMenu--collapsed {
      width: 75px;
      padding-top: 6px;

      .ant-collapse .ant-collapse-header .ant-collapse-expand-icon {
        padding-right: 0.25rem;
        margin-left: 2px;
      }

      .DesktopSideMenu {
        &__title-container {
          align-items: flex-start !important;
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
        }
      }

      .DesktopSideMenuItem__featured,
      .DesktopSideMenuItem__settings-link,
      .DesktopSideMenuItem__text {
        display: none;
      }

      .SideMenuIconOuter--item-icon {
        font-size: 18px;
      }

      .DesktopSideMenuItem__nav-link,
      .DesktopSideMenuItem__nav-link.active {
        padding-right: 0;
        padding-left: 0;
        justify-content: center;

        &:after {
          right: 0;
          left: 0;
          border-top-color: var(--grayscaleColor-3);
        }
      }

      .DesktopSideMenuItem__nav-link.active {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          border-right: 1px solid var(--primaryColor-6);
        }
      }

      .DesktopSideMenu {
        &__collapsable-button {
          svg {
            transform: rotate(180deg);
          }
        }
        &__collapse {
          &.ant-collapse.ant-collapse {
            .ant-collapse-header {
              padding-left: 0.5rem;

              &:after {
                right: 0;
                left: 0;
              }

              .ant-collapse-arrow {
                font-size: 16px;
                right: 6px;
              }

              .collapse-panel-heading-icon {
                font-size: 1.25rem;
              }
            }
          }
        }
      }
    }
  }
}
