.outer {
  &:global {
    &:hover,
    &:has(.ant-select-open) {
      &:has(.ant-select:hover:not(.ant-select-disabled)),
      &:has(.ant-select:not(.ant-select-disabled)) {
        .SelectPrefixComponentOuter,
        .ant-select-selector {
          border-color: var(--primaryColor-6);
        }
      }
    }
  }

  :global {
    .SelectPrefixComponentOuter {
      width: 40px;
      background-color: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      border-right: none;
      border-top-left-radius: var(--borderRadius-l);
      border-bottom-left-radius: var(--borderRadius-l);
      z-index: 1;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .prefix-icon {
      z-index: 1;
      margin-left: 6px;
    }

    .with-prefix-icon {
      .ant-select-selector {
        padding-left: 1.5rem !important;
      }
    }

    .ant-select {
      &:not(.ant-select-disabled) {
        &.active,
        &:active,
        &:hover,
        &:focus {
          .ant-select-selector {
            border-color: var(--primaryColor-6);
          }
        }
      }

      .ant-select-selector {
        min-height: 40px;
        line-height: 38px;
        border-radius: 8px;
        color: var(--neutralColor-6);

        .ant-select-selection-placeholder {
          padding-right: 1rem;
          transition: color 0.3s;
          line-height: inherit;
          inset-inline: 9px;
        }

        .ant-select-selection-overflow {
          line-height: initial;

          .ant-select-selection-overflow-item {
            & > span {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  &:global {
    &.WithPrefixComponent {
      .prefix-icon {
        margin-left: 46px !important;
      }

      .ant-select {
        max-width: calc(100% - 40px);

        .ant-select-selector {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }

    &.ant-select-open {
      .ant-select:not(.ant-select-disabled) {
        .ant-select-selector {
          border-color: var(--primaryColor-6) !important;

          .ant-select-selection-placeholder {
            color: var(--primaryColor-6) !important;
          }
        }
      }
    }
  }
}

:global {
  .maxTagPlaceholder {
    .Checkbox {
      display: none;
    }
    .tooltip-top-results {
      font-size: 12px;
    }
    ul {
      max-height: 334px;
      overflow-y: auto;
    }
  }

  .ant-select-dropdown {
    border-radius: 8px;
    border: 1px solid var(--grayscaleColor-5);
    padding: 0;
    box-shadow: none;

    .ant-select-item-option {
      background-color: transparent;
    }

    .wrp-top-results {
      background-color: var(--grayscaleColor-2);
    }
    .showing-top-results {
      max-width: 300px;
      color: var(--neutralColor-3);
      font-weight: 400;
    }

    &.checkmarks {
      .ant-select-item.ant-select-item-option {
        padding-left: 0;

        .ant-checkbox-wrapper {
          margin-left: 1rem;
        }

        &.ant-select-item-option-selected {
          font-weight: normal;

          .ant-select-item-option-state {
            display: none;
          }
        }
      }
    }

    &.searchInDropdown {
      padding-top: 0;

      &.topResultsInDropdown {
        max-height: 23rem;
      }
      .ant-input::placeholder {
        color: var(--neutralColor-3);
        font-weight: 500;
      }

      .ant-input.ant-input,
      .ant-input-affix-wrapper {
        &:not(:disabled) {
          &,
          &:hover,
          &:focus {
            border-color: transparent;
            box-shadow: none;
          }
        }
        & + .rc-virtual-list {
          border-top: 1px solid var(--grayscaleColor-5);
        }
      }
    }
  }
}
