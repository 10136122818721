.editor {
  width: 100%;

  :global {
    .clear-btn {
      padding: 0 !important;
      border: 1px solid transparent !important;
      display: flex;
      order: 3;
      border-radius: 50%;
      height: 18px;
      margin-left: auto;

      &:before,
      &:after {
        display: none;
      }

      &:focus {
        border: 1px solid var(--errorColor-4) !important;
      }
    }

    .buttons-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .ant-btn {
      &.btn-left,
      &.btn-right {
        background: var(--grayscaleColor-1) !important;
        border: 1px solid var(--grayscaleColor-5);
        font-weight: 500;
        font-size: 14px;
        color: var(--neutralColor-4);
        position: relative;
        z-index: 0;
        height: 24px;
        padding: 0 7px;
        line-height: 22px;

        &:focus {
          border-color: var(--primaryColor-4) !important;
        }

        &.checked {
          color: var(--neutralColor-6);
          border-color: var(--primaryColor-6) !important;
          box-shadow: none !important;
          z-index: 1;
        }
      }

      &.btn-left {
        border-radius: 6px 0 0 6px;
        order: 1;
      }

      &.btn-right {
        border-radius: 0 6px 6px 0;
        margin-left: -1px;
        order: 2;
      }
    }
  }
}
