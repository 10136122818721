.wrapper {
  :global {
    .charts {
      overflow: auto;

      .widget-wrapper {
        min-height: 40px;
        position: relative;
        background: var(--grayscaleColor-1);
        box-shadow: 0 1px 3px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
        border-radius: var(--SECTION__borderRadius);
      }

      .react-resizable-handle {
        opacity: 0;
        transition: opacity 0.3s;
      }

      .react-grid-item:hover .react-resizable-handle {
        opacity: 1;
      }
      .react-grid-item .ql-editor img {
        pointer-events: auto;
        user-select: auto;
      }
    }

    .charts .react-grid-item:hover {
      z-index: 100;
    }

    .no-permissions-img {
      height: calc(100% - 90px);
      min-height: 100px;
      max-height: 400px;
    }
    .no-permissions-txt {
      font-size: 1rem;
      color: var(--neutralColor-4);
    }
  }
}
