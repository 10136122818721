.wrapper {
  :global {
    .edit-button {
      cursor: pointer;

      &.disabled {
        color: var(--grayscaleColor-4);
        cursor: default;
      }
    }
  }
}
