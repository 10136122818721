.container {
  :global {
    .example-block,
    .delete-btn,
    .drag-handler {
      height: 40px;
    }

    .select-property {
      .ant-select {
        width: auto !important;
      }
    }
  }
}

.sortable {
  box-shadow: 0 1px 3px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
  padding: 0 !important;

  :global {
    .drag-item {
      margin-bottom: 0 !important;
      padding: 1rem;
      align-items: center !important;
      border-bottom: 1px solid var(--grayscaleColor-5);
      background-color: var(--grayscaleColor-1);
    }

    .actions {
      margin-top: 2rem;
    }

    .label {
      width: 100%;
    }
  }
}
