.buttonsOuter {
  :global {
    .action-button:not(.ant-tooltip-disabled-compatible-wrapper) {
      height: 40px;
      background: transparent;
      border: none;
      border-radius: var(--borderRadius-circle);
      padding: 10px;
      aspect-ratio: 1;

      &:hover,
      &:active,
      &:focus {
        background: var(--primaryColor-2) !important;
        color: inherit !important;
      }

      &.danger {
        background: var(--errorColor-5);
        border: 1px solid var(--errorColor-5);
        border-radius: var(--borderRadius-circle);
        color: var(--grayscaleColor-1);
        width: 40px;
        height: 40px;
        padding: 0.75rem;

        &:hover,
        &:active {
          background: var(--errorColor-4);
          border-color: var(--errorColor-4);
          color: var(--grayscaleColor-1) !important;
        }
      }
    }
  }
}
