.wrapper {
  :global {
    .ant-collapse-content-box {
      padding: 0 1rem 1rem !important;
    }

    .TableFieldsOuter {
      display: block;
    }

    .InlineEditorOuter,
    .FieldLabelWrapper,
    .FieldLabelOuter,
    .FieldWrapperOuter {
      width: 100%;
    }

    .ExpandedViewWrapper {
      .ExpandedView {
        border-radius: var(--borderRadius-l);
        margin-top: 13px !important;
      }
    }

    .TriangleOuter {
      display: block;
    }

    .FieldLabelWrapper .ArrowButtonOuter {
      background: var(--grayscaleColor-3);
    }

    .PropertyFieldInlineEditorOuter {
      &.available-expanded-view {
        height: auto;

        .FieldLabelWrapper {
          padding-right: 0;
        }
      }
      &.show-expanded-view .field-label-wrapper {
        .layout-field {
          border-color: transparent;
          background-color: transparent;

          &::after {
            display: none;
          }
        }

        &:after,
        &::before {
          display: none;
        }
        .FieldLabelOuter {
          &:after,
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
