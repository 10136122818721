.controlPanel {
  :global {
    .previewSelector {
      max-width: 400px;

      :global {
        .ant-select.ant-select.ant-select-styled2 .ant-select-selector {
          background: var(--grayscaleColor-1);
        }
      }
    }
  }
}

.previewResults {
  :global {
    .previewResultTitle {
      margin-top: calc(-1.5rem + 1px);
      line-height: 1.5rem;
      font-size: 0.8rem;
      color: var(--neutralColor-6);
    }
    .previewResultContainer {
      border: 1px solid var(--grayscaleColor-5);
      border-radius: 8px;
      background-color: var(--grayscaleColor-1);
    }
  }
}
