.dateWidget {
  background: var(--grayscaleColor-1);
  width: 475px;
  border-radius: 4px;

  :global {
    .infoIcon {
      color: var(--grayscaleColor-7);
    }

    .ant-tabs-nav {
      margin-bottom: 24px;
    }

    .ant-tabs-nav-list {
      width: 100%;
      padding-top: 12px;

      .ant-tabs-tab {
        width: 50%;
        margin: 0;
        justify-content: center;
        font-size: 16px;
        line-height: 20px;
        color: var(--neutralColor-4);
      }
    }

    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      display: none;
    }

    .DashboardControlPanelCustomDropdown {
      &__footer {
        border-top: 1px solid var(--primaryColor-1);
        display: flex;
        align-items: center;
        padding: 16px 20px;

        .clear-all-btn {
          font-weight: 500;
          color: var(--neutralColor-4);
          padding: 0;
        }

        .cancel-btn {
          margin-left: auto;
          height: 40px;
          background: var(--grayscaleColor-2);
          border: 1px solid var(--grayscaleColor-5);
          border-radius: 4px;
          font-weight: 600;
          color: var(--neutralColor-6) !important;
          margin-right: 16px;

          &:hover {
            background: var(--grayscaleColor-2);
            border-color: var(--primaryColor-6);
            color: var(--primaryColor-6) !important;
          }
        }

        .save-btn {
          font-weight: 600;
          color: var(--grayscaleColor-1);
          background: var(--primaryColor-6);
          border: none;
          border-radius: 4px;
          height: 40px;
          padding-left: 24px;
          padding-right: 24px;

          &:hover {
            background: var(--primaryColor-5);
          }
        }
      }
    }

    .relative-radio-row {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .ant-radio-group.ant-radio-group {
      display: flex;
      margin-right: 12px;

      .ant-radio-button-wrapper {
        height: 40px;
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--neutralColor-4);
        border: 1px solid var(--primaryColor-1);

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }

        &.ant-radio-button-wrapper-checked {
          background: var(--primaryColor-6);
          border-color: var(--primaryColor-6);
          color: var(--grayscaleColor-1);
        }
      }
    }

    .relative-radio-row-label {
      font-weight: 500;
      color: var(--grayscaleColor-13);
      padding-left: 20px;
      width: 98px;
    }

    .inputs-row {
      display: flex;
      margin: 24px 16px;

      .ant-input {
        height: 40px;
        background: var(--grayscaleColor-1);
        border: 1px solid var(--primaryColor-1);
        border-radius: var(--borderRadius-l);
        color: var(--neutralColor-4);
      }

      .ant-select {
        width: 100%;

        .ant-select-selector {
          background: var(--grayscaleColor-1);
          border: 1px solid var(--primaryColor-1);
          border-radius: var(--borderRadius-l);
          height: 40px;
        }

        .ant-select-selection-item {
          line-height: 37px;
          color: var(--neutralColor-6);
        }
      }
    }

    .inputs-row-cell {
      width: 100%;

      &:first-child {
        padding-right: 6px;
      }

      &:last-child {
        padding-left: 6px;
      }
    }

    .picker-wrapper {
      padding-left: 7px;
      padding-right: 10px;

      .ant-picker {
        display: none;
      }

      > div {
        position: static !important;
      }

      .picker-wrapper-inner {
        position: static !important;
        pointer-events: all !important;
        opacity: 1 !important;

        .ant-picker-panel-container {
          box-shadow: none;
        }
      }

      .ant-picker-ok {
        padding-top: 16px;
        .ant-btn {
          font-weight: 600;
          color: var(--grayscaleColor-1);
          background: var(--primaryColor-6);
          border: none;
          border-radius: 4px;
          height: 40px;
          padding-left: 24px;
          padding-right: 24px;

          &:hover {
            background: var(--primaryColor-5);
          }

          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }

      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-radius: 9px;
      }

      .ant-picker-content {
        width: auto;

        thead {
          tr {
            th {
              text-transform: capitalize;
            }
          }
        }
      }

      .ant-picker-cell {
        padding: 6px;
      }

      .ant-picker-cell::before {
        height: 44px;
      }

      .ant-picker-cell-inner {
        font-weight: 500;
        color: rgba(119, 127, 152, 0.6);
        width: 32px;
        height: 32px;
        border-radius: 9px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-picker-cell-in-view .ant-picker-cell-inner {
        color: var(--neutralColor-6);
      }

      .ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-range-start .ant-picker-cell-inner,
      .ant-picker-cell-range-end .ant-picker-cell-inner {
        color: var(--grayscaleColor-1);
      }

      .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
          .ant-picker-cell-range-start-single
        )::before,
      .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
          .ant-picker-cell-range-end-single
        )::before {
        background: var(--primaryColor-1);
      }

      .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: var(--primaryColor-1);
      }

      .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
        left: 0;
        border-radius: 9px 0 0 9px;
      }

      .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
        right: 0;
        border-radius: 0 9px 9px 0;
      }

      .ant-picker-cell-disabled::before {
        background: var(--infoColor-1);
        border-radius: 9px;
        width: 32px;
        height: 32px;
        top: 22px;
        left: 6px;
      }

      .ant-picker-content thead th {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: var(--neutralColor-6);
      }

      .ant-picker-header {
        .ant-picker-header-prev-btn {
          order: 1;
          color: var(--neutralColor-4);
        }

        .ant-picker-header-super-prev-btn {
          order: 2;
          color: var(--neutralColor-4);
        }

        .ant-picker-header-view {
          order: 3;

          .ant-picker-month-btn,
          .ant-picker-year-btn {
            color: var(--neutralColor-6);
          }
        }

        .ant-picker-header-super-next-btn {
          order: 4;
          color: var(--neutralColor-4);
        }

        .ant-picker-header-next-btn {
          order: 5;
          color: var(--neutralColor-4);
        }
      }

      .ant-picker-date-panel {
        width: auto;
      }

      .ant-picker-panel-container {
        width: 100%;
      }

      .ant-picker-panels {
        width: 100%;
      }

      .ant-picker-panel {
        width: 100%;
        border: none !important;

        .ant-picker-footer {
          border: none !important;
          padding: 0;
        }
      }

      .ant-picker-ranges {
        padding: 0;
      }

      .ant-picker-header {
        border: none;
      }

      .ant-picker-time-panel {
        border-left: none;
        margin-left: auto;

        .ant-picker-content {
          .ant-picker-time-panel-column {
            height: 250px;

            .ant-picker-time-panel-cell-inner {
              height: 37px;
            }
          }
        }

        .ant-picker-header {
          flex-wrap: wrap;
          padding: 0;

          .ant-picker-header-view {
            order: 1;
            width: 100%;
            font-size: 0;
            line-height: 0;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 4px;
            margin-bottom: 16px;

            &::after {
              content: 'Time';
              font-size: 14px;
              color: var(--neutralColor-6);
            }
          }

          &:before,
          &:after {
            width: 50%;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: var(--neutralColor-6);
            padding-bottom: 10px;
          }

          &:before {
            content: 'Hrs';
            order: 2;
          }

          &:after {
            content: 'Min';
            order: 3;
            border-left: 1px solid var(--primaryColor-1);
          }
        }

        .ant-picker-content {
          .ant-picker-time-panel-cell-inner {
            padding: 0;
            font-weight: 500;
            color: var(--neutralColor-6);
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
          }
        }
      }

      .ant-picker-datetime-panel .ant-picker-time-panel-column::after {
        height: 10px;
      }
    }

    .picker-inputs {
      margin-bottom: 24px;

      &__row {
        display: flex;
        padding: 0 16px;
        margin-bottom: 17px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__cell {
        width: 50%;

        &:first-child {
          padding-right: 13px;
        }

        &:last-child {
          padding-left: 13px;
        }
      }

      &__label {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: var(--neutralColor-4);
        margin-bottom: 5px;
      }

      .ant-input {
        padding: 0 12px;
        height: 40px;
        background: var(--grayscaleColor-1);
        border: 1px solid var(--primaryColor-1);
        border-radius: var(--borderRadius-l);
        color: var(--neutralColor-4);

        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .date-range-preview {
      font-size: 0.8rem;
    }
  }
}
