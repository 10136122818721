@import '../../../mixins.scss';

.searchForm {
  position: relative;
  height: 40px;

  border-radius: var(--borderRadius-lg);
  outline: 0px solid rgba(var(--primaryColorRGB-6), 0.1);
  transition: outline 1s ease;

  &:active {
    outline: 4px solid rgba(var(--primaryColorRGB-6), 0.1);

    transition: outline 0s ease;
  }

  input {
    &::placeholder {
      color: var(--neutralColor-4);
    }
  }

  :global {
    .ant-select-dropdown.ant-select-dropdown .ant-select-item {
      min-height: unset;
    }

    .ant-select-selector {
      padding: 0 !important;
    }

    .ant-input.ant-input {
      background: transparent !important;
      height: 38px;
      caret-color: var(--primaryColor-6);
    }

    .ant-input-prefix {
      margin-inline-end: 8px;
    }
    .ant-input-prefix {
      color: var(--neutralColor-4);
      font-size: 1.5rem;
    }
    .ant-input-affix-wrapper {
      &:hover {
        border-color: var(--primaryColor-4);
      }
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
      border-color: var(--primaryColor-6);
      &:hover {
        border-color: var(--primaryColor-6);
      }
    }
    .ant-select-selection-search-input {
      background: transparent;
      border-radius: var(--borderRadius-lg);
      padding-left: 1rem;
    }
  }
}

.labelTitle {
  line-height: 20px;
  padding: 0.5rem 1rem;
}

.popupMenu {
  @include glass-background-effect;
}
