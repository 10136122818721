.wrapper {
  position: relative;

  :global {
    .triangle {
      display: block;
      height: 20px;
      width: 20px;
      background-color: var(--grayscaleColor-2);
      border: 1px solid var(--grayscaleColor-5);
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      border-radius: 0 0 0 0.25em;

      &.top {
        transform: rotate(135deg);
      }
    }
  }
}
