.actionsContainer {
  :global {
    button.menu {
      min-height: 32px !important;
      min-width: 32px !important;
      border: 0;
    }
  }
}

.menu {
  min-width: 208px !important;

  :global {
    .no-icon > span {
      margin-left: 1.5rem;
    }
  }
}
