.outer {
  &:hover {
    :global {
      .code-outer:after {
        background: linear-gradient(
            to top,
            var(--grayscaleColor-1) 5%,
            rgba(var(--grayscaleColorRGB-13), 0) 80%
          )
          100px;
      }
    }
  }

  :global {
    .code-outer:after {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      content: '';
      background: linear-gradient(
        to top,
        var(--grayscaleColorRGB-1) 5%,
        rgba(var(--grayscaleColorRGB-13), 0) 80%
      );
      pointer-events: none;
    }
  }
}

.outerSimple {
  font-size: 14px;
  font-family: monospace;
  overflow: hidden;
  display: block !important;

  :global {
    .code-outer {
      position: relative;
    }
  }
}
