@import '../../../variables';

.wrapper {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 193px);

  @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
    grid-template-columns: repeat(auto-fit, calc(33% - 4px));
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    grid-template-columns: repeat(auto-fit, calc(50% - 4px));
  }

  :global {
    .aggregation-block {
      background-color: rgba(var(--grayscaleColorRGB-1), 0.5);
      padding: 0.875rem 1rem;
      border: 1px solid var(--grayscaleColor-5);
      border-radius: 20px;

      &.clickable {
        cursor: pointer;
        color: var(--primaryColor-6);

        &:hover {
          background-color: var(--primaryColor-1);
          border-color: var(--primaryColor-3);
        }

        &:active {
          border-color: var(--primaryColor-6);
        }
      }

      .GroupedMetricOuter {
        .ant-select-arrow,
        .ant-select-arrow svg,
        .ant-select:not(.ant-select-customize-input)
          .ant-select-selector
          .ant-select-selection-item {
          cursor: pointer;
        }
        &.available-selected-items:hover {
          background-color: var(--primaryColor-2);
        }
      }

      &.clickable.selected {
        color: var(--grayscaleColor-1);
        background-color: var(--primaryColor-6);
        border-color: var(--primaryColor-6);

        &:hover {
          background-color: var(--primaryColor-5);
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          .ant-select-selection-item {
            color: var(--grayscaleColor-1);
          }
        }

        .ant-select-arrow {
          color: var(--grayscaleColor-1);
          svg {
            color: var(--grayscaleColor-1) !important;
          }
        }

        .metric-title,
        .aggregation-value {
          color: var(--grayscaleColor-1);
        }
        .GroupedMetricOuter {
          &.available-selected-items:hover {
            .ant-select-arrow,
            .ant-select-arrow svg,
            .ant-select:not(.ant-select-customize-input)
              .ant-select-selector
              .ant-select-selection-item {
              color: var(--neutralColor-6) !important;
            }
          }
        }
      }
    }

    .metric-title {
      font-size: 0.75rem;
      line-height: 14px;
      color: var(--neutralColor-4);
      overflow: hidden;
    }

    .aggregation-value {
      font-size: 2rem;
      line-height: 1;
      overflow: hidden;
      margin-bottom: 4px;
    }

    .aggregation-value-loading {
      height: 24px !important;
    }
  }
}
