.tableInlineEditor {
  position: relative;

  :global {
    .edit-actions {
      right: 0;
      background: rgba(var(--primaryColorRGB-1), 0.78) !important;
    }

    .editor-actions-block {
      left: -60px;
      bottom: 6px;
      right: unset;
    }

    .clear-selector-button {
      left: -33px;
      bottom: unset;
      top: 34px;
      right: unset;
    }

    .FieldWrapperOuterSimple {
      background-color: inherit !important;
      color: inherit !important;
    }
  }

  &:global {
    &:hover {
      .edit-actions {
        background: rgba(var(--primaryColorRGB-1), 0.78) !important;
      }
    }
  }
}
