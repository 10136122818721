.item {
  background: var(--grayscaleColor-1);
  border: 1px solid var(--grayscaleColor-5);
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: var(--primaryColor-5);
  }

  :global {
    .drag-handle {
      cursor: move;
      line-height: 1;
    }

    .title {
      font-size: 17px;
      line-height: 1;
      color: var(--neutralColor-6);
    }

    .toggle-btn {
      color: var(--neutralColor-6);
    }

    .remove-btn {
      cursor: pointer;
      font-size: 1.5rem;
      line-height: -1;
      color: var(--grayscaleColor-6);
      transition: color 0.3s ease;

      &:hover {
        color: var(--errorColor-4);
      }
    }

    .switcher {
      line-height: 1;
    }

    .SideMenuIconOuter {
      width: 24px;
      height: 24px;
      font-size: 15px;
    }
  }
}
