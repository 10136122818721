.fileWrapper {
  &:global {
    &.kanbanField {
      display: inline-block;
    }
  }
  :global {
    .drag-handle {
      padding: 0 5px;
      cursor: move;

      svg {
        fill: var(--grayscaleColor-6);
        color: var(--grayscaleColor-6);
      }
    }
  }
}
