@import '../../../../../src/mixins';

.dropdownButton {
  :global {
    button {
      border: none;
      background: transparent;

      @include safe-space();
      border-radius: var(--borderRadius-circle) !important;
      padding: 9px !important;
      &:hover,
      &:focus {
        background: var(--primaryColor-2) !important;
      }

      &:active {
        svg {
          color: var(--HEADER_MENU_ACTIVE__color);
        }
      }

      svg {
        color: var(--HEADER_MENU__color);
        transition: color 0.3s ease;
      }
    }
  }
}
