@import '../../../../variables';

.wrapper {
  padding-left: 140px;
  padding-right: 140px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  @media screen and (max-width: map-get($grid-breakpoints, 'md-lg')) {
    padding-left: 40px;
    padding-right: 40px;
  }

  :global {
    .footer {
      text-align: center;
      font-size: 0.8rem;
    }

    .content {
      background-color: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--borderRadius-l);
      min-width: 580px;
      max-width: 580px;
      padding: 70px;

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        min-width: 100%;
        padding: 20px;
      }
    }

    .FieldWrapperOuterSimple {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
