.wrapper {
  background-color: var(--primaryColor-6);
  color: var(--grayscaleColor-1);

  border-radius: 5px;
  position: relative;

  min-width: 200px;

  :global {
    img {
      width: 46px;
    }
    .value {
      font-weight: 600;
      display: block;
      font-size: 2rem;
      margin: 0.8rem 0 0.3rem 0;
    }

    .title {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
}
