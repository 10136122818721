.textarea {
  &:global {
    & > div {
      padding: 0 !important;
      width: 100%;
    }
  }

  :global {
    textarea {
      background: transparent;
      border: none;
      padding: 8px 0 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: var(--neutralColor-6);
      border-radius: var(--borderRadius-l);
      resize: none;
    }
  }
}

.input {
  &:global {
    & > div {
      padding: 0 !important;
      width: 100%;
    }
  }

  :global {
    input {
      border: none;
      font-weight: 500;
      font-size: 14px;
      padding: 0;
    }
  }
}
