@import '../../../variables';

.smallText {
  font-size: 0.8rem;
}

.fieldsContainer {
  margin: -0.5rem;
  padding: 0 !important;
  padding-bottom: 1rem !important;

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    padding-bottom: 0 !important;
  }
}

.text {
  font-size: 0.8rem;
  padding-bottom: 1rem;
  color: var(--grayscaleColor-7);

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 0.9rem;
  }
}

.groupName {
  font-weight: 600;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    font-weight: inherit;
  }
}

.styledRemoveIcon {
  cursor: pointer;
  transition: color 200ms ease;
  font-size: 1.5rem;
  margin: 0 1rem;
  color: var(--grayscaleColor-6);

  &:hover {
    color: var(--errorColor-5);
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    margin: 0;
  }
}
