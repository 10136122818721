@import '../../../../../variables';

.wrapper {
  padding: 0 1rem 1rem 1rem;
  height: 100%;

  :global {
    label {
      font-weight: 600;
    }

    .editor-toolbar.editor-toolbar {
      margin-bottom: 0.5rem;
    }

    .title-placeholder {
      margin: 5px 0;
    }

    .inline-editor-extended {
      font-size: 1.2rem;
      font-weight: 600;
      min-width: 250px;

      > span {
        padding: 2px 0 3px 0;
      }

      > div {
        position: relative;
      }

      .edit-actions {
        position: absolute;
      }
    }

    .create-file-uploader {
      width: 50%;
      margin: 0 auto;

      > div > div {
        padding: 5rem 1rem;
      }

      button {
        margin-top: 2rem;
      }

      span {
        display: block;
      }
    }
  }
}

.versionHistory {
  background: var(--grayscaleColor-1);

  :global {
    .list-header {
      border-bottom: 1px solid var(--grayscaleColor-3);
      border-top: 1px solid var(--grayscaleColor-3);
      padding: 0.5rem;
      font-weight: 600;
    }
  }
}

.sidebar {
  width: 300px;
  border-right: 1px solid var(--grayscaleColor-3);
  display: none !important;
  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    display: flex !important;
  }
}

.columnWrapper {
  border: 1px solid var(--grayscaleColor-3);
}
