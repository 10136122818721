.centeredLoading {
  height: 3rem;
}

.outer {
  padding: 0 0.5rem;
  width: 100%;

  :global {
    .sc-section-card {
      margin-top: 1rem;
    }
  }
}
