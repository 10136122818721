.wrapper {
  border-radius: 20px;
  border: 1px solid var(--grayscaleColor-5);
}

.item {
  z-index: 1001;
  padding-right: 12px;
  background-color: rgba(var(--grayscaleColorRGB-1), 0.6);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--grayscaleColor-5);
  }
}
