@import '../../../variables';

.editor {
  position: relative;

  &__invalid {
    border: 1px solid var(--errorColor-5);
    border-radius: var(--borderRadius-l);

    &:not(.editing) {
      *,
      & *:hover,
      & *:focus,
      & *:active {
        border-color: transparent;
      }
    }

    :global {
      .FieldWrapperOuterSimple {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .edit-actions {
        right: 20px !important;
      }
    }
  }

  :global {
    .edit-actions {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      padding-left: 0.5rem;
      background: rgba(var(--grayscaleColorRGB-1), 0.7);
      gap: 0.2rem;

      &.hide {
        opacity: 0;
        visibility: hidden;
      }
    }

    .with-color,
    .with-background-color {
      .open-item-info-drawer-link,
      a {
        color: inherit !important;
      }
    }
  }

  &:global {
    &:hover {
      .edit-actions {
        opacity: 1;
        visibility: visible;
      }

      .with-color,
      .with-background-color {
        background-color: inherit !important;
        color: var(--neutralColor-4) !important;

        &.disabled {
          background-color: var(--grayscaleColor-3) !important;
        }

        .open-item-info-drawer-link:hover,
        a {
          color: var(--infoColor-5) !important;
        }

        .list-bullet {
          background-color: var(--primaryColor-4) !important;
        }

        .ShowMoreOuter {
          .text-overlay {
            background: linear-gradient(
              rgba(var(--grayscaleColorRGB-1), 0),
              rgba(var(--grayscaleColorRGB-1), 0.78) 50%,
              var(--grayscaleColor-1)
            ) !important;
          }

          .textBlock {
            color: var(--primaryColor-6) !important;
            border-color: var(--primaryColor-6) !important;

            &:hover {
              color: var(--grayscaleColor-1) !important;
            }
          }
        }
      }
    }

    & > .disabled + .edit-actions {
      background: var(--grayscaleColor-3);
    }
  }
}
