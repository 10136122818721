.container {
  padding: 20px;
}

.resetHistoryButton {
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:global {
    &:hover {
      color: var(--infoColor-5);
    }
  }
}

.author {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
  margin-right: 0.5rem;
}

.dateTime {
  font-size: 12px;
  color: var(--grayscaleColor-6);
  white-space: nowrap;
}

.divider {
  margin: 12px 0 !important;
}

.value {
  padding-top: 12px;

  :global {
    .empty-value {
      font-style: italic;
    }
  }
}
