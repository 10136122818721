.container {
  z-index: 1;

  &:global {
    &.pos-top {
      top: -33px;
    }

    &.pos-bottom {
      bottom: -33px;
    }
  }
}

.select {
  :global {
    .ant-select-selector,
    .ant-select-selection-item {
      height: 28px !important;
      min-height: unset !important;
      line-height: 26px !important;
    }
  }
}
