.wrapper {
  :global {
    .edit-actions {
      svg {
        color: var(--primaryColor-6);
        cursor: pointer;
      }
    }
  }
}

.addBtn {
  height: 40px;
  border-top: 1px solid var(--grayscaleColor-5);
}
