@import '../../../../../mixins.scss';
@import '../../../../../variables.scss';

.modal {
  :global {
    .ant-modal {
      @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        max-width: 756px !important;
        width: 100% !important;
      }
    }

    .ant-modal-content {
      background: var(--grayscaleColor-1);
      box-shadow: 4px 0 20px rgba(var(--neutralColorRGB-8), 0.12);
      border-radius: 8px !important;
    }

    .ant-modal-header,
    .ant-modal-body {
      padding: 20px 16px;
    }

    .ant-modal-body {
      @include custom-scroll-styling;
    }

    .ant-modal-footer {
      padding: 16px;
    }

    .ant-alert {
      margin-bottom: 20px;
      background: var(--warningColor-1);
      border: 1px solid var(--warningColor-6);
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      padding: 8px 0 8px 24px;
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        width: 8px;
        top: 0;
        left: 0;
        bottom: 0;
        background: var(--warningColor-6);
      }

      .ant-alert-message,
      .ant-alert-description {
        font-family: var(--fontFamily);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--neutralColor-6);
      }

      svg {
        margin-right: 16px;
        color: var(--warningColor-6);
      }
    }

    .ant-modal-header {
      border-radius: 8px 8px 0 0 !important;
    }

    .ant-modal-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--neutralColor-6);
    }

    .ant-modal-footer {
      display: flex;
      align-items: center;
      padding: 16px 20px;
    }

    .cancel-btn,
    .start-btn {
      height: 40px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      padding: 0 24px;
    }
  }
}
