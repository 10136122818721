.menu {
  :global {
    .slider-outer-wrapper {
      position: relative;
      overflow: hidden;
      transition: max-height 0.2s ease-in;
    }

    .slider-transition-group {
      width: 200%;
      overflow: hidden;

      > div {
        width: 50%;
        float: right;
        transition: all 0.2s ease-in-out;

        &:first-child {
          position: relative;
          left: -50%;
        }
      }
    }

    .slider-right-enter {
      transform: translate3d(100%, 0, 0);
    }

    .slider-left-enter {
      transform: translate3d(-100%, 0, 0);
    }

    .slider-right-enter-active,
    .slider-left-enter-active,
    .slider-right-exit,
    .slider-left-exit {
      transform: translate3d(0, 0, 0);
    }

    .slider-right-exit-active {
      transform: translate3d(-100%, 0, 0);
    }

    .slider-left-exit-active {
      transform: translate3d(100%, 0, 0);
    }
  }
}

.subMenu {
  flex-shrink: 0;
  top: 0;

  :global {
    a {
      display: block;
    }
  }
}

.menuItem {
  :global {
    .FeaturedButton {
      font-size: 1rem;
      padding: 0.5rem;
      color: var(--neutralColor-6) !important;
      transition: none;
      cursor: pointer;

      svg {
        display: block;
      }
    }
  }
}

.subTitleText {
  color: var(--SIDE_MENU_ITEM_SUBTITLE__color);
  font-size: 0.8rem;
  display: block;
  font-weight: 400 !important;
  font-family: Monaco, Consolas, 'Courier New', monospace;
}

.navLink {
  font-size: 0.875rem;
  color: var(--SIDE_MENU_ITEM_MOBILE__color) !important;
  padding: 0.5rem 1.5rem 0.5rem 1.25rem;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
  border-bottom-color: var(--SIDE_MENU_ITEM_ACTIVE_MOBILE__backgroundColor);
  display: block;

  @media screen and (min-width: 80em) {
    padding: 1rem;
  }

  &:global {
    &[disabled] {
      color: var(--grayscaleColor-5) !important;
    }
    &.item {
      border-bottom: none !important;
    }
    &.active:not(.item) {
      border-radius: var(--borderRadius-lg);
      border-bottom-color: var(--SIDE_MENU_ITEM_ACTIVE_MOBILE__backgroundColor) !important;
      background-color: var(--primaryColor-3);
      font-weight: 600;
    }
  }

  :global {
    .item-icon {
      overflow: inherit !important;
      width: 20px;
    }
  }
}

.settingsLink {
  color: var(--SIDE_MENU_ITEM_MOBILE__color) !important;
  font-size: 1rem;
  padding-right: 0.5rem;

  @media screen and (min-width: 80em) {
    padding: 1rem;
  }
}

.text {
  overflow-wrap: break-word;

  &:global {
    &.has-icon {
      padding-left: 0.5rem;
    }
  }
}
