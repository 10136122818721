.wrapper {
  :global {
    .task-item {
      cursor: pointer;
      color: var(--grayscaleColor-7);

      &:hover {
        color: var(--primaryColor-6);

        .task-item-icon {
          color: var(--primaryColor-6);
        }
      }
    }

    .task-item-icon {
      width: 25px;
      height: 25px;
    }
  }
}
