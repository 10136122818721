.wrapper {
  &:global {
    &.warning {
      .ComputedInputOuter.editor-border .col .ace_editor,
      .ComputedInputInlineOuter .col .ace_editor {
        border-color: var(--warningColor-6);
      }
    }
  }

  :global {
    .input-label {
      font-size: 0.9rem;
      padding-bottom: 0.3rem;
      display: block;
      color: var(--neutralColor-6);
    }

    .input-warning {
      color: var(--warningColor-6);
      font-size: 0.9rem;
    }
  }
}
