.breadcrumbsList {
  font-size: 0.85rem;
}

.breadcrumbItem {
  height: 2rem;

  &::after {
    content: '›';
    padding: 0 0.5rem;
    font-size: 1.4rem;
    user-select: none;
    cursor: default;
    font-weight: 500;
    color: var(--neutralColor-2);
  }

  &:last-child::after {
    content: none;
  }

  &.currentBreadcrumb {
    min-width: 200px;
    font-weight: 600;
  }
}

.styledLink {
  font-weight: inherit !important;
  transition: color 200ms ease;
  text-overflow: ellipsis;

  &:hover {
    color: var(--mainColorVibrant);
  }
}
