.instancesForm {
  :global {
    .menuWrapper {
      border-right: 1px solid var(--grayscaleColor-5);

      ul {
        list-style: none;
      }
    }

    .websiteFormContainer {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
