.editor {
  :global {
    .ant-select.ant-select {
      .ant-select-selector {
        min-height: 38px;
        border: none;
        padding: 0;
      }

      .ant-select-selection-search {
        left: 0;
      }

      .ant-select-selection-search-input {
        height: 38px;
      }

      .ant-select-selection-item {
        line-height: 38px;
      }

      .ant-select-clear {
        right: 39px;
        margin-top: -8px;
      }
    }
  }
}
