.breadcrumbsViewTitle {
  min-height: unset;
  line-height: 1;
  display: flex !important;

  :global {
    .DefinitionIcon {
      display: none !important;
    }

    .editor-row {
      overflow: visible;
      max-width: 100%;
    }

    .InlineEditorOuter {
      display: flex;
      line-height: unset;
      padding-right: 65px !important;

      &.editing {
        padding-right: 0 !important;
      }

      .FieldWrapperOuterSimple {
        line-height: unset;

        .text-row {
          display: flex;
        }
      }

      .FieldWrapperOuter {
        border: none;
        min-height: unset;
        padding: 0 !important;
        background-color: transparent;

        input {
          background-color: transparent;
          font-size: inherit;
          line-height: inherit;
        }
      }

      .editor-actions-block {
        display: none;
      }

      .edit-actions {
        background-color: transparent !important;

        > div {
          transition: border-color 0.3s;
          border: 1px solid transparent;
          flex: 1;
          aspect-ratio: 1;
        }
      }
    }
  }
}
