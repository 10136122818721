.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--grayscaleColor-3);
  color: var(--grayscaleColor-7);

  :global {
    .text-container {
      background-color: var(--grayscaleColor-1);
      border-radius: 2px;
      box-shadow: 0 5px 20px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
      font-size: 0.9rem;
    }
    .title {
      color: var(--neutralColor-6);
    }
  }
}
