.steps {
  gap: 22px;
  padding-top: 16px;

  &:global {
    &.ant-steps-horizontal:not(.ant-steps-label-vertical) {
      .ant-steps-item {
        padding-left: 16px;
      }
    }
  }
  :global {
    .ant-steps-item {
      border: 1px solid var(--primaryColor-6);
      border-left-width: 0;
      border-right-width: 0;
      padding-left: 22px;
      cursor: default;
      background: var(--grayscaleColor-1);
      min-width: 0;

      &:first-child {
        border-radius: 8px 0 0 8px;
        border-left-width: 1px;
        &::before {
          display: none;
        }
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
        border-right-width: 1px;
      }

      &::after {
        position: absolute;
        top: 50% !important;
        left: 100%;
        display: inline-block;
        width: 24px !important;
        height: 24px !important;
        margin-top: -12px;
        margin-left: -12px;
        border: 1px solid var(--primaryColor-6);
        border-bottom: none;
        border-left: none;
        transform: rotate(45deg) !important;
        border-inline-color: var(--primaryColor-6) !important;
        border-top-color: var(--primaryColor-6) !important;
        content: '';
        border-radius: 0 5px 0 24px;
        background: var(--grayscaleColor-1);
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        width: 24px !important;
        height: 24px !important;
        margin-top: -12px;
        margin-left: -12px;
        border: 1px solid var(--infoColor-5);
        border-bottom: none;
        border-left: none;
        transform: rotate(45deg);
        content: '';
        border-radius: 0 5px 0 24px;
        background-color: var(--grayscaleColor-2) !important;
        inset-inline-start: 0 !important;
      }
      &.ant-steps-item-active::before {
        width: 24px;
      }

      .ant-steps-item-icon {
        display: none;
      }

      .ant-steps-item-container {
        padding-bottom: 0;
        margin-left: 0;
        max-width: 100%;
        .ant-steps-item-content {
          max-width: 100%;
          .ant-steps-item-title {
            color: var(--primaryColor-6);
          }
        }
      }

      &.ant-steps-item-process {
        .ant-steps-item-container {
          .ant-steps-item-title {
            font-weight: 600;
          }
        }
      }

      &.ant-steps-item-finish {
        background: var(--primaryColor-6);

        &::after {
          background: var(--primaryColor-6);
        }

        .ant-steps-item-container {
          .ant-steps-item-title {
            color: var(--grayscaleColor-1);
          }
        }
      }

      &.ant-steps-item-wait {
        background: var(--grayscaleColor-3);
        border-color: var(--grayscaleColor-5);

        &::after {
          background: var(--grayscaleColor-3);
          border-color: var(--grayscaleColor-5) !important;
          border-inline-color: var(--grayscaleColor-5) !important;
        }
        &::before {
          border-color: var(--grayscaleColor-5);
        }
        .ant-steps-item-container {
          .ant-steps-item-title {
            color: var(--neutralColor-6);
          }
        }
      }
    }
  }
}
