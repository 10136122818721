.wrapper {
  border: 1px solid var(--grayscaleColor-5);

  :global {
    .header {
      .description,
      .title {
        color: var(--grayscaleColor-8);
      }
    }
  }
}
