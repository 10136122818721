.header {
  z-index: 1000;
  background-color: var(--HEADER__backgroundColor);
  color: var(--primaryColor-6) !important;
  padding: 0.5rem 1rem 3px;

  @media print {
    display: none;
  }

  :global {
    .ant-menu-item::after,
    .ant-menu-submenu::after {
      border: none !important;
    }
    .logo-link {
      margin-right: 0.75rem;
      display: block;

      img {
        display: block;
        height: 32px;
      }
    }

    .sign-in-link {
      cursor: pointer;
      transition: color 200ms ease;

      &:hover {
        color: var(--primaryColor-4);
      }
    }

    .user-container {
      height: 100%;

      .icon-notify {
        border-radius: var(--borderRadius-circle) !important;
        &:hover {
          background-color: var(--primaryColor-2) !important;
        }
      }
    }
  }
}

.menu {
  width: 100%;
  border-bottom: 0 !important;

  &:global {
    &.ant-menu-root {
      background-color: transparent;
    }

    &.ant-menu.ant-menu {
      .ant-menu-submenu.ant-menu-submenu {
        &:hover {
          span {
            color: var(--HEADER_MENU_HOVER__color);
          }
        }

        span {
          color: var(--HEADER_MENU__color);
        }
      }

      .ant-menu-item {
        &:hover {
          .ant-menu-title-content {
            & > a {
              color: var(--HEADER_MENU_HOVER__color);
            }
          }
        }

        .ant-menu-title-content {
          & > a {
            color: var(--HEADER_MENU__color);
          }
        }
      }

      .ant-menu-item.ant-menu-item-selected {
        &:hover {
          &:after {
            border-bottom-color: var(--HEADER_MENU_HOVER__borderColor);
          }

          a {
            color: var(--HEADER_MENU_HOVER__color);
          }
        }

        &:after {
          border-bottom-color: var(--HEADER_MENU_ACTIVE__borderColor);
        }

        a {
          color: var(--HEADER_MENU_ACTIVE__color);
        }
      }
    }
  }

  :global {
    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child,
    .ant-menu-submenu {
      font-size: 16px;
      .ant-menu-submenu-title {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

.subMenu {
  &.ant-menu-submenu > .ant-menu {
    border-radius: 0;
  }

  :global {
    .ant-menu-item {
      svg {
        font-size: 1.1rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.menuItem {
  :global {
    .ant-menu-submenu-title {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.menuIcon {
  margin-left: 0.5rem;
}

.subMenuItemIconContainer {
  margin-right: 0.4rem;
}

.menuLink {
  display: flex;
  align-items: center;
}
