.outer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;

  :global {
    .text-overlay {
      position: absolute;
      bottom: calc(100% + 1px);
      height: 1.25rem;
      width: 100%;
      background: linear-gradient(
        rgba(var(--grayscaleColorRGB-1), 0),
        rgba(var(--grayscaleColorRGB-1), 0.78) 50%,
        var(--grayscaleColor-1)
      );
      pointer-events: none;
    }

    .textBlock {
      padding: 5px;
      border-radius: 4px;
      color: var(--primaryColor-6);
      font-size: 0.9rem;
      cursor: pointer;
      transition: color 0.3s ease, background-color 0.3s ease;
      border: 1px solid var(--primaryColor-6);
      height: 29px;
      display: flex;
      align-items: center;

      &:hover {
        color: var(--grayscaleColor-1);
        background-color: var(--primaryColor-6);
      }
    }
  }
}
