.wrapper {
  :global {
    .description-icon {
      color: var(--grayscaleColor-7);
    }

    .message-mapping-field-wrapper {
      background: var(--grayscaleColor-4);
    }
  }
}
