.resizer {
  &:after,
  &:before {
    margin-left: -13px;
    height: 24px;
    content: '';
    background-color: var(--grayscaleColor-5);
    width: 4px;
    display: block;
    border-radius: 8px;
    position: fixed;
    top: 40%;
  }

  &:after {
    margin-left: -7px;
  }
}
