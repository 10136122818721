@import '../../../variables';

.dashboardSelect {
  min-width: 200px;
  max-width: fit-content;
}

.dashboardContainer {
  user-select: none;
  background: var(--grayscaleColor-1);
  box-shadow: 0 1px 3px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
  border-radius: 0.25rem;
  overflow-y: auto;
}

.errorContainer {
  padding: 20px;
  color: var(--grayscaleColor-7);
  width: calc(100% - 3px);

  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    text-align: center;
  }
}
