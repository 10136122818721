.wrapper {
  :global {
    .quick-filter-select {
      width: 150px;
    }

    .formTabs {
      > .ant-tabs-nav {
        margin: 0;

        > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
          width: 100%;

          .ant-tabs-tab {
            flex: 1 1 auto !important;
            margin: 0;
            justify-content: center;
          }
          .ant-tabs-tab-active {
            background-color: var(--grayscaleColor-3);
          }
          .ant-tabs-ink-bar {
            background: none;
          }
        }
      }

      > .ant-tabs-content-holder {
        background-color: var(--grayscaleColor-3);
        padding-right: 1rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
      }
    }

    .control-panel {
      .right-buttons {
        button {
          padding: 0.75rem;
        }
      }
    }

    .no-activity-txt {
      font-size: 1rem;
      color: var(--neutralColor-4);
    }
  }
}
