@import '../../../../../variables';

:global {
  .prev-field-closed {
    .show-expanded-view .field-label-wrapper {
      .layout-field {
        &::after {
          border-left: none !important;
        }
      }
      &:after {
        content: '';
        width: 34px;
        height: 34px;
        position: absolute;
        bottom: -9px;
        border-radius: 100%;
        border-width: 1px;
        border-style: solid;
        left: -33px;
        transform: rotate(216deg);
        border-color: transparent;
        border-left-color: var(--grayscaleColor-5);
      }

      &::before {
        content: '';
        width: 47px;
        height: 47px;
        position: absolute;
        bottom: -17px;
        border-radius: 100%;
        border-width: 9px;
        border-style: solid;
        left: -38px;
        transform: rotate(216deg);
        border-color: transparent;
        border-left-color: var(--grayscaleColor-2);
      }
    }
  }
}

.wrapper {
  &:global {
    &.show-expanded-view .field-label-wrapper {
      position: relative;

      .layout-field {
        border-color: var(--grayscaleColor-5);
        background-color: var(--grayscaleColor-2);
        border-radius: var(--borderRadius-l) var(--borderRadius-l) 0 0;

        &::after {
          position: absolute;
          content: '';
          width: calc(100% - 1px);
          height: 20px;
          bottom: -18px;
          background-color: var(--grayscaleColor-2);
          border-left: 1px solid var(--grayscaleColor-5);
          left: 0px;
        }
      }

      .FieldLabelOuter {
        &:after {
          content: '';
          width: 34px;
          height: 34px;
          position: absolute;
          bottom: -9px;
          border-radius: 100%;
          border-width: 1px;
          border-style: solid;
          right: -33px;
          transform: rotate(-41deg);
          border-color: transparent;
          border-left-color: var(--grayscaleColor-5);
        }

        &::before {
          content: '';
          width: 47px;
          height: 47px;
          position: absolute;
          bottom: -17px;
          border-radius: 100%;
          border-width: 9px;
          border-style: solid;
          right: -38px;
          transform: rotate(-46deg);
          border-color: transparent;
          border-left-color: var(--grayscaleColor-2);
        }
      }
    }

    &.available-expanded-view {
      height: calc(100% - 0.5rem);

      .FieldLabelWrapper {
        padding-right: 10px;
      }
      .InlineEditorOuter {
        .edit-actions {
          top: 20px;
        }
      }

      .field-label-wrapper,
      .layout-field {
        height: 100%;
      }
    }
  }
  :global {
    .FieldLabelWrapper {
      width: 35%;
      flex-shrink: 0;

      @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        .arrow-button {
          background-color: transparent;
          border-color: transparent;

          &.collapsed {
            background-color: var(--grayscaleColor-3);
          }
        }
      }

      .FieldLabelOuter {
        padding-left: 0.5rem;
      }

      .layout-field {
        border: 1px solid transparent;
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        width: 100%;
      }

      .FieldLabelOuter {
        max-width: 100%;
      }
    }

    .InlineEditorOuter {
      width: 65%;
      flex-shrink: 0;
      @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        width: 100%;
      }
    }
  }
}
