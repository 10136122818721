.disabledAssigneeSelector {
  &:global {
    > button {
      background-color: var(--successColor-6);
      border: 1px solid transparent;

      &.main,
      &.menu {
        color: var(--grayscaleColor-1) !important;

        svg {
          color: var(--grayscaleColor-1) !important;
        }
      }

      &.main:focus + .menu,
      &.main:active + .menu,
      &.main:hover + .menu {
        border-left-color: var(--successColor-7);
      }

      &:hover,
      &:focus {
        background-color: var(--successColor-7) !important;
        border-color: var(--successColor-7) !important;
        color: var(--grayscaleColor-1) !important;
      }
    }
  }
}
