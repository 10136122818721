.loader {
  position: relative;
  height: 402px;

  &__container {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 35px);
  }
}

.container {
  background: var(--grayscaleColor-1);
  border: 1px solid var(--grayscaleColor-5);
  border-radius: 1rem;
}

.task {
  &__item {
    display: flex;
    width: 100%;
    border: 1px solid transparent;
    border-bottom-color: var(--grayscaleColor-5);

    &:last-child {
      border-bottom: none;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.5rem;
    transition: border-color 0.3s ease;

    &_smallSize {
      padding: 1rem 0 !important;
      display: block;

      :first-child {
        padding-top: 0 !important;
      }

      :last-child {
        padding-bottom: 0 !important;
      }
    }

    &:hover {
      border-color: var(--primaryColor-6);

      :local {
        .task__title {
          border-bottom-color: var(--neutralColor-6);
        }
      }
    }
  }

  &__status {
    display: flex;
  }

  &__assignee {
    width: 100%;
    margin-top: 0.25em;
  }

  &__tag {
    display: flex;
    align-items: center;
  }

  &__info {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__infoTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    line-height: 1;

    &_smallSize {
      flex-wrap: wrap;
    }
  }

  &__infoBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 15px;
    color: var(--neutralColor-6);
    font-weight: 500;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.3s ease;
    display: flex;
    align-items: center;
  }

  &__processInfo {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--grayscaleColor-7);
  }

  &__processRunId {
    font-family: Monaco, Consolas, 'Courier New', monospace;
    font-size: 0.8rem;
    line-height: 0.9rem;
    font-weight: 500;
    color: var(--grayscaleColor-7);
  }

  &__deadline {
    color: var(--neutralColor-7);
    font-size: 0.75rem;
    white-space: nowrap;
    margin-left: 0.25rem;
  }
}

.simpleRow {
  color: var(--grayscaleColor-7);
  font-size: 0.9rem;
}
