.list {
  margin: 0.5rem 0;
}

.icon {
  margin-left: 0.3rem;
  font-size: 0.45rem;
  opacity: 0.6;
  height: 18px;

  &:global {
    &.invalid {
      fill: var(--errorColor-6);
      font-size: 0.7rem;
    }
  }
}

.field {
  color: var(--grayscaleColor-7);

  &:global {
    &.error {
      color: var(--errorColor-6);
    }

    &.success {
      color: var(--successColor-6);
    }
  }
}
