.antCarouselFix {
  /* Fixes for antd and slick-carousel package conflicts */
  /* todo: remove the following fix when slick-carousel is removed */
  :global {
    .slick-dots {
      width: auto;
      margin-bottom: 0;
    }
    .slick-dots li button:before {
      display: none;
    }
    .slick-dots li button:after {
      position: absolute;
      inset: -4px;
      content: '';
    }
  }
}
.carouselImage {
  height: 80px;
}
.carouselContainer {
  border-radius: 4px;
  overflow: hidden;

  :global {
    .slick-dots {
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        height: 0;
        width: 80%;
        border-radius: 50px;
        margin-top: -3px;
        box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.carouselArrowContainer {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: 10px;
  gap: 8px;

  .carouselArrow {
    position: relative;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      background: var(--neutralColor-4);
      opacity: 0.6;
    }

    &:hover:after {
      opacity: 0.8;
    }
  }
}
