@keyframes moveUpAndDownAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.wrraper {
  :global {
    .edit-actions {
      display: none;
    }

    .file-upload-placeholder {
      width: 100%;

      &:hover {
        .uploadIcon {
          color: var(--infoColor-5);

          :local {
            animation: moveUpAndDownAnimation 0.5s infinite alternate;
          }
        }
      }

      .uploadIcon {
        transition: color 0.25s ease-in-out;
        color: var(--neutralColor-6);
      }

      .uploadedIcon {
        transition: color 0.25s ease-in-out;
        color: var(--successColor-7);
      }

      .error-message {
        color: var(--warningColor-6);
      }
    }
  }
}
