.wrapper {
  :global {
    .http-method {
      background-color: var(--successColor-6);
      border-radius: 2px;
      user-select: none;
      color: var(--grayscaleColor-1);
    }
    .http-url {
      cursor: pointer;
    }
    .remove-icon {
      width: 15px;
      height: 15px;
      cursor: pointer;

      &:hover {
        color: var(--errorColor-5);
      }
    }

    .description-icon {
      color: var(--grayscaleColor-7);
    }

    .array-item + .array-item {
      border-top: 1px dashed var(--grayscaleColor-7);
    }

    .ant-picker.ant-picker {
      margin-top: 0;
    }
  }
}
