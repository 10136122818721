@mixin custom-scroll-styling {
  scrollbar-color: var(--grayscaleColor-5) transparent;
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: inherit;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--grayscaleColor-5);

    &:hover {
      background-color: var(--neutralColor-4);
    }
  }
}

@mixin safe-space {
  padding: 0.25rem;
  border-radius: var(--borderRadius-circle);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutralColor-6);
  cursor: pointer;

  &:hover {
    background-color: var(--primaryColor-2) !important;
  }
}

@mixin outline-animation($outlineSize: 4px) {
  --outline-size: #{$outlineSize};
  justify-content: center;

  &:after {
    width: 100%;
    height: 100%;
    content: '';
    outline: var(--outline-size) solid rgba(var(--primaryColorRGB-6), 0.1) !important; // Use the custom property
    border-radius: var(--borderRadius-l) !important;
    position: absolute;
    opacity: 0;
    transition: all 1s ease;
    left: 0; // in case if someone manually added
    top: 0;
    right: 0;
    bottom: 0;
  }

  &:active:after {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }

  &:not(:hover):after {
    transition: 0s;
    outline: 0px solid rgba(var(--primaryColorRGB-6), 0.1) !important;
  }
}

@mixin flex-truncate {
  flex: 1;
  min-width: 0;
}

@mixin glass-background-effect {
  border-radius: var(--borderRadius-m);
  border: 1px solid rgba(var(--grayscaleColorRGB-1), 0.3);
  background: rgba(var(--grayscaleColorRGB-1), 0.5);
  box-shadow: 0px 2px 10px 0px rgba(17, 29, 73, 0.15);
  backdrop-filter: blur(16px);
}

@mixin dark-popover {
  max-width: 370px;

  :global {
    --antd-arrow-background-color: rgba(var(--grayscaleColorRGB-13), 0.8);

    .ant-popover-inner {
      background-color: var(--antd-arrow-background-color);
      border-radius: var(--borderRadius-m);
      box-shadow: 0 6px 16px 0 rgba(var(--grayscaleColorRGB-13), 0.08),
        0 3px 6px -4px rgba(var(--grayscaleColorRGB-13), 0.12),
        0 9px 28px 8px rgba(var(--grayscaleColorRGB-13), 0.05);
      padding: 8px;
    }

    .ant-popover-title,
    .ant-popover-inner-content {
      color: var(--grayscaleColor-1);
    }

    .ant-popover-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      word-break: break-word;
    }

    .ant-popover-inner-content {
      @include custom-scroll-styling;

      max-height: 220px;
      overflow-y: auto;
    }
  }
}
