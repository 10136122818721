@import '../../../variables';

@mixin dirty-indicator {
  .dirty-indicator {
    width: 10px;
    height: 10px;
    background-color: var(--errorColor-5);
  }
}
.activeTabsContainer {
  height: 0;
  z-index: 1000;
  right: 1rem;
  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    right: 0;
  }

  :global {
    .item-tab,
    .overflow-menu {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
    }

    .item-tab {
      height: auto;
      max-width: 464px;
      width: 100%;
      max-height: calc(100dvh - 72px);
      background: var(--grayscaleColor-1);
      transition: all 0.17s ease-out;
      transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        margin-top: 0.5rem;
      }

      &.minimized {
        max-height: 56px;
        width: 216px;
        transition: all 0.17s ease-out;
        transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

        @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
          max-height: 0;
        }
      }

      .tab-header {
        height: 56px;
      }
    }

    .overflow-menu {
      min-height: 56px;
      width: 56px;
      height: 100%;

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      > button {
        border: 0;
        height: 100%;
        width: 100%;
        background: var(--grayscaleColor-1);
        justify-content: center;
      }
    }
    .collapsed {
      transform: rotate(180deg);
    }
    @include dirty-indicator;

    .PropertyFieldInlineEditorOuter .FieldLabelWrapper .layout-field {
      border: none;
    }
  }
}

.overflowTabsContainer {
  width: 232px;

  :global {
    .ant-dropdown-menu-item {
      padding-right: 12px !important;
    }
    .ant-dropdown-menu-title-content {
      overflow: hidden;
    }
    @include dirty-indicator;
  }
}
