.wrapper {
  border-radius: var(--borderRadius-l);
  border: 1px solid var(--grayscaleColor-5);
}

.expandWrapper {
  z-index: 999;
}

.expand {
  background-color: rgba(var(--grayscaleColorRGB-1), 0.1);
  backdrop-filter: blur(32px);
  box-shadow: 0 2px 10px 0 #111d4926;
  border: 1px solid rgba(var(--grayscaleColorRGB-1), 0.3);
  color: var(--primaryColor-6);
}

.overlay {
  width: 44%;
  &:first-of-type {
    left: 44px;
  }
  &:last-of-type {
    right: 44px;
  }
}
