@import '../../../../../../variables';

.editor {
  width: 100%;

  :global {
    .ant-picker-suffix {
      color: var(--primaryColor-6);
    }

    .ant-picker {
      display: block;
      border: none;
    }

    .ant-picker.ant-picker {
      margin: 0;
      box-shadow: none;
      padding: 0;
      border-radius: var(--borderRadius-l);
      width: 100%;

      .ant-picker-clear {
        right: 30px;
      }

      .ant-picker-input {
        height: auto;
      }
    }
  }

  &:global {
    &.disabled {
      .ant-picker-suffix {
        color: var(--grayscaleColor-3);
      }
    }
  }
}
