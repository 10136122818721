.block {
  background: rgba(var(--grayscaleColorRGB-1), 0.4) !important;
  backdrop-filter: blur(16px);
  box-shadow: 0 2px 10px 0 rgba(var(--neutralColorRGB-7), 0.15);
  border-radius: 20px;

  &:last-of-type {
    flex-basis: 100%;
  }
}
