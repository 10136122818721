.wrapper {
  :global {
    .remove-btn-outer {
      height: 33px;
    }

    .remove-btn {
      cursor: pointer;
      font-size: 1.5rem;
      color: var(--grayscaleColor-6);

      &:hover {
        color: var(--errorColor-5);
      }
    }
  }
}
