@import '../../../../../variables';

.processHeader {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    align-items: center;
  }
}

.processStatusBanner {
  @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
    margin-top: 8px;
  }
  order: 1;
}

.titleWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: rgba(var(--grayscaleColorRGB-1), 0.5);
  border: 1px solid var(--grayscaleColor-5);
  border-radius: var(--borderRadius-lg);
  padding: 0.5rem 0.75rem;
  color: var(--neutralColor-6);
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  order: 3;
  margin-top: 8px;
  padding: 8px;
  min-width: 0;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    flex-wrap: nowrap;
    flex-grow: 1;
    width: 100%;
    order: 2;
    margin-top: 0;
    padding: 0 0.5rem 0 1rem;
    height: 56px;
  }
}

.processId {
  font-weight: 500;
  font-size: 1rem;
  margin-right: auto;
  order: 1;
  min-width: fit-content;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    margin-right: 0;
  }
}

.title {
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  order: 3;
  min-width: 0;
  flex: 1;
  margin-left: 8px;
  min-width: 50px;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    order: 2;
    margin: 0 1rem;
  }

  :global {
    .InlineEditorOuter {
      .edit-actions {
        background: transparent !important;
      }
    }
  }
}

.processActionButton {
  order: 2;
  margin-left: 8px;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    order: 3;
    margin-left: 0;
  }
}

.steps {
  order: 2;
  margin-left: auto;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    order: 3;
    width: 100%;
    margin-left: 0;
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    overflow-x: scroll;
    padding-bottom: 10px;
  }
}

.inlineEditor {
  word-wrap: break-word;
  width: max-content;
  padding-right: 3rem;

  :global {
    .edit-actions {
      .safe-space {
        width: 36px;
        height: 36px;
      }
    }
  }
  &:has(input) {
    width: 100%;
  }
  &:global {
    &.empty {
      color: var(--grayscaleColor-6);
    }
  }
}
