.tag {
  border: none !important;
  background-color: var(--background-color);
  color: var(--main-color);
  border-radius: var(--borderRadius-m);
  padding: 4px 8px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
}
