.container {
  max-width: 350px;

  :global {
    .react-colorful {
      width: auto;
      height: auto;

      .react-colorful__saturation {
        height: 250px;
        border-radius: var(--borderRadius-l);
        border-bottom: none;
      }

      .react-colorful__hue,
      .react-colorful__alpha {
        margin-top: 1.5rem;
        height: 12px;
        border-radius: var(--borderRadius-l);
      }

      .react-colorful__pointer {
        width: 32px;
        height: 32px;
        border-width: 8px;
      }
    }

    .footer-buttons {
      button {
        height: 40px;

        &.cancel {
          color: var(--neutralColor-4);
        }
      }
    }
  }
}

.inputs {
  :global {
    --bs-gutter-x: 0.5rem;

    .title {
      color: var(--neutralColor-4);
      cursor: default;
    }
  }
}

.defaultColors {
  :global {
    .title {
      color: var(--neutralColor-6);
      cursor: default;
    }

    .color {
      width: 28px;
      height: 28px;
      padding: 2px;
    }
  }
}
