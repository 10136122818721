@import '../../../../variables.scss';

.wrapper {
  :global {
    .error-container {
      color: var(--warningColor-6);
    }

    .form-buttons-container {
      position: absolute;
      top: 0;
      right: 0;
      justify-content: flex-end;
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
      padding: 0.5rem 1rem 0 0;

      .DropdownButton {
        button.menu,
        button.main {
          min-height: 33px;
        }
      }

      > button,
      a {
        height: 33px;
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        width: 100%;
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--primaryColor-6);
    }

    .ant-tabs-ink-bar {
      background: var(--primaryColor-6);
    }

    .ant-tabs-tab:hover:not(.ant-tabs-tab-disabled) {
      color: var(--primaryColor-5);
    }
  }
}
