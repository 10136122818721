.wrapper {
  border-radius: 20px;
  border: 1px solid var(--grayscaleColor-5);
}

.item {
  z-index: 1001;
  padding-right: 12px;
  background-color: rgba(var(--grayscaleColorRGB-1), 0.6);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--grayscaleColor-5);
  }

  :global {
    .title {
      font-size: 0.9rem;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: var(--neutralColor-4);
    }

    .removeButton {
      height: 24px;
    }
  }
}

.dragHandle {
  cursor: grabbing;
  padding: 0.75rem 0.5rem;

  &:global {
    &.disabled {
      cursor: unset;

      opacity: 0.3;
    }
  }
}
