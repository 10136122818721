@import '../../../../../mixins.scss';

.wrapper {
  border: 1px solid var(--grayscaleColor-5);
  border-radius: var(--SECTION__borderRadius);

  :global {
    .ant-tree {
      border-radius: var(--SECTION__borderRadius);
    }
    .ant-collapse-header {
      align-items: center !important;
      padding: 0.5rem !important;
      padding-right: 1rem !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-tree-node-content-wrapper {
      @include flex-truncate;
    }

    .ant-tree-treenode {
      display: flex;
      padding: 0 1rem 0 0;
      align-items: center;
      cursor: grab;

      &:not(:last-child) {
        border-bottom: 1px solid var(--grayscaleColor-5);
      }

      .ant-tree-switcher {
        display: flex;
        align-items: center;
        justify-content: center;

        &.ant-tree-switcher-noop {
          display: none;
        }
      }

      .ant-tree-draggable-icon {
        order: 10;
        opacity: 1;
      }

      .ant-tree-node-content-wrapper {
        cursor: grab;
        border-radius: var(--SECTION__borderRadius);

        &:hover {
          background-color: var(--grayscaleColor-1);
        }

        &.ant-tree-node-selected {
          background-color: var(--grayscaleColor-1);
        }
      }

      &.dragging {
        &:after {
          height: 100%;
        }

        .ant-tree-node-content-wrapper {
          cursor: grabbing;
        }
      }
    }
  }
}
