.title {
  position: relative;
  min-height: 1.5rem;
  line-height: 1.6;
  color: var(--neutralColor-6);

  @media (max-width: 800px) {
    max-width: 100% !important;
  }

  :global {
    .editor-row {
      display: flex;
      align-items: center;
      overflow: hidden;
      height: 40px;

      .SideMenuIconOuter {
        margin-right: 16px;
        flex-shrink: 0;
        font-size: 0.75rem;
      }

      .InlineEditorOuter {
        max-width: 100%;
        display: flex;

        .edit-actions {
          background: transparent !important;
        }
      }
    }

    .title-inline-editor {
      padding-right: 40px;
      min-width: 0;

      &.editing {
        max-width: calc(100% + 2rem - 4px);
        width: calc(100% + 2rem - 4px);

        input {
          font-size: 1rem;
          font-weight: 400;
        }
      }

      &.can-edit {
        padding-right: 80px;
      }

      &.empty-value {
        color: var(--grayscaleColor-6);
      }

      .edit-actions {
        right: 0;
        padding-left: 0;
      }

      .PlainTextDisplayOuter {
        padding: 0;
        border: none;
      }

      > div:first-child {
        width: 100%;
      }

      span {
        display: block;
        width: fit-content;
        max-width: 100%;
      }
    }
  }
}
