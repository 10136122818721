.wrapper {
  background: var(--grayscaleColor-1);
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  border-radius: 8px;
  padding: 0.5em;
  margin-bottom: 1rem;
  border: 1px solid var(--grayscaleColor-5);

  :global {
    .item-title {
      min-width: 0;
    }
  }
}
