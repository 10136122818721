.list {
  --hoverBackgroundColor: rgba(var(--grayscaleColorRGB-1), 0.4);

  min-width: 286px;
  max-width: 286px;
  display: flex;
  flex-direction: column;
  border-radius: var(--borderRadius-l);
  overflow: hidden;
  transition: background-color 0.3s;

  &:global {
    &:hover {
      &:not(:has(a[role='button']:hover)):not(:has(.KanbanCard:hover)) {
        .ToggleButton {
          color: rgba(var(--neutralColorRGB-6), 0.8);
        }
      }
    }
  }

  &__hidden {
    min-width: min-content;
    align-self: flex-start;
    position: relative;
    height: 300px !important;
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: all;
    cursor: not-allowed;
  }

  &_draggableArea {
    padding: 0 8px 8px 8px;
    overflow: auto;

    &__hidden {
      opacity: 0;
      visibility: hidden;
      position: absolute !important;
      top: 0;
      left: 0;
      width: 0 !important;
      min-width: 0 !important;
      height: 0 !important;
      padding: 0;
    }

    & > div {
      width: calc(100% - 12px) !important;
    }

    &::-webkit-scrollbar-button {
      height: 16px;
    }
  }
}

.loaderCard {
  background-color: var(--grayscaleColor-1);
  box-shadow: 0 1px 4px 0 rgba(var(--neutralColorRGB-7), 0.1);
  border-radius: var(--borderRadius-m);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
