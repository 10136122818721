.wrapper {
  white-space: nowrap;

  &:global {
    > button.menu {
      padding: 0.5rem;
      min-height: 40px;
      min-width: 40px;

      &:hover {
        color: var(--neutralColor-6) !important;
      }
    }

    > button.main {
      min-height: 40px;
      min-width: 40px;
      margin-right: 1px;

      &.inversed {
        margin-right: 0;
      }
    }

    > button {
      &.main:focus + .menu,
      &.main:active + .menu,
      &.main:hover + .menu {
        border-left-color: var(--primaryColor-6);
      }
    }

    &.withMenu {
      > button.main {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
      }

      > button.menu {
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.popover {
  min-width: 250px;

  :global {
    .ant-popover-inner {
      border-radius: 2px;
      border: 1px solid var(--grayscaleColor-5);
      padding: 3px;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}
