.wrapper {
  &:global {
    &:hover {
      .control-buttons {
        opacity: 1;
      }
    }
  }

  :global {
    .control-buttons {
      position: absolute;
      top: 8px;
      right: 16px;
      display: flex;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 9;

      button {
        height: 40px;
        width: 40px;
        font-size: 16px;

        &.delete-btn {
          color: var(--errorColor-5) !important;
        }

        &.grip-btn {
          cursor: grabbing !important;
        }
      }
    }

    .widget-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--neutralColor-6);

      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;

      padding-right: 1rem !important;
      padding-left: 1rem !important;

      box-shadow: inset 0 -1px 0 var(--grayscaleColor-5);
    }

    .widget-body {
      padding: 1rem;
      height: calc(100% - 56px);
    }
  }
}
