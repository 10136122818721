.dropdown {
  z-index: 10000000;

  :global {
    .ant-picker-ok .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: var(--fontFamily);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--grayscaleColor-1);
      background: var(--primaryColor-6);
      border: none;
      border-radius: 4px;
      height: 40px;
      width: 82px;

      &:hover {
        background: var(--primaryColor-5);
      }
    }

    .ant-picker-now-btn {
      width: 82px;
      height: 40px;
      margin-right: 12px;
      background: var(--grayscaleColor-2);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      color: var(--neutralColor-6) !important;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: color 0.3s, border-color 0.3s;

      &:hover {
        background: var(--grayscaleColor-2);
        border-color: var(--primaryColor-6);
        color: var(--primaryColor-6) !important;
      }
    }

    .ant-picker-cell-inner {
      font-family: var(--fontFamily);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: rgba(var(--neutralColorRGB-4), 0.6);
      width: 32px;
      height: 32px;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-picker-content {
      thead {
        tr {
          th {
            text-transform: capitalize;
          }
        }
      }
    }

    .ant-picker-cell-in-view .ant-picker-cell-inner {
      color: var(--neutralColor-6);
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      color: var(--grayscaleColor-1);
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-radius: 7px;
    }

    .ant-picker-content thead th {
      font-family: var(--fontFamily);
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: var(--neutralColor-6);
    }

    .ant-picker-header {
      .ant-picker-header-prev-btn {
        order: 1;
        color: var(--neutralColor-4);
      }

      .ant-picker-header-super-prev-btn {
        order: 2;
        color: var(--neutralColor-4);
      }

      .ant-picker-header-view {
        order: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-picker-month-btn,
        .ant-picker-year-btn {
          font-family: var(--fontFamily);
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: var(--neutralColor-6);
        }
      }

      .ant-picker-header-super-next-btn {
        order: 4;
        color: var(--neutralColor-4);
      }

      .ant-picker-header-next-btn {
        order: 5;
        color: var(--neutralColor-4);
      }
    }

    .ant-picker-panel {
      .ant-picker-datetime-panel + .ant-picker-footer {
        border-top: 1px solid var(--grayscaleColor-5);
        border-bottom: none;
        padding: 16px 20px;
      }

      .ant-picker-today-btn {
        display: block;
      }
    }

    .ant-picker-ranges {
      padding: 0;
    }

    .ant-picker-header {
      border: none;
    }

    .ant-picker-time-panel {
      border-left: none;
    }

    .ant-picker-time-panel {
      .ant-picker-header {
        flex-wrap: wrap;
        padding: 0;

        .ant-picker-header-view {
          order: 1;
          width: 100%;
          font-size: 0;
          line-height: 0;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: 'Time';
            font-family: var(--fontFamily);
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: var(--neutralColor-6);
          }
        }

        &:before,
        &:after {
          width: 50%;
          font-family: var(--fontFamily);
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: var(--neutralColor-6);
          padding-bottom: 20px;
        }

        &:before {
          content: 'Hrs';
          order: 2;
        }

        &:after {
          content: 'Min';
          order: 3;
          border-left: 1px solid var(--grayscaleColor-5);
        }
      }

      .ant-picker-content {
        .ant-picker-time-panel-cell-inner {
          padding: 0;
          text-align: center;
          font-family: var(--fontFamily);
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: var(--neutralColor-6);
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
        }
      }
    }

    .ant-picker-datetime-panel {
      .ant-picker-time-panel-column::after {
        height: 10px;
      }

      .ant-picker-date-panel {
        .ant-picker-body {
          padding-top: 0;
        }
      }

      .ant-picker-time-panel {
        .ant-picker-header-view {
          margin-bottom: 7px;
        }

        .ant-picker-time-panel-column:not(:first-child) {
          border-left-color: var(--grayscaleColor-5);
        }
      }
    }
  }
}
