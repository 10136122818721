@import '../../../../variables';

.container {
  margin-top: 6px;
  position: relative;
  font-size: 0.9rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  user-select: none;

  :global {
    .FieldLabelOuter {
      width: 35%;
      flex-shrink: 0;
      @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        width: 100%;
      }
    }

    .FieldWrapperOuter {
      width: 65%;
      flex-shrink: 0;
      @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        width: 100%;
      }

      &.with-background-color,
      &.with-color {
        &:hover {
          .files-container {
            .file-outer {
              &:before {
                background-color: var(--primaryColor-5);
              }

              .drag-handle {
                svg {
                  color: var(--grayscaleColor-6);
                }
              }
            }

            .file-edit-actions {
              svg {
                color: var(--errorColor-5);
              }
            }
          }
        }

        .edit-actions {
          color: inherit;
        }

        .files-container {
          .label-values {
            color: inherit;
          }

          .file-outer {
            &:before {
              background-color: inherit;
            }

            .drag-handle {
              svg {
                color: inherit;
              }
            }

            .label-wrapper {
              a {
                color: inherit;
              }
            }

            &:hover {
              .label-wrapper {
                a {
                  color: var(--neutralColor-6);
                }
              }
            }
          }

          .file-edit-actions {
            svg {
              transition: color 0.3s;
              color: inherit;
            }
          }
        }
      }

      &.slider.editor-field.has-value {
        .edit-actions {
          @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
            top: -28px;
          }
        }
      }
    }

    .icon {
      margin-right: 10px;
    }

    .editor-field {
      position: relative;

      &:hover .upload-button {
        color: var(--primaryColor-5);
      }

      &.has-value .edit-actions {
        top: 12px;
      }
    }

    .upload-placeholder {
      font-size: 0.9rem;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      color: var(--grayscaleColor-7);
      font-weight: 400;

      svg {
        margin-right: 0.5rem;
      }
    }

    .loader {
      font-size: 10px;
      text-indent: -9999em;
      border-top: 2px solid rgba(var(--grayscaleColorRGB-1), 0.2);
      border-right: 2px solid rgba(var(--grayscaleColorRGB-1), 0.2);
      border-bottom: 2px solid rgba(var(--grayscaleColorRGB-1), 0.2);
      border-left: 2px solid var(--neutralColor-6);
      transform: translateZ(0);
      animation: file-field-loader-animation 1.1s infinite linear;

      &,
      &:after {
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
      }
    }

    .file-upload-input {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 1px;
      height: 1px;
      z-index: 1;
    }

    .upload-date {
      font-size: 0.8rem;
    }

    .upload-contact {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--grayscaleColor-7);
    }

    .edit-actions {
      position: absolute;
      right: 16px;
      color: var(--grayscaleColor-7);
      z-index: 99;
      display: flex;
      align-items: center;

      div {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }

    .view-history-button,
    .edit-images-button,
    .carousel-button,
    .upload-button {
      cursor: pointer;
      z-index: 2;
      width: 20px;
      height: 20px;

      &:hover {
        color: var(--primaryColor-5);
      }
    }

    .edit-images-button {
      height: 17px;
    }

    .upload-button {
      height: 19px;
    }

    .files-container {
      padding: 10px 0 0 0;
      width: 100%;

      &.slider {
        padding: 0;
        margin: 0 -1rem;
        width: calc(100% + 2rem);
      }

      .label-values {
        margin-bottom: 10px;
        border-bottom: 1px solid var(--grayscaleColor-3);
        font-weight: 500;
        font-size: 14px;
        color: var(--grayscaleColor-7);
        padding-top: 6px;
        padding-bottom: 10px;
        cursor: pointer;
        font-weight: 400;
      }
    }
  }
}

.fileItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;

  :global {
    .file-outer {
      display: flex;
      align-items: center;
      height: 38px;
      border: 1px solid var(--grayscaleColor-3);
      border-radius: 4px;
      padding-right: 8px;
      width: 100%;
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        background: var(--primaryColor-5);
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
      }
    }

    .drag-handle {
      padding: 10px 12px;
      flex-shrink: 0;
      cursor: move;

      svg {
        fill: var(--grayscaleColor-6);
        color: var(--grayscaleColor-6);
      }
    }

    .image-preview {
      width: 100px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      flex-shrink: 0;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .label-wrapper {
      overflow: auto;

      a,
      p {
        color: var(--neutralColor-6);
        display: block;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .file-edit-actions {
      flex-shrink: 0;
      margin-left: auto;
    }

    .delete-button {
      svg {
        color: var(--errorColor-5);
      }
    }
  }

  &:global {
    &.image {
      .file-outer {
        height: auto;
      }

      .label-wrapper {
        max-width: calc(100% - 120px);
      }
    }
  }
}
