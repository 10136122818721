.groupSettingBtn {
  outline: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  transition: color 0.3s ease;
  color: var(--grayscaleColor-6);

  &:hover,
  &__active {
    color: var(--primaryColor-5);
  }
}
