.container {
  padding: 0 8px;

  :global {
    .with-background {
      background-color: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: var(--SECTION__borderRadius);
    }

    .card-item {
      border: 1px solid var(--grayscaleColor-5);
      border-radius: 4px;

      .logo {
        background-color: var(--grayscaleColor-3);
      }

      .title {
        color: var(--neutralColor-6);
      }

      .description {
        color: var(--neutralColor-4);
      }
    }
  }
}
