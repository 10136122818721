@import '../../../../mixins.scss';
@import '../../../../variables.scss';

@keyframes blinkAnimation {
  0% {
    background-color: var(--primaryColor-3);
  }
  100% {
    background-color: transparent;
  }
}

.blinkActivityTask {
  animation: blinkAnimation 1s 3 ease-in-out;
}

.wrapper {
  :global {
    .sections {
      &.ant-collapse > .ant-collapse-item {
        > .ant-collapse-header {
          padding-left: 0;
        }
        > .ant-collapse-content > .ant-collapse-content-box {
          padding-top: 0;
        }
      }
    }

    .comments {
      max-height: 200px;
      overflow: auto;

      .FieldWrapperOuter:hover {
        border-color: var(--grayscaleColor-5);
      }
    }
    .files {
      .files-input {
        > .disabled {
          background: inherit;
          border: none;
          padding: 0 !important;
        }
        .files-container {
          padding: 0;
        }
      }
    }

    .activities {
      .ant-collapse-header-text {
        @include flex-truncate;
      }
      .ant-collapse-arrow.ant-collapse-arrow {
        top: 13px;
      }

      .ant-collapse-header {
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;

        margin-bottom: 12px;
        margin-top: 12px;
      }

      .ant-collapse-content {
        padding-left: 30px;

        .ant-collapse-content-box {
          padding-top: 0 !important;
          padding-right: 0;
        }
      }

      .ant-collapse-item {
        position: relative;
      }

      .ant-collapse-item {
        &::before {
          content: '';
          height: calc(100% - 28px);
          width: 2px;
          position: absolute;
          background-color: var(--activity-background-color, var(--successColor-6));
          left: 25px;
          top: 28px;
        }
      }
    }
  }
}
