.card {
  max-width: none !important;
  border: 1px solid var(--grayscaleColor-5);

  :global {
    .image-icon {
      font-size: 2.5rem;
    }

    .logo-container {
      width: 8rem;
      height: 8rem;
      border: 1px solid var(--grayscaleColor-6);
    }

    .custom-select {
      .ant-select-multiple {
        cursor: pointer;
      }

      &.ant-select-focused {
        .ant-select-selection-placeholder {
          display: none !important;
        }
      }
    }
  }
}

.formSection {
  max-width: 600px;
}
