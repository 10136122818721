.container {
  cursor: pointer;

  &:has(svg):hover {
    background-color: var(--primaryColor-2) !important;
    border-radius: var(--borderRadius-circle) !important;
  }

  :global {
    .notification-dot {
      font-family: var(--fontFamily) !important;
      animation: none !important;
      top: 0 !important;
      right: 0 !important;
      transform: translate(50%, -50%) !important;
      font-size: 12px !important;
      line-height: 1;
      background-color: var(--errorColor-5) !important;
      border-radius: 10px !important;
      box-shadow: 0 0 0 1px var(--grayscaleColor-1) !important;
    }
  }

  svg {
    color: var(--HEADER_MENU__color);
    transition: color 0.3s ease;
  }
}
