.button {
  max-width: 250px;
  border-radius: var(--borderRadius-lg) !important;
  svg {
    font-size: 0.75rem !important;
  }
}
.disabledProcessTooltip {
  z-index: 1000;
}
.disabledTitle {
  max-width: 198px;
}
.menu {
  transition: backdrop-filter 0.3s ease;
  backdrop-filter: none !important;

  &:global {
    &.is-open {
      // Safari sometimes doesn't handle backdrop-filter correctly when an element is first rendered, especially if it's dynamically created, like with dropdown.
      // Add backdrop-filter only after the element has appeared on the screen.
      backdrop-filter: blur(16px) !important;
    }
  }
}
