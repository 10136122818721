.wrapper {
  background-color: var(--primaryColor-2);
  border-bottom: 1px solid var(--grayscaleColor-1);
  line-height: 15px;

  :global {
    & .ant-badge .ant-badge-count {
      background-color: var(--primaryColor-6);
      font-size: 10px;
      line-height: 13px;
      padding: 0.25rem 0.5rem;
      box-shadow: none;
      font-weight: 500;
    }
  }
}
