.valueItem {
  border: 1px solid var(--grayscaleColor-5);
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 16px;

  :global {
    .FieldWrapperOuter,
    .FieldWrapperOuter:hover {
      border-color: var(--grayscaleColor-5);
    }
  }

  &__value {
    position: relative;
  }

  &__heading {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  &__headingField {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: hidden;

    svg {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &__headingFieldLabel {
    font-weight: 400;
    font-size: 16px;
    color: var(--neutralColor-6);
    max-width: 100%;
  }

  &__headingFieldPropertyId {
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 300;
    color: var(--grayscaleColor-7);
    height: 24px;
    border-left: 1px solid var(--grayscaleColor-5);
    padding-left: 8px;
    margin-left: 8px;
    max-width: 100%;
    line-height: 24px;
  }

  &__headingDate {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-left: 8px;
  }

  &__headingTime {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--neutralColor-4);
  }

  &__headingUndo,
  &__headingCurrent {
    display: flex;
    align-items: center;
    height: 24px;
    color: var(--neutralColor-3);
    margin-left: 9px;
    position: relative;

    &:before {
      content: '';
      height: 100%;
      width: 1px;
      background-color: var(--grayscaleColor-5);
      position: relative;
      top: 0;
      left: -5px;
      margin-left: 9px;
    }
  }

  &__author {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--neutralColor-6);
  }
}

.resetHistoryButton {
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    color: var(--infoColor-5);
  }
}

.currentHistoryButton {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid var(--neutralColor-3);
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    background-color: var(--neutralColor-3);
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
}

.loadMore {

  :global {
    .ant-btn {
      height: 40px;
      background: var(--grayscaleColor-2);
      border: 1px solid var(--grayscaleColor-5);
      border-radius: 4px !important;
      font-weight: 600;
      font-size: 14px;
      color: var(--neutralColor-6) !important;

      &:hover {
        background: var(--grayscaleColor-2) !important;
        border-color: var(--primaryColor-6) !important;
        color: var(--primaryColor-6) !important;
      }
    }
  }
}
