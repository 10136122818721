.overlay {
  z-index: 1000;

  :global {
    .ant-dropdown-menu {
      padding: 0 !important;
      background: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      box-shadow: 4px 0 20px rgba(var(--neutralColorRGB-8), 0.12);
      border-radius: 0.375rem;
      overflow: auto;
      max-height: max(382px, 40vh);
      min-width: 240px;
      position: static;
    }

    .ant-dropdown-menu-item-group-title {
      padding: 1rem 1rem 0.25rem !important;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.9375rem;
      color: var(--neutralColor-6) !important;
    }

    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item {
      height: 40px !important;
      padding: 0 1rem;

      &:last-child {
        border: none;
      }

      &.ant-dropdown-menu-item-active,
      &:hover {
        background: var(--primaryColor-2) !important;

        .ant-dropdown-menu-title-content {
          .SearchMenuItemLabel {
            color: var(--grayscaleColor-1);
          }

          .SideMenuIconOuter svg {
            color: inherit !important;
          }
        }
      }

      .SideMenuIconOuter {
        margin-left: 0;
        margin-right: 0;
        width: 24px;
        height: 24px;
      }

      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
        padding: 0 1rem;

        .SideMenuIconOuter {
          width: 24px;
          height: 24px;
          margin-right: 1rem;
        }

        .SearchMenuItemLabel {
          white-space: nowrap;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.0625rem;
          color: var(--neutralColor-6);
        }
      }
    }

    .SearchMenuItem.ant-dropdown-menu-item.ant-dropdown-menu-item {
      border-bottom: 1px solid var(--grayscaleColor-5);
      background: var(--grayscaleColor-1) !important;
      border-radius: 0.3125rem 0.3125rem 0 0;
      padding-right: 0;
      padding-left: 0;

      &:hover {
        background: var(--grayscaleColor-1) !important;
      }

      .ant-input {
        border: none;
        background: transparent;
        height: 100% !important;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .ant-dropdown-menu-title-content {
        height: 100% !important;
        padding: 0;
      }
    }
  }
}
