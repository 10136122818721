.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .ant-modal {
      width: 80vw !important;
      height: 80vh;

      .ant-modal-content {
        height: auto;
        overflow: hidden;

        .ant-modal-footer .cp-c-row {
          max-width: 100%;
        }

        .ant-modal-body {
          margin-bottom: 61px;
        }
      }

      .ReactTable .rt-tbody {
        overflow: auto;
        position: relative;
      }
    }
  }
}
