.wrapper {
  &:global {
    position: relative;
  }

  :global {
    .col .ace_editor {
      border-radius: 2px;
      border: 1px solid var(--grayscaleColor-6);

      .ace_layer.ace_text-layer,
      .ace_layer.ace_marker-layer,
      .ace_layer.ace_cursor-layer,
      .ace_layer.ace_print-margin-layer {
        width: calc(100% - 51px);
      }
    }

    .btn {
      cursor: pointer;
      position: absolute;
      top: 0.5rem;
      right: 0;
      z-index: 9;
    }
  }
}
