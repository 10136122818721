.wrapper {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  padding: 0;
  background: var(--grayscaleColor-3);
  border: 1px solid var(--grayscaleColor-5);
  border-radius: 0.25rem;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutralColor-4);

  svg {
    width: auto;
    height: auto;
    vertical-align: inherit;
    transition: all 0.3s;
  }

  &:global {
    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
