@import '../../../../../variables';

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--fontFamily);

  &:global(.broken-chrome) {
    circle + g foreignObject > div {
      margin-top: 38px;
    }
  }

  &:global(.readonly) {
    :global {
      .dagre-d3 {
        .node {
          cursor: auto;

          &:hover {
            rect,
            polygon,
            circle,
            ellipse,
            diamond {
              stroke-width: 1.5px;
            }
          }
        }
      }
    }
  }

  :global {
    .node-actions {
      position: absolute;
      background: var(--grayscaleColor-1);
      border-radius: 30px;
      padding: 0.2rem;
      transform: translateX(-50%);
      box-shadow: 0 2px 5px rgba(var(--grayscaleColorRGB-13), 0.2);
      border: 1px solid var(--grayscaleColor-6);
      display: none;
      > div {
        display: inline-block;
      }
      svg {
        cursor: pointer;
        fill: var(--neutralColor-6);
        vertical-align: middle;
      }
    }

    foreignObject {
      overflow: visible;

      & > div {
        /* ? The `foreignObject` doesn't work correctly on iOS,
         ? its children with `absolute` and `fixed` positions don't see the object as a parent */
        position: fixed;
        left: 0;
        top: 0;
      }
    }

    .dagre-d3 {
      overflow: visible;

      .node {
        fill: var(--grayscaleColor-2);
        cursor: pointer;
        user-select: none;

        rect,
        polygon,
        circle,
        ellipse,
        diamond {
          stroke: var(--primaryColor-6);
          stroke-width: 1.5px;
        }

        &:hover {
          rect,
          polygon,
          circle,
          ellipse,
          diamond {
            stroke-width: 3.5px;
          }
        }

        .label {
          fill: var(--primaryColor-6);
          white-space: normal;

          .loop {
            color: var(--grayscaleColor-7);
          }

          .decision {
            white-space: nowrap;
          }

          .email,
          .sms,
          .automated,
          .process_run,
          .generate_document,
          .delay,
          .milestone,
          .step {
            padding-right: 15px;

            svg {
              /* Required to position the node icon outside the text block */
              position: fixed;
              top: -5px;
              right: -7px;
            }
          }

          .email svg {
            top: -9px;
          }
        }

        g div {
          /* hide text if it more than N lines  */
          overflow: hidden;
          /* use this value to count block height */
          line-height: 1.3em;
          /* max-height = line-height (1.2) * lines max number (3) */
          max-height: 2.6em;
          text-align: left;
          white-space: normal;
        }
      }

      .node.selected {
        g div {
          color: var(--grayscaleColor-1);
        }

        rect,
        polygon,
        circle,
        ellipse,
        diamond {
          fill: var(--primaryColor-6);
          stroke-width: 3.5px;
        }
        .label {
          fill: var(--grayscaleColor-1);
        }
      }
      .node.invalid {
        rect,
        polygon,
        circle,
        ellipse,
        diamond {
          stroke: var(--warningColor-6);
        }

        .warning {
          display: none;
          position: fixed;
          z-index: 2;
          right: -22px;
          top: -22px;
          padding: 2px;
          border: 2px solid var(--warningColor-6);
          border-radius: 100%;
          background: var(--grayscaleColor-1);
          fill: var(--warningColor-6);
          stroke: var(--warningColor-6);
          @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
            display: block;
          }
        }

        &.decision .warning {
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
        }

        &.loop_while .warning {
          right: -20px;
          top: -15px;
        }
      }

      .edgePath {
        stroke: var(--grayscaleColor-6);
        fill: var(--grayscaleColor-6);
        stroke-width: 1.5px;
      }
    }

    .btn-settings:hover,
    .btn-copy:hover,
    .btn-paste:hover {
      fill: var(--primaryColor-5);
    }
    .btn-add:hover,
    .btn-replace:hover {
      fill: var(--successColor-7);
    }
    .btn-delete:hover {
      fill: var(--errorColor-6);
    }
  }
}
