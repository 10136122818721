@import './mixins';
@import './variables';

.antd-dropdown-popup-styled {
  background: var(--grayscaleColor-1);
  border: 1px solid var(--primaryColor-5);
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(var(--grayscaleColorRGB-5), 0.33);
  border-radius: var(--borderRadius-l);
  padding: 0;

  .ant-select-dropdown-header {
    background: var(--grayscaleColor-3);
    border-bottom: 1px solid var(--grayscaleColor-3);
    min-height: 40px;
    padding: 8px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutralColor-4);
    display: flex;
    align-items: center;
  }

  div.ant-select-item.ant-select-item-option {
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;

    .ant-select-item-option-content {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--neutralColor-6);
      transition: color 0.3s ease;
    }

    .ant-select-item-option-state {
      line-height: 1;
      height: 20px;
      font-size: 12px;
      transition: color 0.3s ease;
      display: flex;
      align-items: center;
    }

    &:hover,
    &.ant-select-item-option-active {
      background-color: var(--primaryColor-5);

      .ant-select-item-option-content {
        color: var(--grayscaleColor-1);
      }

      .ant-select-item-option-state {
        color: var(--grayscaleColor-1);
      }
    }

    &.create-new-option {
      background: var(--grayscaleColor-3);
      border-top: none !important;
      min-height: 40px;

      &:hover,
      &.ant-select-item-option-active {
        background: var(--primaryColor-5);

        .ant-select-item-option-content {
          font-weight: 400;
          color: var(--grayscaleColor-1);
        }
      }
    }
  }
}

.ant-select.ant-select {
  &.ant-select-single {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      height: 100%;
      width: calc(100% - 1rem);
      padding-right: 1.7rem;
      transition: color 0.3s;
      line-height: 38px;
      display: flex;
      align-items: center;
    }
    &.ant-select-disabled {
      .ant-select-selection-placeholder {
        color: var(--neutralColor-4);
      }
    }
  }

  &.ant-select-multiple {
    .ant-select-selection-overflow {
      padding: 5px 0;
      max-width: calc(100% - 1rem);
    }

    .ant-select-selection-search {
      margin-left: 0;
    }
  }

  &:not(.ant-select-disabled) {
    &.ant-select-status-warning {
      .ant-select-selector {
        border-color: var(--warningColor-6);
      }
    }

    .ant-select-selector {
      border-color: var(--grayscaleColor-5);

      .ant-select-selection-item {
        .ant-checkbox-wrapper {
          display: none;
        }
      }
    }

    &.active,
    &:active,
    &:hover,
    &:focus,
    &.ant-select-open {
      &:not(.ant-select-status-warning) {
        .ant-select-selector {
          border-color: var(--primaryColor-6);
        }
      }

      &.ant-select-status-warning {
        .ant-select-selector {
          border-color: var(--warningColor-5);
        }
      }
    }
  }

  .ant-select-selector {
    .ant-select-selection-placeholder {
      color: var(--grayscaleColor-7);
      font-weight: 400;
    }

    .ant-select-selection-item {
      color: var(--neutralColor-6);
      font-weight: 500;
    }
  }

  .ant-select-selector {
    min-height: 40px;
    border-radius: var(--borderRadius-l);
    overflow: hidden;

    .ant-select-selection-search-input {
      height: 100%;
    }
  }

  .ant-select-arrow {
    right: 9px;
    font-size: 16px;
  }

  .ant-select-clear {
    width: 16px;
    height: 16px;
    margin-top: 0;
    transform: translateY(-50%);

    .anticon {
      vertical-align: 0;
    }
  }
}

.ant-select.ant-select.ant-select-styled2 {
  &.ant-select-lg {
    font-size: 14px;
  }

  .ant-select-selector {
    border: 1px solid var(--grayscaleColor-5);
    border-radius: 4px;
    background: var(--grayscaleColor-2);
    font-size: 14px;

    .ant-select-selection-item {
      color: var(--grayscaleColor-9);
    }
  }

  .ant-select-arrow {
    svg {
      color: var(--neutralColor-6) !important;
    }
  }

  &:hover:not(.ant-select-disabled),
  &.ant-select-focused:not(.ant-select-disabled) {
    .ant-select-selector {
      border-color: var(--primaryColor-4);
      color: var(--primaryColor-5);

      .ant-select-selection-item {
        color: var(--primaryColor-5);
      }
    }
  }
}

.ant-select-dropdown.ant-select-dropdown.antd-dropdown-popup-styled2 {
  @include glass-background-effect;
  padding: 0;
  overflow: hidden;

  .ant-select-item-group {
    font-weight: 400;
    font-size: 0.75rem;
    color: var(--neutralColor-6);
    padding-top: 0.75rem;
    min-height: auto;
  }

  .ant-select-item-option {
    height: 40px !important;
    padding: 0;
    color: var(--neutralColor-6);
    font-weight: 500;

    svg {
      margin-right: 0.75rem !important;
    }

    &:last-child {
      border: none;
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      padding: 0 16px;
      white-space: nowrap;
      font-size: 14px;
    }
  }
}

.ant-picker-datetime-panel {
  .ant-picker-time-panel-column {
    @include custom-scroll-styling;
  }
}

.ant-drawer {
  &.ant-drawer-right {
    .ant-drawer-header {
      margin-left: 24px;

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        margin-left: 0;
      }

      .ant-drawer-header-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }

    .ant-drawer-content {
      background: linear-gradient(
        90deg,
        rgba(var(--grayscaleColorRGB-1), 0) 10%,
        rgba(var(--grayscaleColorRGB-1), 0.2) 50%,
        var(--grayscaleColor-1)
      );

      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        background: var(--grayscaleColor-1);
      }
    }
  }

  &.ant-drawer-left {
    .ant-drawer-content {
      background: linear-gradient(
        270deg,
        rgba(var(--grayscaleColorRGB-1), 0) 10%,
        rgba(var(--grayscaleColorRGB-1), 0.2) 50%,
        var(--grayscaleColor-1)
      );
    }
  }

  .ant-drawer-header {
    backdrop-filter: blur(16px);
    border-bottom: none;

    .ant-drawer-title {
      color: var(--neutralColor-6);
      font-size: 20px;
      font-weight: 700;
      line-height: 48px;
    }
  }
}

.ant-modal .ant-modal-content {
  font-family: var(--fontFamily);

  .ant-modal-confirm-body {
    .ant-modal-confirm-content,
    .ant-modal-confirm-title {
      max-width: calc(100% - 1.5rem);
    }
  }
}

.ant-drawer-mask,
.ant-modal-mask {
  background: rgba(247, 248, 255, 0.4) !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
  z-index: 10000 !important;
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;

  .ant-picker-panel {
    max-width: 450px;
  }

  .ant-picker-datetime-panel {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;

    .ant-picker-date-panel {
      width: calc(100% - 100px);
    }

    .ant-picker-time-panel-column {
      width: auto;
    }

    .ant-picker-time-panel-column {
      .ant-picker-time-panel-cell-inner {
        padding: 0 12px;
      }
    }
  }
}

.collapse-outer {
  border: none !important;

  &.ant-collapse.ant-collapse {
    padding-right: 0;
  }

  .collapse-panel-header {
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin-right: calc(1.375rem - 8px);
    }
  }

  > .ant-collapse-item.ant-collapse-item.ant-collapse-item {
    background: var(--grayscaleColor-1);
    border: 1px solid var(--grayscaleColor-5);
    box-sizing: border-box;
    border-radius: var(--borderRadius-l);
    margin-bottom: 8px;
    overflow: hidden;

    > .ant-collapse-header {
      padding: 0.375rem 1.375rem;
      align-items: center;
      min-height: 2.625rem;
      font-weight: 600;
      font-size: 0.9375rem;
      color: var(--neutralColor-6);
      background: var(--primaryColor-2);
      position: relative;
      line-height: 1.5rem;
      border-radius: var(--borderRadius-l);

      &:focus-visible {
        background-color: rgba(var(--primaryColorRGB-2), 0.5);
        outline: none;
      }

      .ant-collapse-arrow {
        transform: none;
        top: 50%;

        svg {
          transform: rotate(-90deg);
        }
      }
    }

    .ant-collapse-content-box.ant-collapse-content-box {
      padding: 2px 16px 32px;
    }

    .col-6 {
      padding-right: 20px;
      padding-left: 20px;
    }

    .FileFieldOuter {
      margin-top: 15px;
    }

    .PropertyFieldInlineEditorOuter {
      margin-top: 15px !important;

      &.available-expanded-view {
        height: calc(100% - 15px);
      }
    }
  }

  .ant-collapse-content {
    border-top: 1px solid var(--grayscaleColor-5);
    background: transparent;
  }
}

.ant-select-styled-outer {
  .ant-select {
    .ant-select-clear {
      right: 9px;
    }

    .ant-select-selector.ant-select-selector {
      min-height: 38px;
      border-radius: var(--borderRadius-l);
      padding: 0 26px 0 15px;

      .ant-select-selection-placeholder {
        position: absolute;
        top: 50%;
        right: 11px;
        left: 1rem;
        transform: translateY(-50%);
        transition: all 0.3s;
        padding-left: 0;
        display: flex;
        align-items: center;
      }

      .ant-select-selection-search-input {
        display: block;
        height: 100%;
      }

      .ant-select-selection-overflow-item-suffix {
        display: flex;
      }

      .ant-select-selection-overflow-item-rest {
        .ant-select-selection-item-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .icon {
            font-size: 16px;
            margin-right: 4px;
          }

          .text {
            font-size: 14px;
            font-weight: 500;
            margin-right: 8px;
          }

          .clear-button {
            border: 0;
            border-radius: 50%;
            outline: none;
            padding: 0;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--grayscaleColor-1);
            color: var(--errorColor-5);
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
              background-color: var(--errorColor-5);
              color: var(--grayscaleColor-1);
            }
          }
        }
      }

      .ant-select-selection-overflow-item {
        align-self: auto;

        .ant-select-selection-search-input {
          height: 100%;
        }
      }

      .ant-select-selection-item {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
      }
    }

    &.ant-select-multiple {
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          padding-left: 0;
        }
      }

      .ant-select-selector.ant-select-selector {
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          padding: 4px 8px;
          background: rgba(var(--primaryColorRGB-6), 0.7);
          border: 1px solid var(--primaryColor-5);
          border-radius: 4px;
          color: var(--grayscaleColor-1) !important;
        }

        .ant-select-selection-item-remove {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7.5' transform='rotate(-90 8 8)' fill='white' stroke='%23F24848'/%3E%3Ccircle cx='8' cy='8' r='7.5' transform='rotate(-90 8 8)' fill='%23F24848' fill-opacity='0.5' stroke='%23F24848'/%3E%3Cpath d='M9.92908 5.04302L8.0006 6.97149L6.07213 5.04302C5.85922 4.83011 5.51364 4.83011 5.30073 5.04302L5.04362 5.30013C4.83072 5.51304 4.83072 5.85862 5.04362 6.07152L6.9721 8L5.04362 9.92848C4.83072 10.1414 4.83072 10.487 5.04362 10.6999L5.30073 10.957C5.51364 11.1699 5.85922 11.1699 6.07213 10.957L8.0006 9.02851L9.92908 10.957C10.142 11.1699 10.4876 11.1699 10.7005 10.957L10.9576 10.6999C11.1705 10.487 11.1705 10.1414 10.9576 9.92848L9.02911 8L10.9576 6.07152C11.1705 5.85862 11.1705 5.51304 10.9576 5.30013L10.7005 5.04302C10.4876 4.83011 10.142 4.83011 9.92908 5.04302Z' fill='%23fff'/%3E%3C/svg%3E");

          span {
            display: none;
          }
        }
      }
    }
  }
}

.ant-dropdown-menu {
  .ant-dropdown-menu-item:not([class$='-disabled']).ant-dropdown-menu-item-danger {
    color: var(--errorColor-5);

    &:hover {
      svg.ant-dropdown-menu-item-icon {
        color: var(--grayscaleColor-1) !important;
      }

      background: var(--errorColor-5);
      color: var(--grayscaleColor-1);
    }
  }
}

.ant-tabs {
  font-family: var(--fontFamily);
}

.tabs-with-styled-more-btn {
  .ant-tabs-nav-more.ant-tabs-nav-more {
    padding: 0;
  }

  .ant-tabs-nav-operations {
    align-items: center;
    padding: 0 1rem;
  }

  .ant-tabs-nav-list {
    .ant-tabs-ink-bar {
      background: var(--primaryColor-6);
    }

    .ant-tabs-tab {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 1rem;
    }
  }
}

.ant-select-tree {
  .ant-select-tree-list-holder {
    padding: 7px;
  }
}

.ant-btn {
  &.ant-dropdown-trigger {
    .anticon {
      vertical-align: 0;
    }
  }
}

.ant-select-transparent {
  max-width: min(300px, 100%);

  &.ant-select.ant-select:not(.ant-select-disabled) {
    .ant-select-selection-item {
      position: relative;
    }
  }

  .ant-select.ant-select:not(.ant-select-disabled) .ant-select-selector,
  .ant-select-selection-item {
    padding-top: 1px;
    color: var(--neutralColor-6) !important;
    font-size: 14px;
  }

  .ant-select.ant-select {
    .ant-select-selector {
      background: transparent;
      border: none;
      border-radius: var(--borderRadius-l);
      padding-right: 0.5rem;
      padding-left: 1rem;
    }

    .ant-select-arrow {
      svg {
        width: 14px;
        height: 14px;
        color: var(--neutralColor-6) !important;
      }
    }
  }

  .ant-select.ant-select-open,
  .ant-select.ant-select:hover:not(.ant-select-disabled) {
    .ant-select-selector {
      color: var(--neutralColor-6);
      background: var(--primaryColor-2);
    }
  }
}
