.label {
  padding: 0 0.5rem 0 0.25rem;

  font-size: 14px;
  line-height: 1rem;
  color: var(--neutralColor-6);
  min-height: 40px;

  :global {
    .required-flag svg {
      font-size: 0.45rem;
      opacity: 0.6;
    }

    .icon {
      svg {
        width: 16px;
        height: auto;
      }
    }
  }
}

.additionalIcon {
  min-width: 14px;
  font-size: 14px;
}
