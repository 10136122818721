.editor {
  position: relative;

  :global {
    .link-input,
    .text-input {
      position: relative;
      z-index: 1;

      & > div {
        padding: 0 !important;
      }

      &:hover {
        z-index: 2;
      }

      input {
        background: transparent;
        border: none;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        color: var(--neutralColor-6);

        &:disabled {
          background: var(--grayscaleColor-3);
          color: var(--grayscaleColor-7);
        }
      }
    }

    .text-input {
      border-radius: var(--borderRadius-l) var(--borderRadius-l) 0 0;
    }

    .link-input {
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      border-radius: 0 0 var(--borderRadius-l) var(--borderRadius-l);
    }
  }

  &:global {
    &.inline {
      .link-input {
        position: relative;
        top: 0;
        margin-top: -1px;
      }
    }

    &.disabled {
      .text-input,
      .link-input {
        background: var(--grayscaleColor-3);
        border-color: var(--grayscaleColor-5);
      }
    }

    &.invalid {
      .text-input,
      .link-input {
        border-color: var(--primaryColor-6);
      }
    }
  }
}
