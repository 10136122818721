.wrapper {
  display: flex;

  :global {
    .separate-buttons {
      .action-button:not(.ant-tooltip-disabled-compatible-wrapper) {
        background: transparent;
        border: none;
        border-radius: var(--borderRadius-circle);
        padding: 10px;
        aspect-ratio: 1;

        &:hover,
        &:active,
        &:focus {
          background: var(--primaryColor-2);
          color: inherit !important;
        }
      }
      .action-button.danger {
        background: var(--errorColor-5);
        border: 1px solid var(--errorColor-5);
        border-radius: var(--borderRadius-circle);
        color: var(--grayscaleColor-1);
        width: 40px;
        height: 40px;
        padding: 0.75rem;

        &:hover,
        &:active {
          background: var(--errorColor-4);
          border-color: var(--errorColor-4);
          color: var(--grayscaleColor-1) !important;
        }
      }
    }
  }
}
