.wrapper {
  :global {
    .input-label {
      margin-right: 28px;
      line-height: 1.8rem;
    }

    .remove-btn {
      cursor: pointer;
      font-size: 1.5rem;
      color: var(--grayscaleColor-6);

      &:hover {
        color: var(--errorColor-5);
      }
    }
  }
}
