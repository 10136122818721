@import '../../../../variables';

.button {
  display: none !important;
  background-color: transparent;
  border: none;
  padding: 0;
  min-height: unset;
  overflow: unset;
  box-shadow: none;
  z-index: 1;
  min-width: 32px;

  &:global {
    &.isActiveFilter,
    &.open {
      display: flex !important;

      svg {
        color: var(--primaryColor-6);
      }
    }
  }
}

.wrapper {
  width: 400px;

  @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
    width: 300px;
  }

  :global {
    .clear-button {
      height: 1rem;
      line-height: 1rem;
    }
  }
}

.alert {
  max-width: 400px;
}

.filterComponent {
  flex-direction: column !important;
  gap: 0.5rem !important;

  &:global {
    & > div {
      width: 100%;
      margin: 0 !important;
    }
  }
}
