@import '../../../../../variables';

.task {
  background: var(--grayscaleColor-1);
  width: 100%;
  border-radius: var(--borderRadius-l);

  &:not(:first-child) {
    margin-top: 8px;
  }

  :global {
    .ant-collapse.ant-collapse {
      padding: 0;
      border: 0;
      border-radius: var(--SECTION__borderRadius);

      .ant-collapse-item > .ant-collapse-header {
        padding: 10px 16px;
        min-height: 74px;
        display: flex;
        align-items: center;
        cursor: default;
      }
    }

    .ant-collapse-item-disabled > .ant-collapse-header {
      cursor: default;
    }

    .ant-collapse-item {
      &.ant-collapse-item-active {
        :local {
          .taskArrow {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    .ant-collapse-header-text {
      width: 100%;
      cursor: default !important;
    }

    .ant-collapse-content {
      &.ant-collapse-content-active {
        border-top: 1px solid var(--grayscaleColor-5);
      }
    }
  }
}

.taskTitleWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;

  :global {
    .task-metadata {
      margin-top: 1rem;
      width: 160px;
      flex-shrink: 0;

      @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
        width: 160px;

        margin-left: 24px;
        margin-top: 0;
      }
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    flex-wrap: nowrap;
  }
}

.taskArrow {
  background-color: transparent;
  border: 1px solid var(--primaryColor-6);
  min-width: 40px;
  max-width: 40px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--primaryColor-6);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: var(--primaryColor-6);
    border-color: var(--primaryColor-6);
    color: var(--grayscaleColor-1);
  }

  &:active {
    background-color: var(--primaryColor-7);
    border-color: var(--primaryColor-7);
    color: var(--grayscaleColor-1);
  }

  svg {
    transition: transform 0.3s ease;
  }
}

.taskTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: var(--neutralColor-6);
  margin: 0 auto 0 16px;
  overflow: hidden;
  width: calc(
    100% - 40px - 16px
  ); // 40px - arrow block; 16px - margin right between arrow block and title

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    width: auto;
  }

  &__notCollapsible {
    width: calc(100% - 16px); // 16px - margin right between arrow block and title
    margin-left: 0;
  }
}

.assigneeDropdown {
  background: var(--grayscaleColor-1);
  border: 0;
  border-radius: var(--borderRadius-l);
  height: 40px;
  margin-right: 16px;
  margin-top: auto;
  max-width: 200px;
  :global {
    .ant-btn {
      border-radius: var(--borderRadius-l);
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    max-width: 100%;
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    margin-top: 0;
  }

  :global {
    button {
      height: 100%;
      border: 0;
      color: var(--neutralColor-4) !important;
      transition: color 0.3s ease, border-color 0.3s ease;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      &:disabled,
      &:hover {
        border: 1px solid var(--grayscaleColor-5);
        background-color: transparent;
      }

      &.main {
        &:disabled {
          &:hover {
            + .menu {
              border-left-color: transparent;
            }
          }
          + .menu {
            border-color: var(--grayscaleColor-5);

            &:before {
              background-color: var(--grayscaleColor-5);
            }
          }
        }
      }
    }

    .main:not(:disabled) {
      border: 1px solid var(--successColor-6);
      max-width: calc(100% - 40px);

      @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        max-width: 100%;
      }

      &:hover {
        border-color: var(--primaryColor-5);
        color: var(--primaryColor-5);

        & + button {
          &:before {
            background-color: transparent;
          }
        }
      }
    }

    button.menu {
      border: 1px solid var(--successColor-6);
      border-left-color: transparent !important;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      position: relative;

      &:hover {
        border-color: var(--primaryColor-5);
        color: var(--primaryColor-5);

        &:before {
          background-color: transparent;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 30px;
        background-color: var(--grayscaleColor-5);
        transition: background-color 0.3s ease;
      }
    }
  }
}

.assigneeInlineEditor {
  max-width: 250px;
  margin-right: 16px;
  margin-top: auto;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    margin-top: 0;
  }

  :global {
    .AssigneeSearchComponentOuter {
      padding: 0 !important;
    }

    .ant-select-selector,
    .ant-select-selection-search-input {
      height: 40px !important;
    }

    .ant-select-selector {
      border-radius: var(--borderRadius-l) !important;
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
}

.markDoneBtn {
  background-color: var(--successColor-6) !important;
  padding: 12px 16px !important;
  border: 1px solid var(--successColor-6) !important;
  border-radius: var(--borderRadius-l) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  white-space: nowrap;
  min-width: fit-content;
  margin-top: 16px !important;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    margin-top: 0 !important;
  }

  &:hover {
    background-color: var(--successColor-8) !important;
    border-color: var(--successColor-8) !important;
  }
}

.disabledMarkDone {
  &:disabled {
    background-color: var(--grayscaleColor-3) !important;
    color: var(--grayscaleColor-6) !important;
    border: 1px solid var(--grayscaleColor-3) !important;
  }
}

.waitingBtn {
  color: var(--warningColor-6);
  border-color: var(--warningColor-6);
}

.taskContentTopBlock {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;

  color: var(--neutralColor-9);

  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    flex-wrap: nowrap;
  }

  :global {
    .task-description {
      width: 100%;
      display: flex;
      align-items: flex-start;

      .task-description-text {
        max-width: 100%;
        max-height: 300px;
        overflow: auto;
      }

      .FieldWrapperOuterSimple {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
}

.taskContentBottomBlock {
  font-weight: 600;
  width: 100%;
  margin-top: 0.5rem;
}
