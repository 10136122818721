@import '../../../../../variables';

.wrapper {
  :global {
    .ExpandedViewWrapper {
      &.prev-field-closed .ExpandedView {
        border-top-left-radius: var(--borderRadius-l);
      }
      @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
        order: unset !important;
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
        .ExpandedView {
          margin-top: 13px !important;
        }
      }
    }
  }
}
