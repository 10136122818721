.wrapper {
  :global {
    .inline-editor:hover {
      .FieldWrapperOuter:not(.disabled) {
        background-color: var(--grayscaleColor-1) !important;
      }
    }
    .ItemMetaSelector {
      label {
        font-weight: normal;
        padding-bottom: 0;
      }

      .ant-select .ant-select-selector {
        background: var(--grayscaleColor-1);
        border: 1px solid var(--grayscaleColor-5);
        border-radius: var(--borderRadius-l);
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--neutralColor-4);
        min-height: 40px;
        cursor: text;
        transition: border-color 0.3s ease;
      }
    }

    .FieldLabelWrapper {
      width: 100%;
    }

    .ant-collapse.ant-collapse {
      padding: 0;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-bottom: 0;
      padding-top: 0;
      margin-top: 1rem;
      color: var(--primaryColor-6);
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .label-text {
      font-weight: normal;
    }
  }
}
