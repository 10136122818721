.header {
  padding-top: 0 !important;
  font-size: 1.2rem;

  :global {
    .actions {
      margin-top: 1.7rem;
    }

    .subject-label {
      font-size: 0.9rem;
      padding-bottom: 0.3rem;
      display: block;
      color: var(--neutralColor-6);
    }

    .DropdownButton {
      height: 40px;
    }
  }
}

.subjectCounter {
  width: 75px;
  font-size: 14px;
}

.iconButton {
  height: 40px;
  font-size: 24px;

  & > span {
    height: 100%;
  }
}

.infoIcon {
  margin-left: 0.3rem;
  color: var(--grayscaleColor-7);
}

.textareaSettings textarea {
  border-radius: var(--borderRadius-l);
}
