@import '../../../../../variables';

.statusWrapper {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grayscaleColor-1);
  border: 1px solid;
  border-radius: var(--borderRadius-lg);
  margin-right: 8px;
  width: 56px;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    flex-shrink: 0;
    width: auto;
  }

  &:global {
    &.SUCCEEDED {
      color: var(--grayscaleColor-1);
      background-color: var(--successColor-6);
      border-color: var(--successColor-6);
    }

    &.RUNNING {
      color: var(--primaryColor-6);
      border-color: var(--primaryColor-6);
    }

    &.FAILED {
      color: var(--grayscaleColor-1);
      background-color: var(--errorColor-6);
      border-color: var(--errorColor-5);
    }

    &.CANCELLED {
      color: var(--grayscaleColor-1);
      background-color: var(--warningColor-6);
      border-color: var(--warningColor-6);
    }
  }
}

.text {
  font-family: var(--fontFamily);
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 10px;
  display: none;

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    display: block;
  }
}
