.actionsContainer {
  background: var(--grayscaleColor-1);
  border: 1px solid var(--grayscaleColor-5);
  border-radius: 8px;
  padding: 8px 12px;

  :global {
    .actionButton.actionButton.QuickAddItemButton {
      color: var(--grayscaleColor-1) !important;
    }

    .delete-btn {
      height: 40px;
      @media (max-width: 767px) {
        margin-bottom: 0 !important;
      }
    }

    .SettingsButton {
      width: 40px;
      height: 40px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .QuickAddItemButton {
      svg {
        width: 22px;
        height: 22px;
        margin-right: 3px;
      }
    }

    .view-selector {
      height: 40px;
      width: 150px;

      .ant-select-selection-item {
        font-weight: 500;
      }
    }

    .ActionsButtonOuter {
      height: 40px;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }

      .ViewActionsButtonEditViewButtonTextOuter {
        svg {
          width: 25px;
          height: 25px;
        }
      }

      & > span {
        display: block;
        height: 100%;

        & > button {
          height: 100%;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: var(--neutralColor-6) !important;
          background: var(--grayscaleColor-2);

          svg {
            fill: var(--neutralColor-6) !important;
            transition: all 0.3s;
          }

          &:hover {
            background: var(--grayscaleColor-2);
            border-color: var(--primaryColor-5) !important;
            color: var(--primaryColor-5) !important;

            + button {
              border-left-color: var(--primaryColor-5) !important;
            }

            svg {
              fill: var(--primaryColor-5) !important;
            }
          }
        }
      }
    }
  }
}
.tableContainer {
  border: 1px solid var(--grayscaleColor-5);

  :global {
    & {
      font-size: 0.85rem;
      border-radius: 1rem;
      min-height: 300px;

      .rt-table .rt-tr {
        align-items: stretch;

        .rt-td:nth-child(3),
        .rt-td:nth-child(4) {
          .editor-actions-block {
            right: -60px;
            left: auto;
          }
        }
      }
    }
    .table-no-data {
      background: var(--grayscaleColor-1);
      box-shadow: 0 1px 3px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
      color: var(--grayscaleColor-7);
    }

    .ReactTable .rt-tbody .rt-td.select,
    .ReactTable .rt-thead .rt-th.select {
      position: sticky !important;
      left: 0;
      z-index: 2;
      background-color: var(--grayscaleColor-1);
    }

    .ReactTable .rt-tbody .rt-td.open-item,
    .ReactTable .rt-thead .rt-th.open-item {
      position: sticky !important;
      left: 45px;
      z-index: 2;
      background-color: var(--grayscaleColor-1);
    }

    .ReactTable .rt-tbody .rt-td.select > div,
    .ReactTable .rt-tbody .rt-td.open-item > a {
      height: 45px;
      width: 45px;
    }

    .ReactTable.isHorizontallyScrolled .rt-tbody .rt-td.row-shadow {
      box-shadow: 11px 5px 15px -2px rgba(var(--grayscaleColorRGB-13), 0.15);
    }

    .ReactTable.isVerticallyScrolled .rt-thead.-header {
      box-shadow: 0 2px 15px 0 rgba(var(--grayscaleColorRGB-13), 0.15);
    }

    .sticky-table-header .rt-thead.-header {
      position: sticky;
      top: 0;
    }
  }
}

.groupingDropdownOverlay {
  max-height: 275px;
  min-width: auto !important;

  :global {
    .ant-dropdown-menu-item-group-title {
      padding: 0 !important;
    }

    .ant-dropdown-menu-item-active {
      &.search-item {
        background-color: transparent !important;
      }
    }

    .ant-dropdown-menu-item-divider {
      margin: 0 !important;
    }

    .ungroup {
      bottom: -1px;
    }
  }
}

:global {
  .ant-dropdown-menu.rowMenu {
    width: 200px;
    padding: 0 !important;

    .ant-dropdown-menu-item {
      padding-left: 1rem;
      svg {
        margin-right: 0.5rem;
      }
    }

    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }
  }
}
