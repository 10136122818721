.fieldWrapper {
  :global {
    .FieldLabelWrapper {
      width: 35%;
      flex-shrink: 0;

      .FieldLabelOuter {
        max-width: 100%;
      }
    }

    .property-preview {
      width: 65%;
      flex-shrink: 0;
    }
  }
}
