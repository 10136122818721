.container {
  color: var(--neutralColor-6);
  font-weight: 500;
  font-size: 0.75rem;
}

.notAssigned {
  color: var(--grayscaleColor-7);
  font-size: 0.75rem;
}
