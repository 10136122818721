.contentRow {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 80em) {
    display: block;
  }

  :global {
    .cp-i-xl-25 {
      flex: 1 1 min(25%, 400px) !important;
      max-width: 400px !important;
      transition: all 0.2s;
    }

    .cp-i-xl-75 {
      flex: 1 1 max(75%, calc(100% - 400px)) !important;
      max-width: max(75%, calc(100% - 400px)) !important;
      transition: all 0.2s;
      @media screen and (max-width: 80em) {
        max-width: 100% !important;
        width: 100% !important;
        flex: 1 1 100% !important;
      }
    }
  }

  &:global {
    &.collapsed {
      .cp-i-xl-25 {
        width: var(--leftDrawerWidth) !important;
        flex: 1 1 var(--leftDrawerWidth) !important;
        max-width: var(--leftDrawerWidth) !important;
      }

      .cp-i-xl-75 {
        flex: 1 1 calc(100% - var(--leftDrawerWidth)) !important;
        max-width: calc(100% - var(--leftDrawerWidth)) !important;
        @media screen and (max-width: 80em) {
          max-width: 100% !important;
          width: 100% !important;
          flex: 1 1 100% !important;
        }
      }
    }
  }
}
