.wrapper {
  :global {
    position: relative;

    .ant-switch {
      margin: 10px 0;
    }

    .btn {
      cursor: pointer;
      position: absolute;
      z-index: 9;
      height: 85%;
      max-height: 38px;
      padding: 0 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color 0.3s ease, background-color 0.3s ease;
      background-color: transparent;

      &:hover {
        background-color: var(--primaryColor-5);
        color: var(--grayscaleColor-1);
      }
    }

    .btn.disabled {
      cursor: default;
    }

    .computed-expression {
      min-height: 40px;
      max-height: 40px;
      display: flex;
      align-items: center;
      border-style: dashed !important;
      padding-right: 30px !important;

      &__inner {
        font-size: 0.9rem;
        line-height: 1.6;
        cursor: pointer;
      }
    }

    .computed-expression.disabled {
      background-color: var(--grayscaleColor-3);
      color: var(--grayscaleColor-6);
      border-color: var(--grayscaleColor-5) !important;

      .computed-expression__inner {
        cursor: default;
      }
    }

    .computed-component-outer {
      display: flex;
      align-items: center;

      & > div {
        width: 100%;
      }

      input,
      textarea {
        font-weight: 500;
        padding-right: 35px;
        border-color: var(--grayscaleColor-5);
        height: 40px;

        &:disabled {
          background-color: var(--grayscaleColor-3);
          color: var(--grayscaleColor-6);
        }
      }

      input {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .ant-select.ant-select {
      &:not(.ant-select-disabled) {
        &.ant-select-single {
          .ant-select-selection-item {
            padding-right: 30px;
          }

          .ant-select-selection-search {
            inset-inline-end: 4rem;
          }
        }

        &.ant-select-multiple {
          .ant-select-selection-overflow {
            max-width: calc(100% - 30px);
          }
        }
      }

      .ant-select-arrow,
      .ant-select-clear {
        right: 45px !important;
      }

      .ant-select-selector {
        .ant-select-selection-placeholder {
          padding-right: 3.3rem !important;
        }
      }
    }

    .ant-picker.ant-picker {
      height: 40px;
      padding-right: 45px;
      margin-bottom: 0 !important;
    }

    .ComputedButton {
      top: 0;
      bottom: 0;
      margin: auto 0;
      right: 3px !important;
      border-radius: var(--borderRadius-l);
      aspect-ratio: 1;
    }
  }
}
