@import '../../../../../variables';

.sideContentWrapper {
  display: none;
  transition: width 0.3s ease;
  position: relative;
  box-shadow: 0 0 0 0 rgba(var(--grayscaleColorRGB-5), 0.33);

  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    display: block;
  }

  &__hidden {
    background: var(--grayscaleColor-1);
    border: 1px solid var(--grayscaleColor-5);
    border-radius: var(--SECTION__borderRadius);

    @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
      width: 56px;
    }

    :global {
      .ant-tabs {
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }
  }

  :global {
    .ant-tabs-content-holder {
      overflow: hidden;
      border-radius: var(--SECTION__borderRadius);
      background-color: var(--grayscaleColor-1);
      border: 1px solid var(--grayscaleColor-5);
      margin-top: -1px;
    }
    .ResizableOuter {
      height: fit-content;
    }
  }
}

.sideContent {
  :global {
    .tabsPanel {
      &.ant-tabs {
        transition: visibility 0.01s ease, opacity 0.01s ease;
        transition-delay: 0.3s;
        visibility: visible;
        opacity: 1;

        > .ant-tabs-nav {
          width: 100%;
          padding: 0 4px 0 16px;

          @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            width: calc(100% - 55px);
            padding: 0;
            margin: 0 0 0 auto;
          }

          .ant-tabs-nav-wrap {
            padding-left: 10px;

            .ant-tabs-nav-list {
              .ant-tabs-tab-btn {
                transition: color 0.3s ease;

                svg {
                  transition: color 0.3s ease;
                }
              }

              .ant-tabs-tab:hover {
                color: var(--primaryColor-6);
              }
            }
          }
        }
      }
    }
  }
}

.sideContentToggleBtn {
  position: absolute;
  left: 0;
  top: 0;
  margin: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: none;
  transition: color 0.3s ease, transform 0.3s ease;
  padding: 0.25rem;
  font-size: 24px;
  transform: rotate(0deg);

  &:hover {
    color: var(--primaryColor-5);
  }

  &__opened {
    transform: rotate(-180deg);
    margin: 9px;
  }
}

.sideTabsButtons {
  position: absolute;
  left: 0;
  top: 54px;
  width: 54px;
  height: calc(100% - 54px);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  transition-delay: 0s;
  opacity: 0;
  visibility: hidden;

  &__opened {
    transition-delay: 0.3s;
    visibility: visible;
    opacity: 1;
  }
}

.sideTabsButtonsItem {
  width: 24px;
  height: 24px;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  font-size: 24px;

  :global {
    .ant-badge {
      transition: color 0.3s ease;

      &:hover {
        color: var(--primaryColor-5);
      }
    }
  }

  svg {
    font-size: 24px;
  }
}

.dataHistoryContainer {
  padding: 24px 16px;
  overflow: auto;
  max-height: calc(100vh - 320px);
  min-height: 600px;
}
