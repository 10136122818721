.editor {
  :global {
    .country-selector {
      position: relative;
      display: flex;
      align-items: center;
    }

    .ant-select.ant-select {
      .ant-select-selector {
        border: none;
        height: 14px;
        width: 42px;
        min-height: 0;
        padding: 0;
      }

      .ant-select-arrow {
        right: 5px;
        margin-top: 0;
        transform: translateY(-50%);
      }

      .ant-select-selection-search {
        display: none;
      }

      .ant-select-selection-item {
        display: none !important;
      }
    }

    .PhoneInput {
      padding: 0;
      width: 100%;

      .PhoneInputCountry {
        margin-right: 0;
        padding-right: 0.5rem;
      }

      .PhoneInputCountrySelect {
        opacity: 0;
      }

      .PhoneInputInput {
        border: none;
        background: transparent;
        outline: none;

        &:focus-visible {
          outline: none;
        }
      }

      .PhoneInputCountryIcon {
        position: absolute;
        z-index: 1;
        pointer-events: none;

        &--border {
          box-shadow: none;
        }

        svg {
          display: block;
        }
      }
    }
  }

  &:global {
    &.invalid {
      .error-msg {
        position: absolute;
        background: rgba(var(--grayscaleColorRGB-1), 0.9);
        z-index: 10;
        margin: 0;
        color: var(--warningColor-6);
      }
    }

    &.disabled {
      .PhoneInputCountry,
      .PhoneInputInput {
        background: var(--grayscaleColor-3);
      }
    }
  }
}
