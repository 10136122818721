@import '../../../../../../variables';

.actions {
  position: absolute;
  top: 0;
  right: 0;
  justify-content: flex-end;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem 0 0;

  :global {
    .DropdownButton {
      button.menu,
      button.main {
        min-height: 33px !important;
      }
    }
  }

  > button,
  a {
    height: 33px;
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'md')) {
    width: 100%;
  }
}
