@import 'hyperplan/dist/hyperplan.min.css';
@import 'normalize-css/normalize.css';
@import 'react-quill/dist/quill.snow.css';
@import 'react-table';
@import './variables';
@import './mixins';
@import './antd';
@import './tag-input';

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-60 {
  flex-basis: 60% !important;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.flex-truncate {
  flex: 1;
  min-width: 0;
}

.col-resize {
  cursor: col-resize;
}

button.action-button,
a.action-button {
  overflow: initial !important;
  background: var(--grayscaleColor-2);
  border: 1px solid var(--grayscaleColor-5);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--grayscaleColor-2);
  }

  &.danger {
    background: var(--errorColor-5);
    border: 1px solid var(--errorColor-5);
    border-radius: var(--borderRadius-circle);
    color: var(--grayscaleColor-1);
    width: 40px;
    height: 40px;
    padding: 0.75rem;

    &:hover,
    &:active {
      background: var(--errorColor-4);
      border-color: var(--errorColor-4);
      color: var(--grayscaleColor-1);
    }
  }
}

.menuItemCss {
  font-family: var(--fontFamily);
  font-size: 0.9rem;
  height: 2.25rem;
  min-height: 2.25rem;
  transition: none !important;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  color: var(--neutralColor-6);

  &[aria-haspopup='true'] {
    padding-right: 30px;
  }
}

.fieldCss {
  cursor: text;
  background-color: var(--grayscaleColor-1);
  border-width: 1px;
  border-style: solid;
  border-color: var(--grayscaleColor-5);
  border-radius: 2px;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--grayscaleColor-9);
  display: inline-block;
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  width: 100%;
  outline: none;
  font-size: 0.9rem;
  line-height: 1.6;

  &:focus {
    border-color: var(--primaryColor-6);
  }

  &:disabled {
    background: var(--grayscaleColor-3);
    color: var(--grayscaleColor-7);
  }

  &::placeholder {
    color: var(--grayscaleColor-6);
    font-weight: 400;
  }
}

html,
body#hyper-body {
  min-height: 100vh;
  font-family: var(--fontFamily);
  font-size: 16px;
  color: var(--neutralColor-6);
  background: var(--BODY__backgroundColor);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: static;

  & > #productstashSelector {
    display: none !important;
  }
}

label {
  font-size: 0.9rem;
}

div > img {
  display: block;
}

.link,
a:link,
a:visited,
a:hover {
  cursor: pointer;
  text-decoration: none;

  &:not(.ant-btn) {
    color: var(--primaryColor-6);
  }

  &.active {
    border-bottom: 2px solid var(--primaryColor-6);
    font-weight: 600;
  }
}

.link:focus,
a:focus {
  outline-style: none !important;
  text-decoration: none !important;
}

/* removes controls from inside number inputs */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='text']::-ms-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select::-ms-expand {
  display: none;
}

/**
  *  initReactModalStyles
  */
@keyframes slideOpenModal {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideCloseModal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes overlayLaunchModal {
  0%,
  33% {
    opacity: 1;
  }
  33%,
  100% {
    opacity: 1;
  }
}

@keyframes overlayDropModal {
  67% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes launchModal {
  0%,
  33% {
    opacity: 0.1;
    transform: translate(-51%, 150%);
  }
  100% {
    opacity: 1;
    transform: translate(-51%, -51%);
  }
}

@keyframes dropModal {
  0% {
    opacity: 1;
    transform: translate(-51%, -51%);
  }
  100% {
    opacity: 0.1;
    transform: translate(-51%, 150%);
  }
}

@keyframes raiseModal {
  0% {
    opacity: 1;
    transform: translate(-51%, -51%);
  }
  100% {
    opacity: 0.1;
    transform: translate(-51%, -300%);
  }
}

button svg {
  vertical-align: middle;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  backface-visibility: hidden;
  width: auto !important;
}

/* keeps the z-index the same as the old mobile overlay */
.ReactModal__Overlay {
  z-index: 1001 !important;
  position: fixed;
  inset: 0px;
}
.ReactModalPortal_slide .ReactModal__Content {
  &--after-open {
    animation: slideOpenModal 200ms linear;
  }

  &--before-close {
    animation: slideCloseModal 250ms linear forwards !important;
  }
}

.ReactModal__Overlay:not(.overlayAnimationDisabled) {
  &.ReactModal__Overlay--after-open {
    opacity: 0;
    animation: overlayLaunchModal 550ms ease-in forwards !important;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 1;
    animation: overlayDropModal 550ms ease-out forwards !important;
  }
}

.ReactModalPortal_language .ReactModal__Content {
  &--after-open {
    animation: launchModal 550ms ease-out;
  }

  &--before-close {
    animation: dropModal 350ms ease-in forwards !important;
  }
}

.ReactModalPortal_Remark .ReactModal__Content {
  &--after-open {
    animation: overlayLaunchModal 200ms linear;
  }

  &--before-close {
    animation: overlayDropModal 200ms linear;
  }
}

.ReactModalPortal_slide {
  .ReactModal__Content {
    background: rgba(var(--grayscaleColorRGB-1), 0.5) !important;
    backdrop-filter: blur(16px) !important;
  }
}

/**
  * initRcSliderStyles
  */
.ant-slider {
  .ant-slider-handle {
    margin-left: -12px !important;
    margin-top: -6px !important;
    width: 16px !important;
    height: 16px !important;
    border: solid 2px var(--primaryColor-4) !important;
    transition-property: transform, margin, width, height;
    transition-duration: 0.1s;
    transition-timing-function: ease;

    &:hover,
    &:active {
      width: 24px !important;
      height: 24px !important;
      border: solid 2px var(--primaryColor-4) !important;
      box-shadow: none !important;
      margin-left: -16px !important;
      margin-top: -10px !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-slider-track {
    background-color: var(--primaryColor-6) !important;
  }
}

/**
  * initAntStyles
  */
.ant-switch.ant-switch-checked {
  background-color: var(--successColor-6);

  &:hover {
    &:not(.ant-switch-disabled) {
      background-color: var(--successColor-6);
    }
  }
}

.ant-switch svg {
  vertical-align: middle;
}

.ant-dropdown-menu-item-icon {
  transition: all 0.3s;
  margin-right: 16px;
}

ul.ant-dropdown-menu {
  border: 1px solid var(--grayscaleColor-5);
  box-shadow: 0 4px 8px rgba(var(--grayscaleColorRGB-5), 0.33);
  border-radius: 6px;
  padding: 5px 0 !important;
}

.ant-dropdown-menu.ant-dropdown-menu {
  .ant-dropdown-menu-item-disabled {
    cursor: default;
  }
}

.ant-radio-group-solid.ant-radio-group-solid {
  display: flex;
  flex-wrap: nowrap;

  .ant-radio-button-wrapper {
    &:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--primaryColor-6);
      color: var(--primaryColor-6);
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: var(--primaryColor-6);
      color: var(--grayscaleColor-1);
    }
  }

  &.ant-radio-group-small {
    .ant-radio-button-wrapper {
      font-weight: 600;
    }
  }

  &.ant-radio-group-large {
    .ant-radio-button-wrapper {
      font-weight: 700;
    }
  }

  &.ant-radio-group-large,
  &.ant-radio-group-small {
    .ant-radio-button-wrapper:first-child {
      border-start-start-radius: var(--borderRadius-l);
      border-end-start-radius: var(--borderRadius-l);
    }

    .ant-radio-button-wrapper:last-child {
      border-start-end-radius: var(--borderRadius-l);
      border-end-end-radius: var(--borderRadius-l);
    }
  }
}

.ant-badge {
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
    left: 4px;
  }

  .ant-badge-count {
    background-color: var(--errorColor-5);
    padding: 0 5px;
  }
}

.info-panel {
  .ant-badge-count {
    top: 6px !important;
  }
}

.ant-collapse.ant-collapse {
  padding-right: 1rem;
  border-bottom: 1px solid var(--grayscaleColor-5);
  border-radius: 0;
  font-family: var(--fontFamily);
  background-color: inherit;

  &.ant-collapse-ghost {
    border: none;
  }

  .ant-collapse-item {
    border-bottom: 0;

    & > .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 1rem;
    }
  }
}

.ant-input.ant-input,
.ant-mention-wrapper .ant-mention-editor.ant-mention-editor {
  font-family: var(--fontFamily);
  @extend .fieldCss;
  border-color: var(--grayscaleColor-5);
  min-height: 33px;
  box-shadow: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.ant-picker-dropdown.ant-picker-dropdown {
  font-family: var(--fontFamily);

  &.ant-picker-dropdown-range {
    padding: 0;
  }
}

.ant-upload.ant-upload.ant-upload-drag {
  background: var(--grayscaleColor-1);
  transition: opacity 200ms ease;

  &.ant-upload-disabled {
    cursor: default;
    opacity: 0.7;
  }
}

.ant-select.ant-select {
  color: var(--grayscaleColor-9);
  font-weight: 500;

  &.ant-select-status-warning {
    &:not(.ant-select-disabled) {
      .ant-select-selector {
        border-color: var(--warningColor-6);
      }
    }
  }

  &.ant-select-focused .ant-select-selector.ant-select-selector {
    border-color: var(--primaryColor-6);
    box-shadow: none !important;
  }

  .ant-select-selector {
    padding: 0 1rem;
  }

  &.ant-select-single {
    .ant-select-selector {
      cursor: pointer !important;

      input {
        cursor: pointer !important;
      }
    }
  }
}

.ant-input.ant-input,
.ant-input-number.ant-input-number {
  border-radius: var(--borderRadius-l);
  border: 1px solid var(--grayscaleColor-5);
  height: 40px;
  font-size: 14px;
  outline: none;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  &::placeholder {
    color: var(--grayscaleColor-7);
  }
  &:not(:disabled) {
    background-color: var(--grayscaleColor-1);

    &.ant-input-status-warning {
      border-color: var(--warningColor-6);
    }

    &:hover,
    &:focus {
      border-color: var(--primaryColor-6);
    }
  }

  .ant-input-number-input-wrap {
    height: 100%;

    input {
      height: 100%;
    }
  }
}

.ant-picker.ant-picker {
  border-radius: var(--borderRadius-l);
  border: 1px solid var(--grayscaleColor-5);

  &:hover,
  &.ant-picker-focused {
    border-color: var(--primaryColor-6);
  }

  .ant-picker-input {
    height: 32px;
  }
}

.ant-menu.ant-menu {
  border-right: 0;

  .ant-menu {
    padding: 5px 0 !important;
  }

  svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    @extend .menuItemCss;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    .ant-menu-title-content > a {
      color: var(--grayscaleColor-8);
    }

    &.ant-menu-item-selected {
      color: var(--primaryColor-5);

      a,
      span {
        color: var(--primaryColor-5);
      }
    }

    &:not([class$='-disabled']) {
      &:hover {
        a,
        span {
          color: var(--primaryColor-5);
        }
      }
    }
  }
}

.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item-selected {
  background: inherit;
}

.ant-menu-submenu > .ant-menu {
  margin-top: -7px;
}

.ant-menu-submenu-popup.ant-menu-submenu.ant-menu-submenu-placement-rightTop {
  padding-top: 3px;
}

.ant-menu-submenu-popup.ant-menu-submenu {
  padding-top: 10px;
  &::before {
    inset: 0;
  }

  .ant-menu.ant-menu-sub {
    border: 1px solid var(--grayscaleColor-1);
    border-radius: 6px;
    box-shadow: none;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-right: 3rem;
    margin: 0;
    width: 100%;

    a {
      color: var(--neutralColor-6);
      display: flex;
      align-items: center;
      font-size: 0.85rem;
      font-weight: 500;
    }

    &:hover {
      background-color: var(--primaryColor-5);
    }
  }
}

.ant-menu-horizontal.ant-menu-horizontal {
  .ant-menu-submenu.ant-menu-submenu,
  .ant-menu-item.ant-menu-item {
    padding: 0 12px;
  }

  .ant-menu-submenu.ant-menu-submenu::after,
  .ant-menu-item.ant-menu-item::after {
    right: 12px;
    left: 12px;
  }
}

.ant-dropdown-menu,
.ant-dropdown-menu-submenu {
  svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    @extend .menuItemCss;
  }
}

/* styling to make it uniform with the menu */
.ant-dropdown-menu-submenu-arrow {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 50%;
  right: 16px;
  width: 16px;

  > i {
    display: none !important;
  }
}

.ant-select-dropdown.ant-select-dropdown {
  max-height: 20rem;

  .ant-select-item {
    @extend .menuItemCss;

    &.ant-select-item-option-selected {
      background-color: transparent;
    }
    &.ant-select-item-option-active {
      background-color: var(--primaryColor-2);
    }

    &:not(:first-child)#NEW_CUSTOM_ENTITY {
      border-top: 1px solid var(--grayscaleColor-4);

      .ant-select-selected-icon {
        display: none;
      }
    }

    &.create-new-option {
      background-color: var(--grayscaleColor-3);
      transition: background-color 0.3s ease;
      border-top: none !important;
      min-height: 40px;

      &:hover,
      &.ant-select-item-option-active {
        background-color: var(--primaryColor-5);

        .ant-select-item-option-content {
          font-weight: 400;
          color: var(--grayscaleColor-1);
        }
      }
    }
  }
}

.ant-menu-submenu-popup {
  z-index: 1040 !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-title-disabled {
  > * {
    color: var(--grayscaleColor-8);
    cursor: not-allowed;
  }
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-block {
  margin: 0;
}

.ant-modal.ant-modal {
  top: 0;
  padding-bottom: 0;

  .ant-modal-body {
    max-height: 80vh;
  }
}

.ant-alert {
  &.ant-alert-warning {
    border: 1px solid var(--warningColor-6);

    .ant-alert-icon {
      color: var(--warningColor-6);
    }
  }

  &.ant-alert-info {
    border: 1px solid var(--primaryColor-6);

    .ant-alert-icon {
      color: var(--primaryColor-6);
    }
  }

  .ant-alert-message {
    color: var(--neutralColor-6);
    font-weight: 600;
    font-size: 16px;
  }

  .ant-alert-description {
    color: var(--neutralColor-6);
    font-weight: 500;
    font-size: 14px;
  }
}

.ant-modal-root {
  .ant-modal .ant-modal-header {
    background-color: transparent;
  }

  .ant-modal-confirm-body.ant-modal-confirm-body {
    flex-wrap: wrap;
  }

  .ant-modal-confirm-btns .ant-btn,
  .ant-modal-footer .ant-btn {
    border-radius: var(--borderRadius-l);
    font-size: 0.875rem;
    height: 2.5rem;
    border: none;
    font-weight: 600;
    box-shadow: none;

    &:focus-visible {
      outline: none !important;
    }

    &.cancel,
    &.ant-btn-default {
      background-color: transparent;
      color: var(--neutralColor-6);

      &:hover {
        background-color: var(--primaryColor-2);
      }
    }

    &.ant-btn-dangerous,
    &.ant-btn-primary.danger {
      color: var(--grayscaleColor-1) !important;
      background-color: var(--errorColor-5);
      border-color: var(--errorColor-5);

      &:hover {
        background-color: var(--errorColor-4);
        border-color: var(--errorColor-4);
      }
    }

    &.right .ant-btn-icon {
      order: 1;
      margin-inline-end: 0 !important;
      margin-inline-start: 8px;
    }
  }

  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none;
    color: var(--neutralColor-6);
    padding: 1rem;

    .ant-modal-confirm-content {
      color: var(--neutralColor-6);
    }

    .ant-modal-confirm-title,
    .ant-modal-title {
      font-size: 18px;
      color: var(--neutralColor-6);
    }
  }

  .ant-modal {
    border-radius: var(--borderRadius-l) !important;
    box-shadow: 0 2px 10px 0 rgba(var(--neutralColorRGB-7), 0.15);
    background: rgba(var(--grayscaleColorRGB-1), 0.4) !important;
    backdrop-filter: blur(16px);
  }
}

/* just the ant-modal styles again */
@media screen and (min-width: 768em/16em) {
  .ant-modal.ant-modal {
    top: 100px;
    padding-bottom: 24px;

    .ant-modal-body {
      max-height: 70vh;
    }
  }
}

.ant-modal-content {
  max-height: 100vh;
}

.ant-modal-body {
  max-height: 70vh;
  overflow: auto;
}

.ant-modal-confirm-body.ant-modal-confirm-body {
  flex-wrap: nowrap;
  align-items: flex-start;

  .ant-modal-confirm-content {
    margin: 0;
  }
}

/**
  * initReactDraftWysiwygStyles
  */
.rdw-editor-wrapper * {
  box-sizing: content-box;
}

/**
  * // Make dragging files in add cms drawer visible
  *
  * initReactSortableHocStyles
  */
body > .sortable {
  z-index: 1001;
  background: var(--grayscaleColor-1);
}

body {
  &:has(> .sortableItem) {
    user-select: none;
  }
}

/**
  * initStripoEditorStyles
  */
.esdev-app {
  // overlapping bootstrap styles
  .dropup .dropdown-toggle::after {
    display: none;
  }
  .tooltip.top .tooltip-arrow {
    margin-left: 0;
    border-color: transparent;
    border: none;
    bottom: 1px;
    &::before {
      margin-left: -3px !important;
    }
  }
  .dropdown-toggle::after {
    content: none;
  }
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  text-align: left;
  background-color: var(--grayscaleColor-1);
  border: 1px solid rgba(var(--grayscaleColorRGB-13), 0.15);
  box-shadow: 0 6px 12px rgba(var(--grayscaleColorRGB-13), 0.175);
  background-clip: padding-box;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 16px;
  text-align: left;
  background-color: var(--grayscaleColor-1);
  border: 1px solid rgba(var(--grayscaleColorRGB-13), 0.15);
  border-radius: 17px;
  box-shadow: 0 6px 12px rgba(var(--grayscaleColorRGB-13), 0.175);
  background-clip: padding-box;
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: var(--grayscaleColor-5);
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  color: var(--grayscaleColor-9);
  background-color: var(--grayscaleColor-3);
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: var(--grayscaleColor-1) !important;
  text-decoration: none;
  outline: 0;
  background-color: var(--primaryColor-6);
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: var(--grayscaleColor-7);
}

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.tooltip.in {
  opacity: 0.9;
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: var(--grayscaleColor-1);
  text-align: center;
  background-color: var(--grayscaleColor-13);
  border-radius: 17px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: var(--grayscaleColor-13);
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: var(--grayscaleColor-13);
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: var(--grayscaleColor-13);
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: var(--grayscaleColor-13);
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: var(--grayscaleColor-13);
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--grayscaleColor-13);
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--grayscaleColor-13);
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--grayscaleColor-13);
}

.ant-divider .ant-divider-inner-text {
  font-size: 0.9rem;
  font-family: var(--fontFamily);
  color: var(--neutralColor-4);
  font-weight: lighter;
}

/**
  * tooltips
  */
.statistics-tooltip {
  .ant-tooltip-arrow::before,
  .ant-tooltip-inner {
    background-color: rgba(var(--infoColorRGB-8), 0.9);
  }
}

.BreadcrumbsContainer {
  background-color: var(--BREADCRUMBS__backgroundColor);
}

.breadcrumbs.breadcrumbs {
  display: flex;
  align-items: center;
  overflow: visible;
  height: 40px;

  span {
    align-items: center;
    text-overflow: ellipsis;
    display: flex;
    overflow: hidden;

    .editor-actions-block {
      display: none;
    }
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes file-field-loader-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Styles for Kanban view based on the Virtuoso package */
.height-preserving-container {
  padding: 0 0 8px 0;

  &:empty {
    min-height: calc(var(--child-height));
    box-sizing: border-box;
  }
}

.kanbanField {
  min-height: 16px;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 17px;
}

.custom-scroll-styling {
  @include custom-scroll-styling;
}

.overscroll-behavior-contain {
  overscroll-behavior: contain;
}

.count-badge {
  border: 2px solid;
  margin-left: 0.25rem;
  padding: 3px 5px;
  border-radius: var(--borderRadius-circle);
  font-size: 0.85rem;
  position: relative;
  min-width: 26px;
}

.safe-space {
  @include safe-space();
}
.ant-dropdown .ant-dropdown-menu {
  .ant-dropdown-menu-submenu-title:hover {
    background: inherit;
  }

  .ant-dropdown-menu-title-content {
    max-width: 100%;
  }
}

.app-header-menu-popup-submenu.app-header-menu-popup-submenu {
  &.ant-menu-submenu {
    & > .ant-menu {
      border-radius: var(--borderRadius-m) !important;
      background: var(--HEADER_SUBMENU__backgroundColor);
      box-shadow: 0 2px 10px 0 rgba(17, 29, 73, 0.15);
      backdrop-filter: blur(16px);

      .ant-menu-item,
      .ant-menu-submenu-title {
        &:hover {
          border-radius: 0;
          background-color: var(--HEADER_SUBMENU_ITEM_HOVER__backgroundColor);

          .ant-menu-title-content > a,
          .ant-menu-title-content > a span {
            color: var(--HEADER_SUBMENU_ITEM_HOVER__color);
          }
        }

        .ant-menu-title-content > a {
          color: var(--HEADER_SUBMENU_ITEM__color);
        }
      }
    }
  }

  .ant-menu-submenu {
    color: var(--HEADER_SUBMENU_ITEM__color);
    background-color: transparent;
    font-weight: 500;
    font-size: 0.85rem;
    padding-right: 0;
    .ant-menu-submenu-title {
      height: 36px;
    }

    &:hover,
    &.ant-menu-submenu-active {
      background-color: var(--HEADER_SUBMENU_ITEM_HOVER__backgroundColor);
      color: var(--HEADER_SUBMENU_ITEM_HOVER__color);

      .ant-menu-title-content,
      .ant-menu-title-content > a {
        color: var(--HEADER_SUBMENU_ITEM_HOVER__color) !important;
      }
    }

    .ant-menu-submenu-title {
      padding-right: 16px;
    }

    .ant-menu-submenu-arrow {
      display: none;
    }

    .ant-menu-title-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.ant-tabs-dropdown {
  @include glass-background-effect;

  .ant-tabs-dropdown-menu {
    background-color: transparent;
  }
}
.ant-dropdown {
  @include glass-background-effect;

  overflow: hidden;

  .ant-dropdown-menu {
    background: transparent;
    border: none;

    .ant-dropdown-menu-item {
      color: var(--neutralColor-6);
    }
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-item-active.ant-dropdown-menu-item-selected {
    background: var(--primaryColor-3) !important;
    color: var(--neutralColor-6) !important;
  }
  .ant-dropdown-menu-item-active:not(.ant-dropdown-menu-item-danger) {
    background: var(--primaryColor-2) !important;
  }
}
.app-header-dropdown.app-header-dropdown {
  border-radius: var(--borderRadius-m) !important;
  background: var(--HEADER_SUBMENU__backgroundColor);
  box-shadow: 0 2px 10px 0 rgba(17, 29, 73, 0.15);
  backdrop-filter: blur(16px);
  .ant-dropdown-menu-submenu-expand-icon {
    transform: translateY(-50%);
  }

  .ant-dropdown-menu {
    background-color: var(--HEADER_SUBMENU__backgroundColor);
    border-color: var(--HEADER_SUBMENU__borderColor);

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu {
      &:hover,
      &.ant-menu-submenu-active {
        background-color: var(--HEADER_SUBMENU_ITEM_HOVER__backgroundColor);
        color: var(--HEADER_SUBMENU_ITEM_HOVER__color);

        a,
        .ant-dropdown-menu-title-content {
          color: var(--HEADER_SUBMENU_ITEM_HOVER__color);

          svg {
            color: var(--HEADER_SUBMENU_ITEM_HOVER__color);
          }
        }
      }

      a,
      .ant-dropdown-menu-title-content {
        transition: color 0.3s ease;
        color: var(--HEADER_SUBMENU_ITEM__color);
        display: flex;
        align-items: center;
        font-size: 0.85rem;
        font-weight: 500;

        svg {
          margin-right: 16px;
          transition: color 0.3s ease;
          color: var(--HEADER_SUBMENU_ITEM__color);
        }
      }

      .ant-dropdown-menu-submenu-arrow-icon {
        color: var(--HEADER_SUBMENU_ITEM__color);
      }
    }
  }
}
