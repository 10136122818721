@import '../../../../variables';

.wrapper {
  padding: 1rem 1rem 2rem;

  :global {
    .add-icon {
      @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
        margin-right: 0.3rem;
      }
    }
  }
}
