@import '../../../../../variables';

.inlineEditor {
  margin-left: 0.8rem;
  word-wrap: break-word;

  &:global {
    &.empty {
      color: var(--grayscaleColor-6);
    }
  }

  :global {
    .edit-actions {
      top: 2px;
      right: -30px;
    }
  }
}

.wrapper {
  :global {
    .collapse-outer
      .ant-collapse-item.ant-collapse-item.ant-collapse-item
      .ant-collapse-header:before {
      background: var(--primaryColor-6);
    }
  }
}

.processSideDrawer {
  :global {
    .ant-tabs .ant-tabs-content-holder {
      border-radius: var(--SECTION__borderRadius);
      border: 1px solid var(--grayscaleColor-5) !important;
      margin-top: -1px;
      overflow: hidden;
    }

    .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
      &:nth-last-child(2) {
        margin-right: 60px !important;
      }
      &:first-of-type {
        margin-left: 10px !important;
      }
    }
  }
}

.sidebarActive {
  align-items: stretch;
}

.collapse {
  width: 66.66%;

  &:global {
    &.ant-collapse.ant-collapse {
      border-bottom: 0;
      padding: 0;

      &.ant-collapse-borderless {
        background: inherit;
      }

      .ant-collapse-header {
        padding-left: 1.5rem;

        i {
          left: 0;
          line-height: 0;
        }
      }

      .ant-collapse-content {
        overflow: visible;
      }

      .ant-collapse-content-box {
        padding: 0 !important;
      }
    }
  }
}

.mobileToggleButton {
  width: 48px;
  height: 40px;
  border-radius: 3rem 0 0 3rem;
  font-size: 24px;
  border: 0;
  background-color: var(--primaryColor-6);
  color: var(--grayscaleColor-1);
  transition: background-color 0.3s ease;
  margin: 0 0 0 auto;
  position: sticky;
  top: 10px;
  z-index: 2;

  &:hover,
  &:focus {
    background-color: var(--infoColor-4);
  }

  &:active {
    background-color: var(--infoColor-6);
  }
}
