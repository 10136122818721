.wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  :global {
    .DateRangeSelectorPicker {
      .ant-select {
        .ant-select-selector {
          font-weight: 500;
        }
        .ant-select-selection-overflow {
          max-width: unset !important;
        }

        .ant-select-selection-search {
          margin-left: 12px !important;
        }
      }
    }
    .custom-btn {
      font-weight: 600;
      font-size: 14px;

      svg {
        font-size: 17px;
      }
    }

    .ant-picker.ant-picker {
      max-width: 100%;
      width: 350px;
      height: 40px;
      background: var(--grayscaleColor-1);
      border: 1px solid var(--primaryColor-1);
      border-radius: var(--borderRadius-l);
      margin: 0;
      flex-shrink: 0;

      .ant-picker-input input {
        font-family: var(--fontFamily);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--neutralColor-4);
      }
    }

    .ant-radio-group {
      display: flex;
      margin-right: 12px;

      .ant-radio-button-wrapper {
        height: 40px;
        padding: 0;
        width: 56px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--fontFamily);
        font-size: 14px;
        line-height: 17px;
        color: var(--neutralColor-4);
        border: 1px solid var(--primaryColor-1);

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }

        &.ant-radio-button-wrapper-checked {
          background: var(--primaryColor-6);
          border-color: var(--primaryColor-6);
          color: var(--grayscaleColor-1);
        }
      }
    }
  }
}
.emptyDate {
  :global {
    .DateRangeSelectorPicker {
      .ant-select {
        .ant-select-selector {
          font-weight: 400;
          color: var(--grayscaleColor-7);
        }
      }
    }
  }
}
