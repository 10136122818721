.wrapper {
  flex: 1 1 100%;

  :global {
    .metric-item .value {
      line-height: normal;
      font-size: 60pt;
      max-width: 100%;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      word-wrap: normal !important;
      font-weight: 600;
    }
  }
}
