@import './mixins';

.react-tag-input {
  padding: 0;
  border: none;
  background: transparent;
  max-height: 300px;
  gap: 3px;
  min-height: 30px;

  &__input {
    color: var(--grayscaleColor-9);
    font-size: 14px;
    margin-top: -3px;
    padding: 0;

    &::placeholder {
      color: var(--grayscaleColor-6);
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    background: rgba(var(--primaryColorRGB-6), 0.7);
    border: 1px solid var(--primaryColor-6);
    border-radius: 4px;
    color: var(--grayscaleColor-1);
    height: 24px;
    padding: 0 5px 0 10px;
    margin: 0;
    max-width: 100%;

    &__content {
      padding: 0;
      color: var(--grayscaleColor-1);
      font-size: 14px;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;

      @include flex-truncate;
    }

    &__remove {
      width: 16px;
      height: 16px;
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7.5' transform='rotate(-90 8 8)' fill='white' stroke='%23F24848'/%3E%3Ccircle cx='8' cy='8' r='7.5' transform='rotate(-90 8 8)' fill='%23F24848' fill-opacity='0.5' stroke='%23F24848'/%3E%3Cpath d='M9.92908 5.04302L8.0006 6.97149L6.07213 5.04302C5.85922 4.83011 5.51364 4.83011 5.30073 5.04302L5.04362 5.30013C4.83072 5.51304 4.83072 5.85862 5.04362 6.07152L6.9721 8L5.04362 9.92848C4.83072 10.1414 4.83072 10.487 5.04362 10.6999L5.30073 10.957C5.51364 11.1699 5.85922 11.1699 6.07213 10.957L8.0006 9.02851L9.92908 10.957C10.142 11.1699 10.4876 11.1699 10.7005 10.957L10.9576 10.6999C11.1705 10.487 11.1705 10.1414 10.9576 9.92848L9.02911 8L10.9576 6.07152C11.1705 5.85862 11.1705 5.51304 10.9576 5.30013L10.7005 5.04302C10.4876 4.83011 10.142 4.83011 9.92908 5.04302Z' fill='%23fff'/%3E%3C/svg%3E")
        0 0 no-repeat;
      background-size: 100% 100%;
      margin-left: 7px;

      &:before,
      &:after {
        display: none;
      }
    }
  }
}
