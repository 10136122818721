.userSuggestionIcon {
  margin-right: 0.3rem;
  width: 16px;
  height: 16px;
}

.userName {
  font-weight: 500;
  margin: 0 0.5rem;
  color: var(--grayscaleColor-13);
}

.creationDate {
  color: var(--grayscaleColor-6);
  font-size: 0.8rem;
}

.messageContent {
  margin-left: calc(24px + 0.5rem);
  border-radius: 10px;
  color: var(--grayscaleColor-13);
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  &.mainInput {
    margin-left: 0;
    padding: 20px 20px 0;
    border-radius: 0;
  }

  :global {
    textarea {
      cursor: text;
      background-color: var(--grayscaleColor-1);
      border-width: 1px;
      border-style: solid;
      border-color: var(--grayscaleColor-5);
      border-radius: var(--borderRadius-l);
      box-shadow: none;
      box-sizing: border-box;
      color: var(--grayscaleColor-9);
      display: inline-block;
      font-weight: 500;
      margin: 0;
      overflow: hidden;
      width: 100%;
      outline: none;
      font-size: 0.9rem;
      line-height: 1;
      padding: 16px;
      min-height: 48px;

      &:focus,
      &:hover {
        border-color: var(--primaryColor-6);
      }

      &:disabled {
        background: var(--grayscaleColor-3);
        color: var(--grayscaleColor-7);
      }

      &::placeholder {
        color: var(--grayscaleColor-7);
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 1;
      }

      &:disabled {
        background-color: var(--grayscaleColor-3) !important;
        color: var(--grayscaleColor-7) !important;
      }
    }

    .editor__control {
      position: relative;
    }

    .editor__suggestions__list {
      background: var(--grayscaleColor-1);
      box-shadow: 0 2px 8px rgba(var(--grayscaleColorRGB-13), 0.15);
    }

    .editor__suggestions__item {
      padding: 5px 12px 5px 10px;
      font-size: 0.9rem;

      display: flex;
      align-items: center;

      b {
        font-weight: 600;
      }
    }

    .editor__suggestions__item--focused {
      background-color: var(--grayscaleColor-3);
    }

    .editor__highlighter {
      font-size: 0.9rem !important;
      padding: 0.25rem 0.5rem;
      line-height: 1.6;
      border: 1px solid transparent;
      min-height: 48px;

      strong {
        background-color: var(--grayscaleColor-3);
        border-radius: 4px;
      }
    }
  }
}

.messageFooter {
  margin-top: 0.5rem;
  margin-left: calc(24px + 0.5rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 20px;

  &.mainInput {
    margin-left: 0;
  }
}

.messageWrapper {
  margin-top: 0.5rem;

  &:global {
    &.flush {
      :local {
        .messageContent,
        .messageFooter {
          margin-left: 1rem;
        }
      }
    }
  }
}

.actionLink {
  color: var(--grayscaleColor-1);
  font-size: 0.8rem;
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 0 24px;
  transition: background-color 0.3s ease;

  &.cancelButton {
    background-color: var(--grayscaleColor-1);
    color: var(--grayscaleColor-7);
    border: 1px solid var(--grayscaleColor-5);

    &:hover,
    &:focus {
      background-color: var(--grayscaleColor-3);
    }

    &:active {
      background-color: var(--grayscaleColor-5);
    }

    &:disabled {
      border-color: var(--grayscaleColor-5);
      background-color: var(--grayscaleColor-3);
    }
  }

  &.saveButton {
    margin-left: 0.5rem;
    background-color: var(--primaryColor-6);
    border: 1px solid var(--primaryColor-6);

    &:hover,
    &:focus {
      background-color: var(--primaryColor-5);
    }

    &:active {
      background-color: var(--primaryColor-7);
    }

    &:disabled {
      border-color: var(--primaryColor-2);
      background-color: var(--primaryColor-2);
    }
  }
}

.deletedMessage {
  font-style: italic;
  opacity: 0.5;
}

.deletedIcon {
  margin-right: 0.2rem;
  width: 20px;
  height: 20px;
  color: var(--grayscaleColor-6);
}
