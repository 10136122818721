.editor {
  padding: 0 !important;

  &:global {
    & > div,
    & > .CustomEntitySelector__tooltip-inner > div {
      padding: 0 !important;
      width: 100%;
    }
  }
}
