.wrapper {
  :global {
    .ant-select.ant-select .ant-select-selector .ant-select-selection-item {
      font-weight: 600;
    }
    .ant-radio-group-solid {
      .ant-radio-button-wrapper {
        font-weight: 500 !important;
      }
    }
  }
}
