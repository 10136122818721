.settingsBtn {
  outline: none;
  background-color: transparent;
  border: 0;
  padding: 2px;
  transition: color 0.3s ease;
  color: var(--grayscaleColor-6);

  &:hover {
    color: var(--primaryColor-5);
  }
}
