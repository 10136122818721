.wrapper {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accounts {
  border-radius: var(--borderRadius-l);
  border: 1px solid var(--grayscaleColor-5);
  overflow: hidden;
}

.account {
  background-color: var(--grayscaleColor-1);
  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    background-color: var(--primaryColor-6);
    color: var(--grayscaleColor-1);

    svg {
      color: var(--grayscaleColor-1) !important;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid var(--grayscaleColor-5);
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  svg {
    right: 0.5rem;
  }
}

.name {
  font-size: 1.1rem;
}
