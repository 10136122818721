.radioRow {
  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    color: var(--neutralColor-6);
    transition: color 0.3s ease, opacity 0.3s ease;

    svg {
      margin-right: 8px;
      width: 13px;
      height: 15px;
    }

    span {
      display: inline-block;
      width: calc(100% - 13px);
    }
  }
}
